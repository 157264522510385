import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  InputGroup,
  Input,
} from "reactstrap";
import InformationModal from "../../components/InformationModal";
import Loader from "../../components/Loader";
import { ACTIONS, useEosReport } from "../../providers/eosReportProvider";
import useGetEosReport from "../../api/EosReport.hooks";
import useGetLines from "../../api/Lines.hooks";
import { utils } from "../../utils/utils";
import {
  faChevronLeft,
  faChevronRight,
  faDownload,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TooltipItem from "../../components/TooltipItem";
import { useGetShifts } from "../../api/Shifts.hooks";

const TABLE_COLUMNS = [
  {
    dataField: "",
    text: "Machine",
    classes: "text-truncate text-center",
    formatter: (_, entry) => (
      <span className="font-weight-bold">
        {entry.machine_id + " / " + entry.machine.name || ""}
      </span>
    ),
  },
  {
    dataField: "user.name",
    text: "Operator",
    classes: "text-truncate text-center",
    formatter: (cell) => (
      <span className="font-weight-bold">{cell || "-"}</span>
    ),
  },
  {
    dataField: "shiftJobSteps",
    text: "Production",
    classes: "text-truncate text-center",
    formatter: (cell) => getShiftProduction(cell) || "-",
  },
  {
    dataField: "timeEntries",
    text: "Downtime",
    classes: "text-truncate text-center",
    formatter: (cell) => getShiftDowntime(cell) || "-",
  },
  {
    dataField: "shiftSchedule.name",
    text: "Shift",
    classes: "text-truncate text-center",
    formatter: (cell) => cell || "-",
  },
];

const getShiftProduction = (shiftJobSteps) =>
  shiftJobSteps.reduce((p, c) => p + c.production, 0);

const getShiftDowntime = (timeEntries) =>
  utils.formatTime(timeEntries.reduce((p, c) => p + c.duration, 0));

const EosReport = () => {
  const [eosReportContext, setEosReportContext] = useEosReport();

  const [date, setDate] = useState(eosReportContext.date);

  const {
    data: lines,
    error: linesErr,
    isLoading: isLoadingLines,
    get: getLines,
  } = useGetLines();

  const {
    data: eosReport,
    error: eosReportErr,
    isLoading: isLoadingEosReport,
    get: getEosReport,
  } = useGetEosReport();

  const {
    data: shifts,
    error: shiftsErr,
    isLoading: isLoadingShifts,
    get: getShifts,
  } = useGetShifts();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const setEosReportContextCb = useCallback(
    (data) => setEosReportContext(data),
    [setEosReportContext]
  );

  useEffect(() => {
    getLines();
  }, [getLines]);

  useEffect(() => {
    if (lines) {
      setEosReportContextCb({
        action: ACTIONS.GET_LINES_SUCCESS,
        payload: { lines },
      });
    }
  }, [lines, setEosReportContextCb]);

  useEffect(() => {
    getEosReport({
      date: eosReportContext.date,
      line_id: eosReportContext.selectedLine,
      shift_schedule_id: eosReportContext.selectedShift,
    });
  }, [
    eosReportContext.date,
    eosReportContext.selectedLine,
    eosReportContext.selectedShift,
    eosReportContext.refresh,
    getEosReport,
  ]);

  useEffect(() => {
    if (eosReport) {
      setEosReportContextCb({
        action: ACTIONS.GET_PRODUCTION_SUCCESS,
        payload: { production: eosReport },
      });
    }
  }, [eosReport, setEosReportContextCb]);

  useEffect(() => {
    getShifts();
  }, [getShifts]);

  useEffect(() => {
    if (shifts) {
      setEosReportContextCb({
        action: ACTIONS.GET_SHIFTS_SUCCESS,
        payload: { shifts },
      });
    }
  }, [shifts, setEosReportContextCb]);

  useEffect(() => {
    if (linesErr || eosReportErr || shiftsErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [linesErr, eosReportErr, shiftsErr]);

  useEffect(() => {
    if (!date) {
      setDate(moment().format("YYYY-MM-DD"));
    } else {
      setEosReportContextCb({
        action: ACTIONS.DATE_FILTER_CHANGE,
        payload: { date: date },
      });
    }
  }, [setEosReportContextCb, date]);

  return (
    <Container className="mx-0" fluid>
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className="mb-0 ">Eos Report</h3>
            <small className="text-muted ml-2 pt-1">
              ({eosReportContext.production?.length})
            </small>
          </div>
          <div className="d-flex align-items-center justify-content-end flex-shrink-0">
            <InputGroup size="m" className="mr-3">
              <Input
                className="min-width-150"
                type="select"
                name="lineSelect"
                id="lineSelect"
                onChange={(evt) =>
                  setEosReportContextCb({
                    action: ACTIONS.LINE_FILTER_CHANGE,
                    payload: {
                      selectedLine: evt.target.value,
                    },
                  })
                }
                value={eosReportContext.selectedLine}
              >
                {eosReportContext.lines?.length &&
                  eosReportContext.lines.map((line, index) => (
                    <option key={index} value={line.id}>
                      {line.name}
                    </option>
                  ))}
              </Input>
            </InputGroup>
            <InputGroup
              size="m"
              className="d-flex align-items-center mr-3 flex-nowrap"
            >
              <Input
                type="select"
                name="shiftSelect"
                id="shiftSelect"
                className="min-width-150 mr-3"
                onChange={(evt) =>
                  setEosReportContextCb({
                    action: ACTIONS.SHIFT_FILTER_CHANGE,
                    payload: {
                      selectedShift: evt.target.value,
                    },
                  })
                }
                value={eosReportContext.selectedShift}
              >
                <option value={0}>Filter Shift</option>
                {eosReportContext.shifts?.length &&
                  eosReportContext.shifts.map((shift, index) => (
                    <option key={index} value={shift.id}>
                      {shift.name + " Shift"}
                    </option>
                  ))}
              </Input>
              <Input
                type="date"
                name="datePicker"
                id="datePicker"
                value={date}
                onChange={(evt) =>
                  setDate(moment(evt.target.value).format("YYYY-MM-DD"))
                }
              />
            </InputGroup>
            <div className="d-flex justify-content-between align-items-center flex-shrink-0 mr-3">
              <TooltipItem id="tooltip-previous-date" title="Previous Date">
                <Button
                  color="primary"
                  onClick={() =>
                    setDate(
                      moment(date).subtract(1, "day").format("YYYY-MM-DD")
                    )
                  }
                  className="rounded-circle square-32 d-flex align-items-center justify-content-center"
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
              </TooltipItem>
              <div className="form-control mx-1 d-flex align-items-center justify-content-center">
                <span>{date}</span>
              </div>
              <TooltipItem id="tooltip-next-date" title="Next Date">
                <Button
                  color="primary"
                  onClick={() =>
                    setDate(moment(date).add(1, "day").format("YYYY-MM-DD"))
                  }
                  className="rounded-circle square-32 d-flex align-items-center justify-content-center"
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
              </TooltipItem>
            </div>
            <Button
              size="sm"
              className="rounded-circle mr-2 square-32"
              color="primary"
              onClick={() => window.print()}
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
            <Button
              size="sm"
              className="rounded-circle square-32"
              color="primary"
              onClick={() =>
                setEosReportContext({
                  action: ACTIONS.REFRESH,
                })
              }
            >
              <FontAwesomeIcon icon={faSync} />
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          {isLoadingLines || isLoadingEosReport || isLoadingShifts ? (
            <Loader />
          ) : eosReportContext.production?.length ? (
            <BootstrapTable
              bootstrap4
              remote
              striped
              bordered={false}
              keyField="id"
              classes="border"
              headerClasses="small text-muted text-center"
              data={eosReportContext.production}
              columns={TABLE_COLUMNS}
              onTableChange={() => {}}
            />
          ) : (
            <div className="text-center">No results</div>
          )}
        </CardBody>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
    </Container>
  );
};

export default EosReport;
