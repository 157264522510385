import React from "react";
import Routes from "./routes/Routes";

import { AuthProvider } from "./providers/authProvider";
import { UsersProvider } from "./providers/usersProvider";
import { SidebarProvider } from "./providers/sidebarProvider";
import { JobsProvider } from "./providers/jobsProvider";
import { HomeProvider } from "./providers/homeProvider";
import { SocketProvider } from "./providers/socketProvider";
import { JobProductionProvider } from "./providers/jobProductionProvider";
import { DowntimeReportProvider } from "./providers/downtimeReportProvider";
import { EosReportProvider } from "./providers/eosReportProvider";
import { ChartsReportProvider } from "./providers/chartsReportProvider";
import { ExpiredShiftsProvider } from "./providers/expiredShiftsProvider";
import { ShiftsProvider } from "./providers/shiftsTableProvider";
import { DowntimeTrackerProvider } from "./providers/downtimeTrackerProvider";
import { RunningTimersProvider } from "./providers/runningTimersProvider";
import { MachinesProvider } from "./providers/machinesProvider";
import { OeeReportProvider } from "./providers/oeeReportProvider";

class App extends React.Component {
  componentDidMount() {
    // Remove `active` className from `.splash` element in `public/index.html`
    !document.querySelector(".splash") ||
      document.querySelector(".splash").classList.remove("active");
  }

  render() {
    return (
      <SidebarProvider>
        <AuthProvider>
          <UsersProvider>
            <JobsProvider>
              <HomeProvider>
                <SocketProvider>
                  <JobProductionProvider>
                    <DowntimeReportProvider>
                      <EosReportProvider>
                        <ChartsReportProvider>
                          <ExpiredShiftsProvider>
                            <ShiftsProvider>
                              <DowntimeTrackerProvider>
                                <RunningTimersProvider>
                                  <MachinesProvider>
                                    <OeeReportProvider>
                                      <Routes />
                                    </OeeReportProvider>
                                  </MachinesProvider>
                                </RunningTimersProvider>
                              </DowntimeTrackerProvider>
                            </ShiftsProvider>
                          </ExpiredShiftsProvider>
                        </ChartsReportProvider>
                      </EosReportProvider>
                    </DowntimeReportProvider>
                  </JobProductionProvider>
                </SocketProvider>
              </HomeProvider>
            </JobsProvider>
          </UsersProvider>
        </AuthProvider>
      </SidebarProvider>
    );
  }
}

export default App;
