import {
  faArrowDown,
  faArrowUp,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import { Button, Badge, Input } from "reactstrap";

import { ACTIONS, useMachines } from "../../../providers/machinesProvider";

import ScheduleJobModal from "../machines/ScheduleJobModal";
import EditMachineJobModal from "../machines/EditMachineJobModal";
import { jobStepsApi } from "../../../api/jobStepServices";
import ConfirmationModal from "../../ConfirmationModal";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import { utils } from "../../../utils/utils";
import { useHistory } from "react-router-dom";

const DIRECTION_DOWN = "DIRECTION_DOWN";
const DIRECTION_UP = "DIRECTION_UP";

const Machine = ({ machine, showHistorical }) => {
  const history = useHistory();
  const [machinesContext, setMachinesContext] = useMachines();
  const [scheduleJobModal, setScheduleJobModal] = useState();
  const [editMachineJobModal, setEditMachineJobModal] = useState();
  const [ordersLoader, setOrdersLoader] = useState();
  const [isLoadingUpdateJobStep, setIsLoadingUpdateJobStep] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  useEffect(() => {
    if (
      machinesContext.search.length &&
      ((machine.jobSteps.length && !machinesContext.openMachines[machine.id]) ||
        (!machine.jobSteps.length && machinesContext.openMachines[machine.id]))
    ) {
      setMachinesContext({
        action: ACTIONS.TOGGLE_OPEN_MACHINE,
        payload: { selectedMachine: machine.id },
      });
    }
  }, [
    machine.id,
    machine.jobSteps,
    machinesContext.openMachines,
    machinesContext.search.length,
    setMachinesContext,
  ]);

  const onScheduleJob = (event, machine) => {
    event.stopPropagation();
    setScheduleJobModal(machine);
  };

  const onComplete = (jobStep) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        setIsLoadingUpdateJobStep(true);
        await jobStepsApi.completeJobStep({
          machine_id: jobStep.machine_id,
          job_id: jobStep.job_id,
        });
        setIsLoadingUpdateJobStep(false);
        setMachinesContext({
          action: ACTIONS.REFRESH,
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Complete Job Step",
      body: `
          <div class="text-center">
              Do you confirm you want to set this job step as completed?
          </div>
    `,
      confirmColor: "warning",
    });
  };

  const onReschedule = (jobStep) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        try {
          setConfirmationModal(initConfirmationModal);
          setIsLoadingUpdateJobStep(true);
          await jobStepsApi.updateJobStep({ ...jobStep, completed: 0 });
          setIsLoadingUpdateJobStep(false);
          setMachinesContext({
            action: ACTIONS.REFRESH,
          });
        } catch (err) {
          setIsLoadingUpdateJobStep(false);
          setInformationModal({
            isOpen: true,
            title: "Error",
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Reschedule Job Step",
      body: `
          <div class="text-center">
              Do you confirm you want to reschedule this job step?
          </div>
    `,
      confirmColor: "warning",
    });
  };

  const handleMachineClick = (selectedMachine) => {
    setMachinesContext({
      action: ACTIONS.TOGGLE_OPEN_MACHINE,
      payload: { selectedMachine },
    });
  };

  const onChangeJobStepOrder = async (jobStep) => {
    try {
      setOrdersLoader(true);
      const currentOrderJobStep = machine.jobSteps.find(
        (js) => js.order === jobStep.newOrder
      );
      if (currentOrderJobStep) {
        currentOrderJobStep.order = jobStep.order;
        jobStep.order = jobStep.newOrder;
        delete jobStep.newOrder;
        delete jobStep.orderTouched;
        await jobStepsApi.updateJobStepsOrder({
          machineId: machine.id,
          jobSteps: [jobStep, currentOrderJobStep],
        });
        setMachinesContext({
          action: ACTIONS.REFRESH,
        });
      } else {
        jobStep.order = jobStep.newOrder;
        delete jobStep.newOrder;
        delete jobStep.orderTouched;
        await jobStepsApi.updateJobStep(jobStep);
        setMachinesContext({
          action: ACTIONS.REFRESH,
        });
      }
      setOrdersLoader(false);
    } catch (err) {
      setOrdersLoader(false);
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  };

  const onMoveJobStep = async (direction, jobStep, index) => {
    try {
      if (ordersLoader) {
        return;
      }
      const activeJobStep = machine.jobSteps.find((jobStep) => jobStep.active);
      const inactiveJobSteps = machine.jobSteps
        .filter((jobStep) => !jobStep.active)
        .sort(
          (x, y) =>
            (x.order || Number.MAX_SAFE_INTEGER) -
            (y.order || Number.MAX_SAFE_INTEGER)
        );
      setOrdersLoader(true);
      if (!jobStep.order) {
        jobStep.order = index + 1;
      }
      if (direction === DIRECTION_UP) {
        //if its first, return
        if (jobStep.order === 1) {
          return setOrdersLoader(false);
        }
        //exchange order with previous
        const previousJobStep =
          index === 0
            ? activeJobStep
            : inactiveJobSteps && inactiveJobSteps[index - 1]
            ? inactiveJobSteps[index - 1]
            : null;
        if (!previousJobStep || jobStep.id === previousJobStep.id) {
          return setOrdersLoader(false);
        }
        previousJobStep.order = jobStep.order;
        jobStep.order = jobStep.order - 1;
        await jobStepsApi.updateJobStepsOrder({
          machineId: machine.id,
          jobSteps: [jobStep, previousJobStep],
        });
        setMachinesContext({
          action: ACTIONS.REFRESH,
        });
      } else {
        //if its last, return
        if (index === inactiveJobSteps.length - 1) {
          return setOrdersLoader(false);
        }
        //exchange order with next
        const nextJobStep =
          inactiveJobSteps && inactiveJobSteps[index + 1]
            ? inactiveJobSteps[index + 1]
            : null;
        if (!nextJobStep || jobStep.id === nextJobStep.id) {
          return setOrdersLoader(false);
        }
        nextJobStep.order = jobStep.order;
        jobStep.order = jobStep.order + 1;
        await jobStepsApi.updateJobStepsOrder({
          machineId: machine.id,
          jobSteps: [jobStep, nextJobStep],
        });
        setMachinesContext({
          action: ACTIONS.REFRESH,
        });
      }
    } catch (err) {
      setOrdersLoader(false);
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
    setOrdersLoader(false);
  };

  const activeJobSteps = machine.jobSteps
    .filter((jobStep) => jobStep.active)
    .sort(
      (x, y) =>
        (x.order || Number.MAX_SAFE_INTEGER) -
        (y.order || Number.MAX_SAFE_INTEGER)
    )
    .map((jobStep) => (
      <div key={jobStep.id} className="d-flex p-3 border-bottom bg-white">
        {!showHistorical ? (
          <div
            className={`d-flex align-items-center justify-content-center width-${
              showHistorical ? "15" : "12"
            }`}
          >
            <Input
              type="number"
              min={0}
              className="text-center"
              maxLength="10"
              placeholder="Enter order.."
              defaultValue={jobStep.order || "Not Set"}
              onChange={(evt) => {
                jobStep.newOrder = parseInt(evt.currentTarget.value);
                jobStep.orderTouched = true;
                setMachinesContext({
                  action: ACTIONS.UPDATE_MACHINE,
                  payload: { machine },
                });
              }}
            />
            {jobStep.orderTouched ? (
              <>
                <Button
                  className="rounded ml-2"
                  size="sm"
                  color="success"
                  onClick={() => onChangeJobStepOrder(jobStep)}
                >
                  Apply
                </Button>
                <Button
                  className="rounded ml-2"
                  size="sm"
                  color="danger"
                  onClick={() => {
                    setMachinesContext({
                      action: ACTIONS.REFRESH,
                    });
                  }}
                >
                  Discard
                </Button>
              </>
            ) : null}
          </div>
        ) : null}
        <div
          className={`d-flex align-items-center justify-content-center width-${
            showHistorical ? "15" : "12"
          }`}
        >
          {jobStep.job.item || "-"}
        </div>
        <div
          className={`d-flex align-items-center justify-content-center width-${
            showHistorical ? "15" : "12"
          }`}
        >
          {jobStep.job_id}
        </div>
        <div
          className={`d-flex align-items-center justify-content-center width-${
            showHistorical ? "15" : "12"
          }`}
        >
          {utils.jobStatusBadge(jobStep.job)}
        </div>
        <div
          className={`d-flex align-items-center justify-content-center width-${
            showHistorical ? "15" : "12"
          }`}
        >
          {jobStep.scheduled_start_date
            ? utils.formatDate(jobStep.scheduled_start_date)
            : "-"}
        </div>
        <div
          className={`d-flex align-items-center justify-content-center width-${
            showHistorical ? "15" : "12"
          }`}
        >
          <Badge color="success">In Progress</Badge>
        </div>
        <div
          className={`d-flex align-items-center justify-content-center width-${
            showHistorical ? "15" : "12"
          }`}
        >
          {`${jobStep.quantity_completed} / ${jobStep.start_quantity}`}
        </div>
        <div
          className={`d-flex align-items-center justify-content-center width-${
            showHistorical ? "15" : "12"
          }`}
        >
          {isLoadingUpdateJobStep ? (
            <Loader size="sm" align="end" />
          ) : (
            <>
              <Button
                className="rounded mr-2"
                size="sm"
                onClick={() => history.push(`/jobs/${jobStep.job_id}`)}
              >
                See Details
              </Button>
              <Button
                className="rounded"
                size="sm"
                color="success"
                onClick={() => onComplete(jobStep)}
              >
                Complete
              </Button>
            </>
          )}
        </div>
      </div>
    ));

  const inactiveJobSteps = machine.jobSteps
    .filter((jobStep) => !jobStep.active)
    .sort(
      (x, y) =>
        (x.order || Number.MAX_SAFE_INTEGER) -
        (y.order || Number.MAX_SAFE_INTEGER)
    )
    .map((jobStep, i) => (
      <div key={jobStep.id} className="d-flex p-3 border-bottom bg-white">
        {!showHistorical ? (
          <div
            className={`d-flex align-items-center justify-content-center width-${
              showHistorical ? "15" : "12"
            }`}
          >
            <Input
              type="number"
              min={0}
              className="text-center"
              maxLength="10"
              placeholder="Enter order.."
              defaultValue={jobStep.order || "Not Set"}
              onChange={(evt) => {
                jobStep.newOrder = parseInt(evt.currentTarget.value);
                jobStep.orderTouched = true;
                setMachinesContext({
                  action: ACTIONS.UPDATE_MACHINE,
                  payload: { machine },
                });
              }}
            />
            {jobStep.orderTouched ? (
              <>
                <Button
                  className="rounded ml-2"
                  size="sm"
                  color="success"
                  onClick={() => onChangeJobStepOrder(jobStep)}
                >
                  Apply
                </Button>
                <Button
                  className="rounded ml-2"
                  size="sm"
                  color="danger"
                  onClick={() => {
                    setMachinesContext({
                      action: ACTIONS.REFRESH,
                    });
                  }}
                >
                  Discard
                </Button>
              </>
            ) : null}
          </div>
        ) : null}
        <div
          className={`d-flex align-items-center justify-content-center width-${
            showHistorical ? "15" : "12"
          }`}
        >
          {jobStep.job.item || "-"}
        </div>
        <div
          className={`d-flex align-items-center justify-content-center width-${
            showHistorical ? "15" : "12"
          }`}
        >
          {jobStep.job_id}
        </div>
        <div
          className={`d-flex align-items-center justify-content-center width-${
            showHistorical ? "15" : "12"
          }`}
        >
          {utils.jobStatusBadge(jobStep.job)}
        </div>
        <div
          className={`d-flex align-items-center justify-content-center width-${
            showHistorical ? "15" : "12"
          }`}
        >
          {jobStep.scheduled_start_date
            ? utils.formatDate(jobStep.scheduled_start_date)
            : "-"}
        </div>
        <div
          className={`d-flex align-items-center justify-content-center width-${
            showHistorical ? "15" : "12"
          }`}
        >
          {jobStep.completed ? (
            <Badge color="success">Completed</Badge>
          ) : (
            <Badge color="warning">Next Up</Badge>
          )}
        </div>
        <div
          className={`d-flex align-items-center justify-content-center width-${
            showHistorical ? "15" : "12"
          }`}
        >
          {`${jobStep.quantity_completed} / ${jobStep.start_quantity}`}
        </div>
        <div
          className={`d-flex align-items-center justify-content-center width-${
            showHistorical ? "15" : "12"
          }`}
        >
          {jobStep.completed ? (
            <Button
              className="rounded mr-2"
              size="sm"
              color="tertiary"
              onClick={() => onReschedule(jobStep)}
            >
              Reschedule Job
            </Button>
          ) : (
            <>
              <Button
                className="rounded mr-2"
                size="sm"
                onClick={() => history.push(`/jobs/${jobStep.job_id}`)}
              >
                See Details
              </Button>
              <Button
                className="rounded"
                size="sm"
                color="warning"
                onClick={() =>
                  setEditMachineJobModal({
                    ...jobStep,
                    machine_name: machine.name,
                  })
                }
              >
                Edit
              </Button>
              <div className="ml-2">
                {ordersLoader ? (
                  <div className="min-width-50">
                    <Loader size="sm" />
                  </div>
                ) : (
                  <div className="d-flex cursor-pointer">
                    <FontAwesomeIcon
                      className="text-primary"
                      icon={faArrowUp}
                      onClick={() => onMoveJobStep(DIRECTION_UP, jobStep, i)}
                    />
                    <FontAwesomeIcon
                      className="ml-2 text-primary"
                      icon={faArrowDown}
                      onClick={() => onMoveJobStep(DIRECTION_DOWN, jobStep, i)}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    ));

  return (
    <div key={machine.id} className="d-flex flex-column">
      <div
        className="d-flex cursor-pointer p-3 border-bottom bg-lighter"
        onClick={() => handleMachineClick(machine.id)}
      >
        <div className="text-left col-6 font-weight-bold px-0">
          {machine.name}
        </div>
        <div className="col-6 d-flex px-0">
          <div className="text-right flex-grow-1 mr-3">
            <FontAwesomeIcon
              className="mr-2 cursor-pointer"
              icon={
                machinesContext.openMachines[machine.id]
                  ? faChevronDown
                  : faChevronUp
              }
            />
          </div>
          <div className="text-right">
            <Button
              size="sm"
              className="rounded"
              onClick={(event) => onScheduleJob(event, machine)}
            >
              Schedule Job
            </Button>
          </div>
        </div>
      </div>
      {machinesContext.openMachines[machine.id] ? (
        <div className="col-12 p-3 border-bottom text-center bg-lighter">
          <div className="border rounded">
            <div className="small text-muted font-weight-bold d-flex p-3 border-bottom bg-white">
              {!showHistorical ? (
                <div
                  className={`d-flex align-items-center justify-content-center width-${
                    showHistorical ? "15" : "12"
                  }`}
                >
                  #
                </div>
              ) : null}
              <div
                className={`d-flex align-items-center justify-content-center width-${
                  showHistorical ? "15" : "12"
                }`}
              >
                Item
              </div>
              <div
                className={`d-flex align-items-center justify-content-center width-${
                  showHistorical ? "15" : "12"
                }`}
              >
                Job#
              </div>
              <div
                className={`d-flex align-items-center justify-content-center width-${
                  showHistorical ? "15" : "12"
                }`}
              >
                Job Status
              </div>
              <div
                className={`d-flex align-items-center justify-content-center width-${
                  showHistorical ? "15" : "12"
                }`}
              >
                Step Start
              </div>
              <div
                className={`d-flex align-items-center justify-content-center width-${
                  showHistorical ? "15" : "12"
                }`}
              >
                Step Status
              </div>
              <div
                className={`d-flex align-items-center justify-content-center width-${
                  showHistorical ? "15" : "12"
                }`}
              >
                Progress
              </div>
              <div
                className={`d-flex align-items-center justify-content-center width-${
                  showHistorical ? "15" : "12"
                }`}
              >
                Actions
              </div>
            </div>
            {!machine.jobSteps.length ? (
              <div className="text-muted small col-12 p-3 border-bottom text-center bg-white">
                No jobs assigned
              </div>
            ) : null}
            {activeJobSteps}
            {inactiveJobSteps}
          </div>
        </div>
      ) : null}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : scheduleJobModal ? (
        <ScheduleJobModal
          machine={scheduleJobModal}
          onSubmit={() => {
            setScheduleJobModal();
            setInformationModal({
              isOpen: true,
              title: "Schedule Job",
              body: "Job scheduled successfully",
            });
            setMachinesContext({
              action: ACTIONS.REFRESH,
            });
          }}
          onClose={() => setScheduleJobModal()}
        />
      ) : editMachineJobModal ? (
        <EditMachineJobModal
          jobStep={editMachineJobModal}
          onSubmit={() => {
            setEditMachineJobModal();
            setInformationModal({
              isOpen: true,
              title: "Update Job Machine",
              body: "Machine updated successfully",
            });
            setMachinesContext({
              action: ACTIONS.REFRESH,
            });
          }}
          onClose={() => setEditMachineJobModal()}
        />
      ) : null}
    </div>
  );
};

export default Machine;
