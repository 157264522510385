import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";

import { useShifts, ACTIONS } from "../../../providers/shiftsTableProvider";

import { useUpdateShiftJobStep } from "../../../api/ShiftJobStep.hooks";
import InformationModal from "../../InformationModal";

const MAX_PRODUCTION = 9999999;

const ShiftProduction = ({ shiftJobStep }) => {
  const [, setShiftsContext] = useShifts();

  const [production, setProduction] = useState(shiftJobStep.production);
  const [updateShiftJobStep, setUpdateShiftJobStep] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const {
    error: updateShiftJobStepErr,
    data: updateShiftJobStepData,
    update,
  } = useUpdateShiftJobStep(shiftJobStep.id);

  useEffect(() => {
    if (updateShiftJobStep && production < MAX_PRODUCTION && production >= 0) {
      update({
        ...shiftJobStep,
        production,
      });
    }
  }, [update, production, updateShiftJobStep, shiftJobStep]);

  useEffect(() => {
    if (updateShiftJobStepData) {
      setShiftsContext({
        action: ACTIONS.REFRESH,
      });
    }
  }, [setShiftsContext, updateShiftJobStepData]);

  useEffect(() => {
    if (updateShiftJobStepErr) {
      setInformationModal({
        isOpen: true,
        title: "Update Shift Job Step",
        body: "There was an error with your request.",
      });
    }
  }, [updateShiftJobStepErr]);

  return (
    <div className="col-12 pt-3 pb-3 border-top px-2 d-flex align-items-center">
      <span className="font-weight-bold text-uppercase flex-grow-0 px-2">
        Production:
      </span>
      <div className="font-weight-bold flex-grow-1 px-5">
        <span>{shiftJobStep.jobStep.job.id}</span>
        <span className="mx-1">/</span>
        <span>{unescape(shiftJobStep.jobStep.job.description)}</span>
      </div>
      <div>
        <div className="float-right d-flex align-items-center px-2">
          <Input
            type="number"
            min="0"
            max={MAX_PRODUCTION}
            className="form-control text-right"
            onChange={(e) => {
              setProduction(e.target.value);
              setUpdateShiftJobStep(true);
            }}
            value={production || ""}
            invalid={production > MAX_PRODUCTION || production < 0}
          />
        </div>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
    </div>
  );
};

export default ShiftProduction;
