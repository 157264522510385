import React from "react";
import Content from "../components/Content";

import Main from "../components/Main";
import Wrapper from "../components/Wrapper";

const Default = ({ children }) => (
  <React.Fragment>
    <Wrapper className="overflow-x-visible">
      <Main className="min-width-lg d-flex flex-column justify-content-center">
        <Content>{children}</Content>
      </Main>
    </Wrapper>
  </React.Fragment>
);

export default Default;
