import React, { useState, useEffect } from "react";

import { Card, CardBody } from "reactstrap";

import { useChartsReport } from "../../../providers/chartsReportProvider";
import { chartsReportUtils } from "../../../utils/chartsReportUtils";
import { Chart as ChartJS, defaults } from "chart.js";

import "chartjs-plugin-datalabels";
import "chartjs-plugin-colorschemes";

defaults.global.defaultFontFamily = "Rubik";

const WEEKS_FOR_AVG = 10;

const AverageDowntimePerWeek = () => {
  const [chartsReportContext] = useChartsReport();

  const [, setChart] = useState();
  const [averagePerWeek, setAveragePerWeek] = useState("");

  useEffect(() => {
    const average = chartsReportUtils.calculateAveragePerWeek(
      chartsReportContext.extCharts,
      WEEKS_FOR_AVG
    );
    setAveragePerWeek(average);
  }, [chartsReportContext.extCharts]);

  useEffect(() => {
    const chartOptions = chartsReportUtils.getDowntimePerWeek(
      chartsReportContext.extCharts
    );
    const container = document.getElementById("weeks-chart-canvas");
    const ctx = container.getContext("2d");
    const newChart = new ChartJS(ctx, chartOptions);
    setChart((previousValue) => {
      previousValue && previousValue.destroy();
      return newChart;
    });
  }, [
    chartsReportContext.extCharts,
    chartsReportContext.charts,
    chartsReportContext.startDate,
  ]);

  return (
    <Card className="border-radius-0 box-shadow-none border-left border-bottom border-right">
      <CardBody className="d-flex flex-column">
        <div className="text-center d-flex justify-content-end">
          <div className="d-flex flex-column">
            <span>
              Average for the {WEEKS_FOR_AVG} weeks before the selected week
            </span>
            <span className="font-weight-bold">{averagePerWeek}</span>
          </div>
        </div>
        <canvas id="weeks-chart-canvas" className="mt-n5"></canvas>
      </CardBody>
    </Card>
  );
};

export default AverageDowntimePerWeek;
