import {
  faChevronLeft,
  faChevronRight,
  faDownload,
  faList,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import useGetLines from "../../api/Lines.hooks";
import ExpiredShifts from "../../components/admin/downtime/ExpiredShifts";
import ShiftsTable from "../../components/admin/downtime/ShiftsTable";
import InformationModal from "../../components/InformationModal";
import Loader from "../../components/Loader";
import {
  ACTIONS,
  useDowntimeTracker,
} from "../../providers/downtimeTrackerProvider";
import {
  ACTIONS as SHIFT_ACTIONS,
  useShifts,
} from "../../providers/shiftsTableProvider";
import Papa from "papaparse";
import { utils } from "../../utils/utils";
import TooltipItem from "../../components/TooltipItem";
import RunningTimers from "../../components/admin/downtime/RunningTimers";

const DowntimeTracker = () => {
  const [downtimeTrackerContext, setDowntimeTrackerContext] =
    useDowntimeTracker();
  const [shiftsContext, setShiftsContext] = useShifts();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    rawBody: false,
  });

  const {
    data: lines,
    error: linesErr,
    isLoading: isLoadingLines,
    get: getLines,
  } = useGetLines();

  useEffect(() => {
    getLines();
  }, [getLines]);

  useEffect(() => {
    if (lines) {
      setDowntimeTrackerContext({
        action: ACTIONS.GET_LINES_SUCCESS,
        payload: { lines },
      });
    }
  }, [lines, setDowntimeTrackerContext]);

  useEffect(() => {
    if (linesErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        rawBody: false,
        body: "There was an error with your request.",
      });
    }
  }, [linesErr]);

  const onListMachines = () => {
    const selectedLine = downtimeTrackerContext.lines.find(
      (line) => line.id === downtimeTrackerContext.selectedLine
    );
    if (!selectedLine) {
      return;
    }
    setInformationModal({
      isOpen: true,
      rawBody: true,
      title: "Machines List",
      body: (
        <ListGroup>
          <ListGroupItem
            className="justify-content-between py-2 bg-lighter d-flex align-items-center font-weight-bold"
            tag="div"
          >
            <div className="col-4 text-left">Name</div>
            <div className="col-4 text-center">Id</div>
            <div className="col-4 text-right">Step Code</div>
          </ListGroupItem>
          {shiftsContext.machines?.map((machine) => (
            <ListGroupItem
              key={machine.id}
              className="justify-content-between py-2 d-flex align-items-center"
              tag="div"
            >
              <div className="col-4 text-left">{machine.name}</div>
              <div className="col-4 text-center">{machine.id}</div>
              <div className="col-4 text-right">{machine.step_code}</div>
            </ListGroupItem>
          ))}
        </ListGroup>
      ),
    });
  };

  const onGenerateCSV = () => {
    const productionCSV = [];
    const downtimeCSV = [];
    const production = []; //this.machinesService.getProductionStatus();
    shiftsContext.shifts.forEach((shift) => {
      const machineProd = production.find((p) => p.id === shift.machine_id);
      productionCSV.push({
        production: shift.machine.name,
        actual:
          !machineProd || !machineProd.job_id
            ? "No Job Assigned"
            : machineProd.quantity_completed,
        expected:
          !machineProd || !machineProd.job_id
            ? "No Job Assigned"
            : machineProd.start_quantity,
        shift: shift && shift.shiftSchedule ? shift.shiftSchedule.name : "N/A",
      });
      if (shift.timeEntries.length) {
        shift.timeEntries.forEach((timeEntry, i) => {
          downtimeCSV.push({
            downtime: i === 0 ? shift.machine.name : "",
            reason: timeEntry.majorReason ? timeEntry.majorReason.name : " ",
            reason_type: timeEntry.minorReason
              ? timeEntry.minorReason.name
              : " ",
            total: utils.timeFromSeconds(timeEntry.duration, false),
          });
        });
        const total = shift.timeEntries.reduce((p, c) => p + c.duration, 0);
        downtimeCSV.push({
          downtime: "Total",
          reason: " ",
          reason_type: " ",
          total: utils.timeFromSeconds(total, false),
        });
      }
    });
    const integratedCSV = [];
    for (
      let i = 0;
      i < Math.max(productionCSV.length, downtimeCSV.length);
      i++
    ) {
      integratedCSV.push({
        downtime: downtimeCSV[i] ? downtimeCSV[i].downtime : "",
        reason: downtimeCSV[i] ? downtimeCSV[i].reason : "",
        reason_type: downtimeCSV[i] ? downtimeCSV[i].reason_type : "",
        total: downtimeCSV[i] ? downtimeCSV[i].total : "",
        production: productionCSV[i] ? productionCSV[i].production : "",
        actual: productionCSV[i] ? productionCSV[i].actual : "",
        expected: productionCSV[i] ? productionCSV[i].expected : "",
        shift: productionCSV[i] ? productionCSV[i].shift : "",
      });
    }
    const CSVDownloadData = Papa.unparse(integratedCSV, { delimiter: ";" });
    const filename = `Production Report ${downtimeTrackerContext.selectedDate}`;
    const hiddenElement = document.createElement("a");
    hiddenElement.href =
      "data:text/csv; charset=utf-8,\ufeff" +
      encodeURIComponent(CSVDownloadData);
    hiddenElement.target = "_self";
    hiddenElement.download = `${filename}.csv`;
    hiddenElement.click();
  };

  return (
    <Container className="mx-0" fluid>
      <Card className="col-12 px-0">
        <CardHeader className="d-flex align-items-center justify-content-between">
          <h3>Downtime Tracker</h3>
          <div className="d-flex col-8 px-0">
            <div className="d-flex flex-grow-1 mr-2">
              <Input
                type="select"
                className="form-control col-4"
                name="lineSelect"
                id="lineSelect"
                onChange={(e) =>
                  setDowntimeTrackerContext({
                    action: ACTIONS.CHANGE_LINE,
                    payload: { selectedLine: parseInt(e.target.value) },
                  })
                }
                value={downtimeTrackerContext.selectedLine}
              >
                {downtimeTrackerContext.lines?.length &&
                  downtimeTrackerContext.lines.map((line) => (
                    <option key={line.id} value={line.id}>
                      {line.name}
                    </option>
                  ))}
              </Input>
              <Input
                className="form-control ml-3 col-4"
                type="select"
                name="shiftSelect"
                id="shiftSelect"
                onChange={(e) =>
                  setDowntimeTrackerContext({
                    action: ACTIONS.CHANGE_SHIFT,
                    payload: { selectedShift: parseInt(e.target.value) },
                  })
                }
                value={downtimeTrackerContext.selectedShift}
              >
                {downtimeTrackerContext.shifts?.length &&
                  downtimeTrackerContext.shifts.map((shift) => (
                    <option key={shift.value} value={shift.value}>
                      {shift.name}
                    </option>
                  ))}
              </Input>
              <Input
                className="form-control ml-3 col-4"
                type="date"
                name="datePicker"
                id="datePicker"
                onChange={(e) =>
                  setDowntimeTrackerContext({
                    action: ACTIONS.CHANGE_DATE,
                    payload: {
                      selectedDate: moment(e.target.value).format("YYYY-MM-DD"),
                    },
                  })
                }
                value={downtimeTrackerContext.selectedDate}
              />
            </div>
            <div className="d-flex justify-content-end flex-shrink-0 ml-4">
              <div className="d-flex justify-content-between align-items-center ml-3">
                <TooltipItem id="tooltip-previous-date" title="Previous Date">
                  <Button
                    color="primary"
                    onClick={() =>
                      setDowntimeTrackerContext({
                        action: ACTIONS.PREV_DATE,
                      })
                    }
                    className="rounded-circle square-32 d-flex align-items-center justify-content-center"
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </Button>
                </TooltipItem>
                <div className="form-control mx-1 d-flex align-items-center justify-content-center">
                  <span>{downtimeTrackerContext.selectedDate}</span>
                </div>
                <TooltipItem id="tooltip-next-date" title="Next Date">
                  <Button
                    color="primary"
                    onClick={() =>
                      setDowntimeTrackerContext({
                        action: ACTIONS.NEXT_DATE,
                      })
                    }
                    className="rounded-circle square-32 d-flex align-items-center justify-content-center"
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                </TooltipItem>
              </div>
              <TooltipItem id="tooltip-csv" title="Generate CSV">
                <Button
                  size="sm"
                  className="rounded-circle square-32 ml-3"
                  color="primary"
                  onClick={onGenerateCSV}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
              </TooltipItem>
              <TooltipItem id="tooltip-refresh" title="Refresh">
                <Button
                  size="sm"
                  className="rounded-circle square-32 mx-3"
                  color="primary"
                  onClick={() =>
                    setShiftsContext({
                      action: SHIFT_ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </TooltipItem>
              <TooltipItem id="tooltip-machines" title="Machines List">
                <Button
                  size="sm"
                  className="rounded-circle square-32"
                  color="primary"
                  onClick={onListMachines}
                >
                  <FontAwesomeIcon icon={faList} />
                </Button>
              </TooltipItem>
            </div>
          </div>
        </CardHeader>
        {isLoadingLines ? (
          <Loader />
        ) : (
          <CardBody className="d-flex flex-column">
            <ExpiredShifts />
            <RunningTimers />
            <ShiftsTable />
          </CardBody>
        )}
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              rawBody: false,
            })
          }
        />
      ) : null}
    </Container>
  );
};

export default DowntimeTracker;
