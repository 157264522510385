import React, { useReducer, createContext, useContext } from "react";

const initialState = {
  timeEntries: {},
};

const ACTIONS = {
  REFRESH: "REFRESH",
  GET_TIMERS_SUCCESS: "GET_TIMERS_SUCCESS",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_TIMERS_SUCCESS: {
      const { payload } = data;
      const { timeEntries } = payload;

      return {
        ...state,
        timeEntries,
      };
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    default:
      throw new Error();
  }
};

const RunningTimersContext = createContext(initialState);

const RunningTimersProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <RunningTimersContext.Provider value={stateAndDispatch}>
      {children}
    </RunningTimersContext.Provider>
  );
};

export const useRunningTimers = () => useContext(RunningTimersContext);

export { RunningTimersContext, RunningTimersProvider, ACTIONS };
