import React, { useReducer, createContext, useContext } from "react";
import moment from "moment";

const initialState = {
  production: {},
  lines: {},
  shifts: {},
  selectedLine: 1,
  selectedShift: 0,
  date: moment().format("YYYY-MM-DD"),
};

const ACTIONS = {
  REFRESH: "REFRESH",
  GET_PRODUCTION_SUCCESS: "GET_PRODUCTION_SUCCESS",
  GET_LINES_SUCCESS: "GET_LINES_SUCCESS",
  GET_SHIFTS_SUCCESS: "GET_SHIFTS_SUCCESS",
  LINE_FILTER_CHANGE: "LINE_FILTER_CHANGE",
  SHIFT_FILTER_CHANGE: "SHIFT_FILTER_CHANGE",
  DATE_FILTER_CHANGE: "DATE_FILTER_CHANGE",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_PRODUCTION_SUCCESS: {
      const { payload } = data;
      const { production } = payload;

      return {
        ...state,
        production,
      };
    }

    case ACTIONS.GET_LINES_SUCCESS: {
      const { payload } = data;
      const { lines } = payload;

      return {
        ...state,
        lines,
      };
    }

    case ACTIONS.GET_SHIFTS_SUCCESS: {
      const { payload } = data;
      const { shifts } = payload;

      return {
        ...state,
        shifts,
      };
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case ACTIONS.LINE_FILTER_CHANGE: {
      const { payload } = data;
      const { selectedLine } = payload;
      return {
        ...state,
        selectedLine,
      };
    }

    case ACTIONS.SHIFT_FILTER_CHANGE: {
      const { payload } = data;
      const { selectedShift } = payload;
      return {
        ...state,
        selectedShift,
      };
    }

    case ACTIONS.DATE_FILTER_CHANGE: {
      const { payload } = data;
      const { date } = payload;
      return {
        ...state,
        date: date ? moment(date).format("YYYY-MM-DD") : state.date,
      };
    }

    default:
      throw new Error();
  }
};

const EosReportContext = createContext(initialState);

const EosReportProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <EosReportContext.Provider value={stateAndDispatch}>
      {children}
    </EosReportContext.Provider>
  );
};

export const useEosReport = () => useContext(EosReportContext);

export { EosReportContext, EosReportProvider, ACTIONS };
