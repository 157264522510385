import React, { useReducer, createContext, useContext } from "react";

const MAX_PAGE_SIZE = 100;
const INIT_PAGE_SIZE = 10;
const INIT_PAGE = 1;

const initialState = {
  machines: {},
  pagination: {},
  sizePerPage: INIT_PAGE_SIZE,
  page: INIT_PAGE,
  search: "",
  sortBy: "status",
  direction: "desc",
  openMachines: {},
};

const ACTIONS = {
  SORT: "SORT",
  SEARCH: "SEARCH",
  SELECTED_DATE: "SELECTED_DATE",
  REFRESH: "REFRESH",
  PAGE_CHANGE: "PAGE_CHANGE",
  PAGE_SIZE_CHANGE: "PAGE_SIZE_CHANGE",
  GET_MACHINES_SUCCESS: "GET_MACHINES_SUCCESS",
  TOGGLE_OPEN_MACHINE: "TOGGLE_OPEN_MACHINE",
  UPDATE_MACHINE: "UPDATE_MACHINE",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_MACHINES_SUCCESS: {
      const { payload } = data;
      const { machines, pagination } = payload;

      return {
        ...state,
        machines,
        pagination,
      };
    }

    case ACTIONS.UPDATE_MACHINE: {
      const { payload } = data;
      const { machine } = payload;

      const machines = state.machines;
      const index = machines.findIndex((m) => m.id === machine.id);
      machine.jobSteps = machine.jobSteps.sort(
        (x, y) =>
          (x.order || Number.MAX_SAFE_INTEGER) -
          (y.order || Number.MAX_SAFE_INTEGER)
      );
      machines.splice(index, 1, machine);

      return {
        ...state,
        machines,
      };
    }

    case ACTIONS.SORT: {
      const { payload } = data;
      const { sortBy, direction } = payload;

      return {
        ...state,
        sortBy,
        direction: direction,
        page: 1,
      };
    }

    case ACTIONS.PAGE_CHANGE: {
      const { payload } = data;
      const { page } = payload;
      return {
        ...state,
        page,
      };
    }

    case ACTIONS.PAGE_SIZE_CHANGE: {
      const { payload } = data;
      const { sizePerPage } = payload;
      return {
        ...state,
        sizePerPage,
        page: INIT_PAGE,
      };
    }

    case ACTIONS.SEARCH: {
      const { payload } = data;
      const { search } = payload;

      return {
        ...state,
        search,
        page: 1,
        openMachines:
          state.search.length && !search.length ? {} : state.openMachines,
      };
    }

    case ACTIONS.SELECTED_DATE: {
      const { payload } = data;
      const { selectedDate } = payload;

      return {
        ...state,
        selectedDate,
      };
    }

    case ACTIONS.TOGGLE_OPEN_MACHINE: {
      const { payload } = data;
      const { selectedMachine } = payload;

      return {
        ...state,
        openMachines: {
          ...state.openMachines,
          [selectedMachine]: !state.openMachines[selectedMachine],
        },
      };
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    default:
      throw new Error();
  }
};

const MachinesContext = createContext(initialState);

const MachinesProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <MachinesContext.Provider value={stateAndDispatch}>
      {children}
    </MachinesContext.Provider>
  );
};

export const useMachines = () => useContext(MachinesContext);

export { MachinesContext, MachinesProvider, ACTIONS, MAX_PAGE_SIZE };
