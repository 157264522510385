import moment from "moment";
import React, { useReducer, createContext, useContext } from "react";

const LINES_ID_LATH = 1;

const SHIFT_OPTIONS = [
  { name: "Any Shift", value: "" },
  { name: "First Shift", value: 1 },
  { name: "Second Shift", value: 2 },
  { name: "Third Shift", value: 3 },
];

const initialState = {
  refresh: false,
  lines: [],
  shifts: SHIFT_OPTIONS,
  selectedDate: moment().format("YYYY-MM-DD"),
  selectedShift: "",
  selectedLine: LINES_ID_LATH,
  openShifts: {},
};

const ACTIONS = {
  REFRESH: "REFRESH",
  GET_LINES_SUCCESS: "GET_LINES_SUCCESS",
  CHANGE_LINE: "CHANGE_LINE",
  CHANGE_DATE: "CHANGE_DATE",
  NEXT_DATE: "NEXT_DATE",
  PREV_DATE: "PREV_DATE",
  CHANGE_SHIFT: "CHANGE_SHIFT",
  TOGGLE_OPEN_SHIFT: "TOGGLE_OPEN_SHIFT",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_LINES_SUCCESS: {
      const { payload } = data;
      const { lines } = payload;

      return {
        ...state,
        lines,
      };
    }

    case ACTIONS.CHANGE_LINE: {
      const { payload } = data;
      const { selectedLine } = payload;

      return {
        ...state,
        selectedLine,
      };
    }

    case ACTIONS.CHANGE_DATE: {
      const { payload } = data;
      const { selectedDate } = payload;

      return {
        ...state,
        selectedDate,
      };
    }

    case ACTIONS.REFRESH: {
      return {
        ...state,
        refresh: !state.refresh,
      };
    }

    case ACTIONS.NEXT_DATE: {
      return {
        ...state,
        selectedDate: moment(state.selectedDate)
          .add(1, "day")
          .format("YYYY-MM-DD"),
      };
    }

    case ACTIONS.PREV_DATE: {
      return {
        ...state,
        selectedDate: moment(state.selectedDate)
          .subtract(1, "day")
          .format("YYYY-MM-DD"),
      };
    }

    case ACTIONS.CHANGE_SHIFT: {
      const { payload } = data;
      const { selectedShift } = payload;

      return {
        ...state,
        selectedShift,
      };
    }

    case ACTIONS.TOGGLE_OPEN_SHIFT: {
      const { payload } = data;
      const { selectedShift } = payload;

      return {
        ...state,
        openShifts: {
          ...state.openShifts,
          [selectedShift]: !state.openShifts[selectedShift],
        },
      };
    }

    default:
      throw new Error();
  }
};

const DowntimeTrackerContext = createContext(initialState);

const DowntimeTrackerProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <DowntimeTrackerContext.Provider value={stateAndDispatch}>
      {children}
    </DowntimeTrackerContext.Provider>
  );
};

export const useDowntimeTracker = () => useContext(DowntimeTrackerContext);

export { DowntimeTrackerContext, DowntimeTrackerProvider, ACTIONS };
