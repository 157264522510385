import { faArchive, faSearch, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  InputGroupText,
  Input,
  InputGroup,
  Badge,
} from "reactstrap";

import InformationModal from "../../components/InformationModal";
import Loader from "../../components/Loader";

import { ACTIONS, MAX_PAGE_SIZE, useJobs } from "../../providers/jobsProvider";

import { useGetJobs, useArchiveJob } from "../../api/Jobs.hooks";
import { useHistory } from "react-router-dom";
import { utils } from "../../utils/utils";
import ConfirmationModal from "../../components/ConfirmationModal";

const TABLE_COLUMNS = (onSort) => [
  {
    dataField: "job.id",
    text: "Job#",
    classes: "text-truncate p-3",
    sort: true,
    formatter: (_, job) => job.id || "-",
    onSort,
  },
  {
    dataField: "machine.name",
    text: "Machines",
    classes: "text-truncate p-3",
    sort: true,
    formatter: (_, job) => getMachine(job) || "-",
    onSort,
  },
  {
    dataField: "status",
    text: "Status",
    classes: "text-truncate p-3",
    sort: true,
    formatter: (_, job) => utils.jobStatusBadge(job) || "-",
    onSort,
  },
  {
    dataField: "job_step.quantity_completed",
    text: "Progress",
    classes: "text-truncate p-3",
    sort: true,
    formatter: (_, job) => getProgress(job) || "-",
    onSort,
  },
  {
    dataField: "in_progress",
    text: "Shifts In Progress",
    headerClasses: "text-center",
    classes: "text-center p-3",
    sort: true,
    formatter: (_, job) => getShiftsInProgress(job) || "-",
    onSort,
  },
];

const getMachine = (job) => {
  const names = job.jobSteps
    .sort((x, y) => (y.machine.name < x.machine.name ? -1 : 1))
    .reduce((p, c) => [...p, `${c.machine.name}`], []);
  return names.length ? names.join(", ") : "-";
};

const getProgress = (job) => {
  const start_quantity = job.jobSteps.reduce((p, c) => p + c.start_quantity, 0);
  const quantity_completed = job.jobSteps.reduce(
    (p, c) => p + c.quantity_completed,
    0
  );
  return `${quantity_completed} / ${start_quantity}`;
};

const getShiftsInProgress = (job) => {
  if (!job.started) {
    return "N/A";
  } else {
    if (!job.activeJobSteps.length) {
      return <Badge color="success">None</Badge>;
    } else {
      return job.activeJobSteps.map((activeJobStep, index) => (
        <Badge key={index} color="success">
          {getActiveJobSteps(activeJobStep)}
        </Badge>
      ));
    }
  }
};

const getActiveJobSteps = (activeJobStep) =>
  activeJobStep.activeShiftJobStep.length
    ? activeJobStep.activeShiftJobStep[0].shiftEntry.user.name
    : "";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const Jobs = () => {
  const [jobsContext, setJobsContext] = useJobs();
  const [archivedJob, setArchivedJob] = useState();
  const history = useHistory();

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const {
    data: jobs,
    error: jobsErr,
    isLoading: isLoadingJobs,
    get: getJobs,
  } = useGetJobs({});

  const {
    error: archiveJobErr,
    isLoading: isLoadingArchiveJob,
    mutate: archiveJob,
    data: archiveJobData,
  } = useArchiveJob(archivedJob);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const getSizePerPage = (size) => (size === "All" ? MAX_PAGE_SIZE : size);

  const onSort = (sortBy, direction) => {
    if (jobsContext.sortBy === sortBy && jobsContext.direction === direction) {
      return;
    }
    setJobsContext({
      action: ACTIONS.SORT,
      payload: { sortBy, direction },
    });
  };

  const onArchiveJob = (job) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setArchivedJob({ ...job, archived: 1 });
        setConfirmationModal(initConfirmationModal);
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Archive Job",
      body: `Are you sure you want to archive this job?`,
      confirmColor: "danger",
    });
  };

  useEffect(() => {
    if (archivedJob) {
      archiveJob(archivedJob);
    }
  }, [archivedJob, archiveJob]);

  useEffect(() => {
    getJobs({
      search: jobsContext.search,
      page: jobsContext.page,
      page_size: getSizePerPage(jobsContext.sizePerPage),
      orderBy: jobsContext.sortBy,
      direction: jobsContext.direction,
    });
  }, [
    getJobs,
    jobsContext.direction,
    jobsContext.search,
    jobsContext.sortBy,
    jobsContext.refresh,
    jobsContext.page,
    jobsContext.sizePerPage,
  ]);

  useEffect(() => {
    if (jobs) {
      setJobsContext({
        action: ACTIONS.GET_JOBS_SUCCESS,
        payload: jobs,
      });
    }
  }, [jobs, setJobsContext]);

  useEffect(() => {
    if (archiveJobData && archiveJobData.status === 200) {
      setJobsContext({
        action: ACTIONS.REFRESH,
      });
    }
  }, [archiveJobData, setJobsContext]);

  useEffect(() => {
    if (jobsErr || archiveJobErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [jobsErr, archiveJobErr]);

  return (
    <Container className="mx-0" fluid>
      <Card className="col-12 px-0">
        <CardHeader className="d-flex align-items-center justify-content-between">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className="mb-0 ">Jobs</h3>
            <small className="text-muted ml-2 pt-1">
              ({jobsContext.pagination?.rowCount || 0})
            </small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <InputGroup size="m" className="mr-3">
              <Input
                maxLength="50"
                placeholder="Search by.."
                value={jobsContext.search}
                onChange={(evt) =>
                  setJobsContext({
                    action: ACTIONS.SEARCH,
                    payload: { search: evt.target.value },
                  })
                }
              />
              <InputGroupText className="search-jobs input-group-text bg-primary text-white border-left-0 border-primary">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroupText>
            </InputGroup>
            <Button
              size="sm"
              className="rounded-circle square-32"
              color="primary"
              onClick={() =>
                setJobsContext({
                  action: ACTIONS.REFRESH,
                })
              }
            >
              <FontAwesomeIcon icon={faSync} />
            </Button>
          </div>
        </CardHeader>
        <CardBody className="d-flex flex-column">
          {isLoadingJobs || isLoadingArchiveJob ? (
            <Loader />
          ) : jobsContext.jobs?.length ? (
            <BootstrapTable
              bootstrap4
              striped
              remote
              bordered={false}
              keyField="id"
              classes="border"
              headerClasses="small text-muted"
              data={jobsContext.jobs}
              columns={[
                ...TABLE_COLUMNS(onSort),
                {
                  dataField: "id",
                  text: "",
                  classes: "text-right",
                  formatter: (_, job) => (
                    <div className="d-flex justify-content-center">
                      <Button
                        className="rounded mr-2"
                        size="sm"
                        color={"warning"}
                        onClick={() => onArchiveJob(job)}
                      >
                        <FontAwesomeIcon icon={faArchive} />
                      </Button>
                      <Button
                        className="rounded"
                        size="sm"
                        onClick={() => history.push(`/jobs/${job.id}`)}
                      >
                        See Details
                      </Button>
                    </div>
                  ),
                },
              ]}
              defaultSorted={[
                {
                  dataField: jobsContext.sortBy,
                  order: jobsContext.direction,
                },
              ]}
              onTableChange={() => {}}
              pagination={paginationFactory({
                page: jobsContext.page,
                totalSize: jobsContext.pagination.rowCount,
                sizePerPage: getSizePerPage(jobsContext.sizePerPage),
                sizePerPageList: [
                  5,
                  10,
                  25,
                  50,
                  {
                    text: "All",
                    value: jobsContext.pagination.rowCount,
                  },
                ],
                onPageChange: (page) =>
                  setJobsContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  }),
                onSizePerPageChange: (sizePerPage) =>
                  setJobsContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  }),
              })}
            />
          ) : (
            <div className="text-center">No results</div>
          )}
        </CardBody>
      </Card>
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default Jobs;
