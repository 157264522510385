import { useApiGet, useApiMutation } from "./Api.hooks";
import { HttpMethod } from "./Api";

const endpoint = `/api/jobs/csv`;

const useGetSettings = () => {
  return useApiGet("/api/settings");
};

export default useGetSettings;

export const useEnviarFile = (files) => {
  return useApiMutation(HttpMethod.POST, endpoint, files);
};
