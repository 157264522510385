// Auth
import SignIn from "../pages/auth/signIn/SignIn";

import {
  faUsers,
  faHome,
  faChartBar,
  faTable,
  faChartPie,
  faTools,
  faList,
  faCogs,
} from "@fortawesome/free-solid-svg-icons";

import Users from "../pages/admin/Users";
import Home from "../pages/admin/Home";
import Jobs from "../pages/admin/Jobs";
import DowntimeReport from "../pages/admin/DowntimeReport";
import EosReport from "../pages/admin/EosReport";
import ChartsReport from "../pages/admin/charts/ChartsReport";
import DowntimeTracker from "../pages/admin/DowntimeTracker";
import JobDetails from "../pages/admin/JobDetails";
import Machines from "../pages/admin/Machines";
import OeeReport from "../pages/admin/oeeReports/OeeReport";

// Routes
export const adminRoutes = [
  {
    path: "/home",
    name: "Home",
    icon: faHome,
    sidebar: true,
    component: Home,
  },
  {
    path: "/schedule",
    name: "Schedule",
    icon: faCogs,
    sidebar: true,
    component: Machines,
  },
  {
    path: "/downtime",
    name: "Downtime",
    icon: faTools,
    sidebar: true,
    component: DowntimeTracker,
  },
  {
    path: "/jobs",
    name: "Jobs",
    icon: faList,
    sidebar: true,
    component: Jobs,
  },
  {
    path: "/jobs/:jobId",
    name: "Job Details",
    sidebar: false,
    component: JobDetails,
  },
  {
    path: "/reports",
    name: "Reports",
    icon: faChartBar,
    sidebar: true,
    entries: [
      {
        icon: faChartPie,
        name: "Charts",
        path: `/reports/charts`,
        component: ChartsReport,
        sidebar: true,
        roles: [],
      },
      {
        icon: faTable,
        name: "Downtime Report",
        path: `/reports/downtime`,
        component: DowntimeReport,
        sidebar: true,
        roles: [],
      },
      {
        icon: faTable,
        name: "EOS Report",
        path: `/reports/eos`,
        component: EosReport,
        sidebar: true,
        roles: [],
      },
      {
        icon: faTable,
        name: "OEE Report",
        path: `/reports/oee`,
        component: OeeReport,
        sidebar: true,
        roles: [],
      },
    ],
  },
  {
    path: "/admin/users",
    name: "Users",
    icon: faUsers,
    sidebar: true,
    component: Users,
  },
];

export const authRoutes = [
  {
    path: "/auth/sign-in",
    name: "Ingresar",
    component: SignIn,
  },
];
