import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import InformationModal from "../../InformationModal";

import { useCreateTimeEntry } from "../../../api/TimeEntries.hooks";

import DowntimeForm from "./DowntimeForm";
import Loader from "../../Loader";

const AddDowntimeModal = ({ shift, onClose, onSubmit }) => {
  const [newDowntime, setNewDowntime] = useState({
    shift_entry_id: shift.id,
  });

  const [timeValidation, setTimeValidation] = useState({
    startTime: true,
    endTime: true,
  });

  const {
    data: createTimeEntryData,
    error: createTimeEntryErr,
    isLoading: isLoadingCreateTimeEntry,
    mutate: createTimeEntry,
  } = useCreateTimeEntry();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    if (createTimeEntryErr) {
      setInformationModal({
        isOpen: true,
        title: "Create Downtime Error",
        body:
          createTimeEntryErr?.response?.data[0].msg ||
          "There was an error with your request.",
      });
    }
  }, [createTimeEntryErr]);

  useEffect(() => {
    if (createTimeEntryData) {
      onSubmit();
    }
  }, [createTimeEntryData, onSubmit]);

  const doSubmit = async (e) => {
    e.preventDefault();
    if (!timeValidation.startTime) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "Invalid start time. Check the data consistency before saving.",
      });
    } else if (!timeValidation.endTime) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "Invalid end time. Check the data consistency before saving.",
      });
    }
    createTimeEntry(newDowntime);
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="md">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          Add Downtime Entry
        </ModalHeader>
        <ModalBody>
          {isLoadingCreateTimeEntry ? (
            <Loader size="sm" />
          ) : (
            <DowntimeForm
              shift={shift}
              downtime={newDowntime}
              setDowntime={setNewDowntime}
              timeValidation={timeValidation}
              setTimeValidation={setTimeValidation}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddDowntimeModal;
