import React, { useEffect, useState } from "react";

import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { Tool } from "react-feather";

import ExtendedDowntimeModal from "./ExtendedDowntimeModal";
import InformationModal from "../../InformationModal";
import MachineStatus from "./MachineStatus";
import { useCancelExtendedDowntime } from "../../../api/ExtendedDowntime.hooks";
import ConfirmationModal from "../../ConfirmationModal";
import Loader from "../../Loader";
import { ACTIONS, useHome } from "../../../providers/homeProvider";

const MODE_KIOSK = "MODE_KIOSK";

const Machine = ({ machine = {}, mode }) => {
  const [, setHomeContext] = useHome();
  const [extendedDowntimeModal, setExtendedDowntimeModal] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const hasActiveExtendedDowntime = () =>
    machine.activeExtendedDowntime?.length;

  const onAddExtendedDowntime = () => {
    if (machine.activeShiftEntry?.length) {
      return setInformationModal({
        isOpen: true,
        title: "Add Extended Downtime",
        body: "The machine has a shift in progress",
      });
    }
    if (machine.activeExtendedDowntime?.length) {
      return setInformationModal({
        isOpen: true,
        title: "Add Extended Downtime",
        body: "The machine has an extended downtime in progress",
      });
    }
    setExtendedDowntimeModal(true);
  };

  const [activeExtendedDowntimeId, setActiveExtendedDowntimeId] = useState();
  const {
    data: cancelExtendedDowntimeData,
    error: cancelExtendedDowntimeErr,
    isLoading: isLoadingCancelExtendedDowntime,
    cancel: cancelExtendedDowntime,
  } = useCancelExtendedDowntime(activeExtendedDowntimeId);

  const onCancelExtendedDowntime = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        const id = machine.activeExtendedDowntime[0].id;
        setActiveExtendedDowntimeId(id);
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Cancel Extended Downtime",
      body: `
            <div class="text-center">
                Do you confirm you want to cancel the extended downtime? Past downtime will be preserved and future downtimes will be removed.
            </div>
      `,
      confirmColor: "danger",
    });
  };

  useEffect(() => {
    if (activeExtendedDowntimeId) {
      cancelExtendedDowntime();
    }
  }, [cancelExtendedDowntime, activeExtendedDowntimeId]);

  useEffect(() => {
    if (cancelExtendedDowntimeErr) {
      setInformationModal({
        isOpen: true,
        title: "Create Extended Downtime",
        body:
          cancelExtendedDowntimeErr?.response?.data[0].msg ||
          "There was an error with your request.",
      });
    }
  }, [cancelExtendedDowntimeErr]);

  useEffect(() => {
    if (cancelExtendedDowntimeData) {
      setInformationModal({
        isOpen: true,
        title: "Cancel Extended Downtime",
        body: "Extended downtime canceled successfully.",
        onClose: () =>
          setHomeContext({
            action: ACTIONS.REFRESH,
          }),
      });
    }
  }, [cancelExtendedDowntimeData, setHomeContext]);

  return (
    <div
      style={{ order: machine.order }}
      className={`col-${machine.width} my-2`}
    >
      <div className="d-flex flex-column justify-content-between text-center border rounded">
        <div className="d-flex bg-light align-items-center justify-content-between flex-shrink-0 border-bottom">
          <div className="col-2"></div>
          <div className="font-weight-bold text-uppercase col-8">
            <span>{machine.name}</span>
          </div>
          {mode !== MODE_KIOSK ? (
            <div className="col-2 px-0 text-right">
              {isLoadingCancelExtendedDowntime ? (
                <Loader size="sm" align="end" />
              ) : (
                <UncontrolledDropdown className="d-inline-block small">
                  <DropdownToggle tag="a">
                    <Button
                      className="py-1"
                      id={`tooltip-${machine.id}`}
                      size="sm"
                      color="primary"
                    >
                      <Tool />
                    </Button>
                  </DropdownToggle>
                  <DropdownMenu right>
                    {!hasActiveExtendedDowntime() ? (
                      <DropdownItem
                        className="small"
                        onClick={onAddExtendedDowntime}
                      >
                        Add Extended Downtime
                      </DropdownItem>
                    ) : (
                      <DropdownItem
                        className="small"
                        onClick={onCancelExtendedDowntime}
                      >
                        Remove Extended Downtime
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </div>
          ) : null}
        </div>
        <div className="d-flex flex-column mr-3 ml-3">
          <img
            className="mb-3 mt-3 max-height-100"
            src={`/images/machines/${machine.image}`}
            alt={machine.name}
          />
        </div>
        <MachineStatus machine={machine} />
      </div>
      {extendedDowntimeModal && (
        <ExtendedDowntimeModal
          key={machine.id}
          machine={machine}
          onClose={() => {
            setExtendedDowntimeModal(false);
          }}
          onSubmit={() => {
            setExtendedDowntimeModal(false);
            return setInformationModal({
              isOpen: true,
              title: "Add Extended Downtime",
              body: "Extended downtime added successfully",
              onClose: () =>
                setHomeContext({
                  action: ACTIONS.REFRESH,
                }),
            });
          }}
        />
      )}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </div>
  );
};

export default Machine;
