import React, { useContext } from "react";
import socketio from "socket.io-client";
import config from "../config/config";

const socket = socketio(config.socketURL);
const SocketContext = React.createContext();

const SocketProvider = ({ children }) => {
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);

export { SocketProvider };
