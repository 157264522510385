import axios from "axios";
import config from "../config/config";
import { utils } from "../utils/utils";

export const HttpMethod = {
  GET: "get",
  PATCH: "patch",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const apiHelper = async (method, path, params = {}) => {
  let requestOptions = {
    baseURL: config.apiURL,
    withCredentials: true,
    method: method,
  };
  if (method === HttpMethod.GET || method === HttpMethod.DELETE) {
    const query = utils.buildQueryString(params);
    path = `${path}?${query}`;
  } else if (
    method === HttpMethod.PATCH ||
    method === HttpMethod.POST ||
    method === HttpMethod.PUT
  ) {
    requestOptions = {
      ...requestOptions,
      data: params,
    };
  }
  let response;
  try {
    response = await axios(path, requestOptions);
  } catch (error) {
    handleError(error);
  }
  return response;
};

export const Api = {
  async get(path, params) {
    return apiHelper(HttpMethod.GET, path, params);
  },
  async post(path, params) {
    return apiHelper(HttpMethod.POST, path, params);
  },
  async put(path, params) {
    return apiHelper(HttpMethod.PUT, path, params);
  },
  async delete(path, params) {
    return apiHelper(HttpMethod.DELETE, path, params);
  },
};
