import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { Printer } from "react-feather";
import classnames from "classnames";

import {
  Card,
  CardHeader,
  Container,
  InputGroup,
  Input,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import InformationModal from "../../../components/InformationModal";
import {
  ACTIONS,
  useChartsReport,
} from "../../../providers/chartsReportProvider";
import useGetLines from "../../../api/Lines.hooks";
import useGetCharts from "../../../api/Charts.hooks";
import ProductionChart from "./ProductionChart";
import DowntimeChart from "./DowntimeChart";
import AverageDowntimePerDay from "./AverageDowntimePerDay";
import AverageDowntimePerWeek from "./AverageDowntimePerWeek";

const PRODUCTION_TAB_CHART = "PRODUCTION_TAB_CHART";
const DOWNTIME_TAB_CHART = "DOWNTIME_TAB_CHART";
const AVERAGE_DAY_TAB_CHART = "AVERAGE_DAY_TAB_CHART";
const AVERAGE_WEEK_TAB_CHART = "AVERAGE_WEEK_TAB_CHART";

const WEEKS_FOR_AVG = 10;

const ChartsReport = () => {
  const [chartsReportContext, setChartsReportContext] = useChartsReport();

  const [activeTab, setActiveTab] = useState(PRODUCTION_TAB_CHART);
  const [startDate, setStartDate] = useState(chartsReportContext.startDate);
  const [endDate, setEndDate] = useState(chartsReportContext.endDate);

  const { data: lines, error: linesErr, get: getLines } = useGetLines();
  const { data: charts, error: chartsErr, get: getCharts } = useGetCharts();

  const {
    data: extCharts,
    error: extChartsErr,
    get: getExtCharts,
  } = useGetCharts();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const setChartsReportContextCb = useCallback(
    (data) => setChartsReportContext(data),
    [setChartsReportContext]
  );

  useEffect(() => {
    getLines();
  }, [getLines]);

  useEffect(() => {
    getCharts({
      start_date: chartsReportContext.startDate,
      end_date: chartsReportContext.endDate,
      line_id: chartsReportContext.selectedLine,
      extended: true,
    });
  }, [
    chartsReportContext.endDate,
    chartsReportContext.selectedLine,
    chartsReportContext.startDate,
    getCharts,
  ]);

  useEffect(() => {
    getExtCharts({
      start_date: moment(chartsReportContext.startDate)
        .add(-WEEKS_FOR_AVG, "week")
        .format("YYYY-MM-DD"),
      end_date: chartsReportContext.endDate,
      line_id: chartsReportContext.selectedLine,
    });
  }, [
    chartsReportContext.endDate,
    chartsReportContext.selectedLine,
    chartsReportContext.startDate,
    getExtCharts,
  ]);

  useEffect(() => {
    if (lines) {
      setChartsReportContextCb({
        action: ACTIONS.GET_LINES_SUCCESS,
        payload: { lines },
      });
    }
  }, [lines, setChartsReportContextCb]);

  useEffect(() => {
    if (charts) {
      setChartsReportContextCb({
        action: ACTIONS.GET_CHARTS_SUCCESS,
        payload: { charts },
      });
    }
  }, [charts, setChartsReportContextCb]);

  useEffect(() => {
    if (extCharts) {
      setChartsReportContextCb({
        action: ACTIONS.GET_EXT_CHARTS_SUCCESS,
        payload: { extCharts },
      });
    }
  }, [extCharts, setChartsReportContextCb]);

  useEffect(() => {
    if (linesErr || chartsErr || extChartsErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [linesErr, chartsErr, extChartsErr]);

  useEffect(() => {
    setChartsReportContextCb({
      action: ACTIONS.DATE_FILTER_CHANGE,
      payload: { startDate, endDate },
    });
  }, [setChartsReportContextCb, startDate, endDate]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <Container className="mx-0" fluid>
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className="mb-0 ">Charts</h3>
          </div>
          <div className="d-flex align-items-center justify-content-end flex-shrink-0">
            <InputGroup size="m" className="mr-3">
              <Input
                type="select"
                name="lineSelect"
                id="lineSelect"
                onChange={(evt) =>
                  setChartsReportContextCb({
                    action: ACTIONS.LINE_FILTER_CHANGE,
                    payload: {
                      selectedLine: evt.target.value,
                    },
                  })
                }
                value={chartsReportContext.selectedLine}
              >
                {chartsReportContext.lines?.length &&
                  chartsReportContext.lines.map((line) => (
                    <option key={line.id} value={line.id}>
                      {line.name}
                    </option>
                  ))}
              </Input>
            </InputGroup>
            <InputGroup
              size="m"
              className="d-flex align-items-center mr-3 flex-nowrap"
            >
              <Input
                className="min-width-150"
                type="date"
                name="startDatePicker"
                id="startDatePicker"
                value={startDate}
                onChange={(evt) => setStartDate(evt.target.value)}
              />
              <span className="mr-2 ml-2">to</span>
              <Input
                className="min-width-150"
                type="date"
                name="endDatePicker"
                id="endDatePicker"
                value={endDate}
                onChange={(evt) => setEndDate(evt.target.value)}
              />
            </InputGroup>
            <Button
              size="sm"
              className="rounded-circle refresh-job"
              color="primary"
              onClick={() => window.print()}
            >
              <Printer />
            </Button>
          </div>
        </CardHeader>
        <Nav
          tabs
          className="d-flex align-items-center work-order-details-nav cursor-pointer mt-3"
        >
          <div className="d-flex flex-grow-1">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === PRODUCTION_TAB_CHART,
                })}
                onClick={() => toggleTab(PRODUCTION_TAB_CHART)}
              >
                Production Chart
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === DOWNTIME_TAB_CHART,
                })}
                onClick={() => toggleTab(DOWNTIME_TAB_CHART)}
              >
                Downtime Chart
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === AVERAGE_DAY_TAB_CHART,
                })}
                onClick={() => toggleTab(AVERAGE_DAY_TAB_CHART)}
              >
                Average Downtime per Day
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === AVERAGE_WEEK_TAB_CHART,
                })}
                onClick={() => toggleTab(AVERAGE_WEEK_TAB_CHART)}
              >
                Average Downtime per Week
              </NavLink>
            </NavItem>
          </div>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={PRODUCTION_TAB_CHART}>
            {activeTab === PRODUCTION_TAB_CHART ? <ProductionChart /> : null}
          </TabPane>
          <TabPane tabId={DOWNTIME_TAB_CHART}>
            {activeTab === DOWNTIME_TAB_CHART ? <DowntimeChart /> : null}
          </TabPane>
          <TabPane tabId={AVERAGE_DAY_TAB_CHART}>
            {activeTab === AVERAGE_DAY_TAB_CHART ? (
              <AverageDowntimePerDay />
            ) : null}
          </TabPane>
          <TabPane tabId={AVERAGE_WEEK_TAB_CHART}>
            {activeTab === AVERAGE_WEEK_TAB_CHART ? (
              <AverageDowntimePerWeek />
            ) : null}
          </TabPane>
        </TabContent>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
    </Container>
  );
};

export default ChartsReport;
