import React, { useReducer, createContext, useContext } from "react";
import moment from "moment";

const MAX_PAGE_SIZE = 100;
const INIT_PAGE_SIZE = 10;
const INIT_PAGE = 1;

const initialState = {
  jobProduction: {},
  pagination: {},
  sizePerPage: INIT_PAGE_SIZE,
  page: INIT_PAGE,
  sortBy: "date",
  direction: "desc",
  machines: {},
  selectedMachine: "",
  startDate: "",
  endDate: "",
};

const ACTIONS = {
  SORT: "SORT",
  REFRESH: "REFRESH",
  PAGE_CHANGE: "PAGE_CHANGE",
  PAGE_SIZE_CHANGE: "PAGE_SIZE_CHANGE",
  GET_JOB_PRODUCTION_SUCCESS: "GET_JOB_PRODUCTION_SUCCESS",
  GET_MACHINES_SUCCESS: "GET_MACHINES_SUCCESS",
  MACHINE_FILTER_CHANGE: "MACHINE_FILTER_CHANGE",
  DATE_FILTER_CHANGE: "DATE_FILTER_CHANGE",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_JOB_PRODUCTION_SUCCESS: {
      const { payload } = data;
      const { jobStepProductions, pagination } = payload;

      return {
        ...state,
        jobProduction: jobStepProductions,
        pagination,
      };
    }

    case ACTIONS.GET_MACHINES_SUCCESS: {
      const { payload } = data;
      const { machines } = payload;

      return {
        ...state,
        machines,
      };
    }

    case ACTIONS.SORT: {
      const { payload } = data;
      const { sortBy, direction } = payload;

      return {
        ...state,
        sortBy,
        direction: direction,
        page: 1,
      };
    }

    case ACTIONS.PAGE_CHANGE: {
      const { payload } = data;
      const { page } = payload;
      return {
        ...state,
        page,
      };
    }

    case ACTIONS.PAGE_SIZE_CHANGE: {
      const { payload } = data;
      const { sizePerPage } = payload;
      return {
        ...state,
        sizePerPage,
        page: INIT_PAGE,
      };
    }

    case ACTIONS.MACHINE_FILTER_CHANGE: {
      const { payload } = data;
      const { selectedMachine } = payload;
      return {
        ...state,
        selectedMachine,
      };
    }

    case ACTIONS.DATE_FILTER_CHANGE: {
      const { payload } = data;
      const { startDate, endDate } = payload;
      return {
        ...state,
        startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
      };
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    default:
      throw new Error();
  }
};

const JobProductionContext = createContext(initialState);

const JobProductionProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <JobProductionContext.Provider value={stateAndDispatch}>
      {children}
    </JobProductionContext.Provider>
  );
};

export const useJobProduction = () => useContext(JobProductionContext);

export { JobProductionContext, JobProductionProvider, ACTIONS, MAX_PAGE_SIZE };
