import { useCallback, useState } from "react";
import { Api, apiHelper } from "./Api";

export const useApiGet = (path) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const get = useCallback(
    async (params) => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await Api.get(path, params);
        setData(response.data);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    },
    [path, setData, setIsLoading]
  );

  return { data, error, isLoading, get };
};

export function useApiMutation(method, path) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const mutate = useCallback(
    async (params) => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await apiHelper(method, path, params);
        setData(response);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    },
    [method, path, setData, setIsLoading, setError]
  );

  return { data, error, isLoading, mutate };
}
