import React, { useState, useEffect } from "react";
import moment from "moment";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCheck, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import {
  useShifts,
  ACTIONS,
  SHIFT_STATUS,
} from "../../../providers/shiftsTableProvider";

import ConfirmationModal from "../../ConfirmationModal";
import InformationModal from "../../InformationModal";
import TooltipItem from "../../TooltipItem";

import {
  useDeleteShiftEntry,
  useUpdateShiftEntry,
} from "../../../api/ShiftEntries.hooks";

import AddDowntimeModal from "./AddDowntimeModal";
import TimeEntryDetails from "./TimeEntryDetails";
import ShiftProduction from "./ShiftProduction";

const SHIFT_EDIT_ACTIONS = {
  COMPLETE: 0,
  APPROVE: 1,
};

const SHIFT_TYPE_DOWNTIME = "TYPE_DOWNTIME";

const ShiftDetails = ({ shift }) => {
  const [, setShiftsContext] = useShifts();

  const [deleteShiftEntryId, setDeleteShiftEntryId] = useState();
  const {
    error: deleteShiftEntryErr,
    data: deleteShiftEntryData,
    deleteShiftEntry,
  } = useDeleteShiftEntry(deleteShiftEntryId);

  const {
    error: updateShiftEntryErr,
    data: updateShiftEntryData,
    update: updateShiftEntry,
  } = useUpdateShiftEntry(shift.id);

  const [addDowntimeModal, setAddDowntimeModal] = useState(false);

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const isShiftApproved = () =>
    parseInt(shift.status) === SHIFT_STATUS.SHIFT_STATUS_APPROVED;

  const isShiftCompleted = () =>
    parseInt(shift.status) === SHIFT_STATUS.SHIFT_STATUS_COMPLETED;

  const onDeleteShift = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setDeleteShiftEntryId(shift.id);
        setConfirmationModal(initConfirmationModal);
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Remove Shift",
      body: `Are you sure you want to delete this shift?`,
      confirmColor: "danger",
    });
  };

  const confirmEditShiftEntry = (action) => {
    const shiftToUpdate = { ...shift };
    shiftToUpdate.status =
      (action === SHIFT_EDIT_ACTIONS.APPROVE
        ? SHIFT_STATUS.SHIFT_STATUS_APPROVED
        : SHIFT_STATUS.SHIFT_STATUS_COMPLETED) + "";
    if (action === SHIFT_EDIT_ACTIONS.COMPLETE) {
      shiftToUpdate.ended_on = moment().toDate();
    }
    updateShiftEntry(shiftToUpdate);
  };

  const onApproveShift = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        confirmEditShiftEntry(SHIFT_EDIT_ACTIONS.APPROVE);
        setConfirmationModal(initConfirmationModal);
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Approve Shift",
      body: `Are you sure you want to approve this shift?`,
      confirmColor: "primary",
    });
  };

  const onCompleteShift = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        confirmEditShiftEntry(SHIFT_EDIT_ACTIONS.COMPLETE);
        setConfirmationModal(initConfirmationModal);
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Complete Shift",
      body: `Are you sure you want to complete this shift?`,
      confirmColor: "primary",
    });
  };

  useEffect(() => {
    if (deleteShiftEntryId) {
      deleteShiftEntry();
    }
  }, [deleteShiftEntry, deleteShiftEntryId]);

  useEffect(() => {
    if (deleteShiftEntryData) {
      setShiftsContext({
        action: ACTIONS.REFRESH,
      });
    }
  }, [deleteShiftEntryData, setShiftsContext]);

  useEffect(() => {
    if (deleteShiftEntryErr) {
      setInformationModal({
        isOpen: true,
        title: "Delete Shift Entry",
        body: "There was an error with your request.",
      });
    }
  }, [deleteShiftEntryErr]);

  useEffect(() => {
    if (updateShiftEntryData) {
      setShiftsContext({
        action: ACTIONS.REFRESH,
      });
    }
  }, [updateShiftEntryData, setShiftsContext]);

  useEffect(() => {
    if (updateShiftEntryErr) {
      setInformationModal({
        isOpen: true,
        title: "Update Shift Entry",
        body: "There was an error with your request.",
      });
    }
  }, [updateShiftEntryErr]);

  return (
    <>
      <div>
        <div className="small col-12 py-3 border-bottom text-muted d-flex bg-lighter justify-content-evenly font-weight-bold">
          <div className="col-2">Downtime</div>
          <div className="col-2">Reason</div>
          <div className="col-2">Notes</div>
          <div className="col-2">Status</div>
          <div className="col-2">Quick Time</div>
          <div className="text-right col-2">Actions</div>
        </div>
        {shift?.timeEntries?.length ? (
          shift.timeEntries.map((timeEntry, index) => (
            <TimeEntryDetails
              key={index}
              isExtendedDowntime={shift.type !== SHIFT_TYPE_DOWNTIME}
              timeEntry={timeEntry}
            />
          ))
        ) : (
          <div className="col-12 py-3 text-center small text-muted">
            No Downtime Recorded
          </div>
        )}
      </div>
      {shift.shiftJobSteps?.length ? (
        shift.shiftJobSteps.map((shiftJobStep, index) => (
          <ShiftProduction key={index} shiftJobStep={shiftJobStep} />
        ))
      ) : shift.type !== SHIFT_TYPE_DOWNTIME ? (
        <div className="col-12 py-3 text-center small text-muted">
          No Production Recorded
        </div>
      ) : null}
      {shift.type !== SHIFT_TYPE_DOWNTIME ? (
        <div className="col-12 d-flex justify-content-evenly border-top pt-3 pb-3">
          <TooltipItem id="tooltip-remove-shift" title="Remove Shift">
            <Button
              id="tooltip-remove-shift"
              size="md"
              className="text-center no-grow mr-2"
              color="danger"
              onClick={() => onDeleteShift()}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </TooltipItem>
          <Button
            size="md"
            className="flex-grow-1 text-center mr-2"
            color="secondary"
            onClick={() => setAddDowntimeModal(true)}
          >
            <FontAwesomeIcon className="mr-2" icon={faPlus} />
            DOWNTIME
          </Button>
          {!isShiftApproved() && !isShiftCompleted() && (
            <Button
              size="md"
              className="flex-grow-1 text-center"
              color="primary"
              onClick={() => onCompleteShift()}
            >
              <FontAwesomeIcon className="mr-2" icon={faCheck} />
              COMPLETE
            </Button>
          )}
          {!isShiftApproved() && isShiftCompleted() && (
            <Button
              size="md"
              className="flex-grow-1 text-center"
              color="primary"
              onClick={() => onApproveShift()}
            >
              <FontAwesomeIcon className="mr-2" icon={faCheck} />
              APPROVE
            </Button>
          )}
        </div>
      ) : null}
      {addDowntimeModal ? (
        <AddDowntimeModal
          shift={shift}
          onClose={() => {
            setAddDowntimeModal(false);
          }}
          onSubmit={() => {
            setAddDowntimeModal(false);
            setShiftsContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
    </>
  );
};

export default ShiftDetails;
