import React, { useReducer, createContext, useContext } from "react";
import moment from "moment";

const initialState = {
  charts: [],
  extCharts: [],
  lines: [],
  selectedLine: 1,
  startDate: moment().format("YYYY-MM-DD"),
  endDate: moment().add(1, "week").format("YYYY-MM-DD"),
};

const ACTIONS = {
  GET_CHARTS_SUCCESS: "GET_CHARTS_SUCCESS",
  GET_EXT_CHARTS_SUCCESS: "GET_EXT_CHARTS_SUCCESS",
  GET_LINES_SUCCESS: "GET_LINES_SUCCESS",
  LINE_FILTER_CHANGE: "LINE_FILTER_CHANGE",
  DATE_FILTER_CHANGE: "DATE_FILTER_CHANGE",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_CHARTS_SUCCESS: {
      const { payload } = data;
      const { charts } = payload;

      return {
        ...state,
        charts,
      };
    }

    case ACTIONS.GET_EXT_CHARTS_SUCCESS: {
      const { payload } = data;
      const { extCharts } = payload;

      return {
        ...state,
        extCharts,
      };
    }

    case ACTIONS.GET_LINES_SUCCESS: {
      const { payload } = data;
      const { lines } = payload;

      return {
        ...state,
        lines,
      };
    }

    case ACTIONS.LINE_FILTER_CHANGE: {
      const { payload } = data;
      const { selectedLine } = payload;
      return {
        ...state,
        selectedLine,
      };
    }

    case ACTIONS.DATE_FILTER_CHANGE: {
      const { payload } = data;
      const { startDate, endDate } = payload;
      return {
        ...state,
        startDate: startDate
          ? moment(startDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
        endDate: endDate
          ? moment(endDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
      };
    }

    default:
      throw new Error();
  }
};

const ChartsReportContext = createContext(initialState);

const ChartsReportProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <ChartsReportContext.Provider value={stateAndDispatch}>
      {children}
    </ChartsReportContext.Provider>
  );
};

export const useChartsReport = () => useContext(ChartsReportContext);

export { ChartsReportContext, ChartsReportProvider, ACTIONS };
