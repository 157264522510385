import { useCallback } from "react";
import { useApiGet, useApiMutation } from "./Api.hooks";
import { HttpMethod } from "./Api";

const endpoint = `/api/job-steps`;

export const useGetJobSteps = () => {
  return useApiGet(endpoint);
};

export const useCreateJobStep = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useUpdateJobStep = (jobStepId) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PUT,
    `${endpoint}/${jobStepId}`
  );

  const update = useCallback(
    async (jobStep) => {
      await mutate(jobStep);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteJobStep = (jobStepId) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.DELETE,
    `${endpoint}/${jobStepId}`
  );

  const deleteJobStep = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, deleteJobStep };
};
