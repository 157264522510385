import { useCallback } from "react";
import { useApiGet, useApiMutation } from "./Api.hooks";
import { HttpMethod } from "./Api";

const endpoint = `/api/shiftEntries`;

export const useGetShiftEntries = () => {
  return useApiGet(endpoint);
};

export const useUpdateShiftEntry = (shiftId) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PUT,
    `${endpoint}/${shiftId}`
  );

  const update = useCallback(
    async (shiftEntry) => {
      await mutate(shiftEntry);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteShiftEntry = (shiftId) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.DELETE,
    `${endpoint}/${shiftId}`
  );

  const deleteShiftEntry = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, deleteShiftEntry };
};
