import React, { useEffect, useState } from "react";

import Select from "react-select";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import InformationModal from "../../InformationModal";
import makeAnimated from "react-select/animated";
import useGetMachines from "../../../api/Machines.hooks";
import { useUpdateJobStep } from "../../../api/JobSteps.hooks";
import Loader from "../../Loader";

const MAX_PAGE_SIZE = 30;

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};

const animatedComponents = makeAnimated();

const EditMachineJobModal = ({ jobStep, onClose, onSubmit }) => {
  const [selectedMachine, setSelectedMachine] = useState();
  const [search, setSearch] = useState();
  const [machines, setMachines] = useState([]);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const {
    data: machinesData,
    error: machinesErr,
    isLoading: isLoadingMachines,
    get: getMachines,
  } = useGetMachines({});

  const {
    error: updateJobStepErr,
    isLoading: isLoadingUpdateJobStep,
    update: updateJobStep,
    data: updateJobStepData,
  } = useUpdateJobStep(jobStep.id);

  useEffect(() => {
    if (jobStep) {
      setSelectedMachine({
        value: jobStep.machine_id,
        label: jobStep.machine_name,
      });
    }
  }, [jobStep]);

  useEffect(() => {
    getMachines({
      search,
      page_size: MAX_PAGE_SIZE,
    });
  }, [getMachines, search]);

  useEffect(() => {
    if (machinesData) {
      setMachines(machinesData.machines);
    }
  }, [machinesData]);

  useEffect(() => {
    if (machinesErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "s was an error with your request.",
      });
    }
  }, [machinesErr]);

  useEffect(() => {
    if (updateJobStepErr) {
      setInformationModal({
        isOpen: true,
        title: "Update Job Step",
        body:
          updateJobStepErr?.response?.data[0].msg ||
          "There was an error with your request.",
      });
    }
  }, [updateJobStepErr]);

  useEffect(() => {
    if (updateJobStepData) {
      onSubmit();
    }
  }, [updateJobStepData, onSubmit]);

  const onChange = (selected) => {
    const id = selected?.value;
    const selectedMachine = machines.find((j) => j.id === id);
    if (selectedMachine) {
      setSelectedMachine({
        value: selectedMachine.id,
        label: selectedMachine.name,
        jobSteps: selectedMachine.jobSteps,
      });
    } else {
      setSelectedMachine();
    }
  };

  const doSubmit = (evt) => {
    evt.preventDefault();
    if (!selectedMachine) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "Please select a job",
      });
    }
    const existingJobStep = selectedMachine.jobSteps?.find(
      (js) => js.job_id === jobStep.job_id && !js.completed
    );
    if (existingJobStep) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "Job has another step already assigned to the machine.",
      });
    }
    updateJobStep({
      ...jobStep,
      order: Number.MAX_SAFE_INTEGER,
      machine_id: selectedMachine.value,
    });
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose} size="sm">
      {isLoadingUpdateJobStep ? (
        <div className="my-3">
          <Loader size="sm" />
        </div>
      ) : (
        <Form onSubmit={doSubmit}>
          <ModalHeader close={closeBtn}>Edit Job Assignation</ModalHeader>
          <ModalBody>
            <FormGroup className="mb-0">
              <Select
                placeholder={
                  <span className="text-muted">Search for a job..</span>
                }
                noOptionsMessage={() => "No machines found"}
                styles={style}
                className="flex-grow-1 border rounded my-2"
                options={machines
                  .filter((machine) => selectedMachine?.value !== machine.id)
                  .map((machine) => ({
                    value: machine.id,
                    label: machine.name,
                  }))}
                closeMenuOnSelect={true}
                components={animatedComponents}
                isSearchable
                isClearable
                inputValue={search}
                onInputChange={setSearch}
                onChange={onChange}
                isLoading={isLoadingMachines}
                value={selectedMachine}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter className="justify-content-between align-items-center">
            <Button color={"primary"} onClick={onClose}>
              Discard
            </Button>
            <Button type="submit" color={"success"}>
              Save
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Modal>
  );
};

export default EditMachineJobModal;
