import React from "react";
import moment from "moment";

const JOB_STATUS_IN_PROGRESS = "1";
const JOB_STATUS_COMPLETED = "2";
const JOB_STATUS_NOT_STARTED_ORACLE = "3";
const JOB_STATUS_CLOSED = "4";

export const utils = {
  JOB_STATUSES: [
    { id: JOB_STATUS_IN_PROGRESS, name: "In Progress" },
    { id: JOB_STATUS_COMPLETED, name: "Completed" },
    { id: JOB_STATUS_NOT_STARTED_ORACLE, name: "Not Started in Oracle" },
    { id: JOB_STATUS_CLOSED, name: "Closed" },
  ],
  buildQueryString: (data) =>
    Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&"),
  formatCurrency: (number, maximumFractionDigits = 2) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits,
    }).format(number),
  formatDecimal: (number, maximumFractionDigits = 2) =>
    new Intl.NumberFormat("en-US", { maximumFractionDigits }).format(number),
  formatPercent: (progress, total) =>
    `${total ? ((progress / total) * 100).toFixed(2) : 0}%`,
  formatDate: (date, format = "MM/DD/YYYY") =>
    (date ? moment(date) : moment()).format(format),
  formatDateTime: (date, format = "MM/DD/YYYY, h:mm a") =>
    (date ? moment(date) : moment()).format(format),
  capitalize: (text) =>
    text.charAt(0).toUpperCase() + text.toLowerCase().slice(1),
  validateEmail: (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  },
  timeFromSeconds: (secs, separator) => {
    const pad = (num) => ("0" + num).slice(-2);
    let minutes = Math.floor(secs / 60);
    const hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    secs = secs % 60;
    return separator
      ? `${pad(hours)}:${pad(minutes)}:${pad(secs)}`
      : `${hours}h ${pad(minutes)}min`;
  },
  formatTime: (seconds) => {
    if (seconds < 0) {
      return "";
    }
    const minutes = Math.floor(parseInt(seconds) / 60);
    const hh = Math.floor(minutes / 60);
    const mm = minutes % 60;
    return `${hh < 10 ? `0${hh}` : hh}h ${mm < 10 ? `0${mm}` : mm}m`;
  },
  jobStatusBadge: (job) => {
    if (job.status === "closed") {
      return <span className="badge badge-dark">Closed</span>;
    } else if (job.ended) {
      return <span className="badge badge-success">Completed</span>;
    } else if (!job.started && !job.ended) {
      return <span className="badge badge-warning">Not started in Oracle</span>;
    } else if (job.started && !job.ended) {
      return <span className="badge badge-primary">In Progress</span>;
    } else {
      return null;
    }
  },
  jobStatus: (job) => {
    if (job.status === "closed") {
      return JOB_STATUS_CLOSED;
    } else if (job.ended) {
      return JOB_STATUS_COMPLETED;
    } else if (!job.started && !job.ended) {
      return JOB_STATUS_NOT_STARTED_ORACLE;
    } else if (job.started && !job.ended) {
      return JOB_STATUS_IN_PROGRESS;
    } else {
      return null;
    }
  },
};
