import React, { useState, useEffect, useCallback } from "react";
import { Badge, Button, Container } from "reactstrap";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import ResolveRunningTimerModal from "./ResolveRunningTimersModal";

import {
  useRunningTimers,
  ACTIONS,
} from "../../../providers/runningTimersProvider";

import { useGetTimeEntries } from "../../../api/TimeEntries.hooks";
import { utils } from "../../../utils/utils";

const GET_EXPIRED_TIME_ENTRIES_PARAMS = {
  expired: 1,
};

const RunningTimers = () => {
  const [resolveRunningTimerModal, setResolveRunningTimerModal] = useState({
    isOpen: false,
    timeEntry: {},
  });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [runningTimersContext, setRunningTimersContext] = useRunningTimers();

  const {
    data: timeEntries,
    error: timeEntriesErr,
    isLoading: isLoadingShifts,
    get: getTimeEntries,
  } = useGetTimeEntries();

  const setRunningTimersContextCb = useCallback(
    (data) => setRunningTimersContext(data),
    [setRunningTimersContext]
  );

  useEffect(() => {
    getTimeEntries(GET_EXPIRED_TIME_ENTRIES_PARAMS);
  }, [getTimeEntries, runningTimersContext.refresh]);

  useEffect(() => {
    if (timeEntries) {
      setRunningTimersContextCb({
        action: ACTIONS.GET_TIMERS_SUCCESS,
        payload: { timeEntries },
      });
    }
  }, [setRunningTimersContextCb, timeEntries]);

  useEffect(() => {
    if (timeEntriesErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [timeEntriesErr]);

  return (
    <Container className="mx-0" fluid>
      {isLoadingShifts ? (
        <Loader />
      ) : runningTimersContext.timeEntries &&
        runningTimersContext.timeEntries.length ? (
        <div className="col-10 offset-1">
          {runningTimersContext.timeEntries.map((timeEntry) => (
            <div
              key={timeEntry.id}
              className="alert bg-white border border-danger text-danger pt-2 pb-2 d-flex align-items-center justify-content-between"
              role="alert"
            >
              <div className="col-6 d-flex align-items-center">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  fixedWidth
                  className="align-center ml-3"
                  size="lg"
                />
                <div className="d-flex flex-column ml-5">
                  <span>Running Time Alert</span>
                  <span className="font-weight-bold">
                    {timeEntry.shiftEntry.user
                      ? timeEntry.shiftEntry.user.name
                      : ""}
                    has a timer still running that needs to be fixed
                  </span>
                </div>
              </div>
              <div className="col-6 d-flex align-items-center justify-content-between">
                <Badge
                  color="danger"
                  className="font-weight-bold text-truncate"
                >
                  {utils.timeFromSeconds(timeEntry.duration)}
                </Badge>
                <Button
                  size="sm"
                  className="rounded"
                  color="danger"
                  onClick={() =>
                    setResolveRunningTimerModal({
                      isOpen: true,
                      timeEntry,
                    })
                  }
                >
                  Fix Timer
                </Button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
      {resolveRunningTimerModal.isOpen && (
        <ResolveRunningTimerModal
          key={resolveRunningTimerModal.timeEntry.id}
          timeEntry={resolveRunningTimerModal.timeEntry}
          onClose={() => {
            setResolveRunningTimerModal({ isOpen: false });
          }}
          onSubmit={() => {
            setResolveRunningTimerModal({ isOpen: false });
            setRunningTimersContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      )}
    </Container>
  );
};

export default RunningTimers;
