import React, { useState } from "react";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledAlert,
} from "reactstrap";

import brand from "../../../assets/img/amico.png";

import { useAuth } from "../../../providers/authProvider";
import { authApi } from "../../../api/authServices";
import Loader from "../../../components/Loader";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const LoginForm = () => {
  const [, setAuthContext] = useAuth();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onDismiss = () => setError(false);

  const doLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await authApi.login({
        email,
        password,
      });
      if (!result) {
        setLoading(false);
        return null;
      }
      setAuthContext({
        currentUser: result,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(
        err.response?.data?.message || "There was an error, please try again."
      );
    }
    return false;
  };

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h2>Welcome back!</h2>
        <p className="lead">Sign in to your account to continue</p>
      </div>
      <Card>
        <CardBody>
          <div className="m-sm-4">
            <div className="text-center">
              <img
                src={brand}
                className="rounded p-0 col-6 my-4"
                alt={"Amico"}
              />
            </div>
            <Form onSubmit={doLogin}>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  maxLength="50"
                  required={true}
                  bsSize="lg"
                  name="email"
                  className="mb-1"
                  type="email"
                  placeholder="Enter your email"
                  onChange={(event) =>
                    setEmail(event.currentTarget.value.trim())
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>Password</Label>
                <Input
                  id="passwordUser"
                  required={true}
                  maxLength="50"
                  bsSize="lg"
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                  onChange={(event) => setPassword(event.currentTarget.value)}
                />
              </FormGroup>
              <div className="text-center mt-3">
                <UncontrolledAlert
                  isOpen={!!error}
                  toggle={onDismiss}
                  color="warning"
                >
                  <div className="alert-icon">
                    <FontAwesomeIcon icon={faBell} fixedWidth />
                  </div>
                  <div className="alert-message text-left">
                    <span className="ml-2">{error}</span>
                  </div>
                </UncontrolledAlert>
                {loading ? (
                  <Loader size="sm" />
                ) : (
                  <Button id="signIn" color="primary" type="submit" size="lg">
                    Sign In
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
