import React, { useReducer, createContext, useContext } from "react";
import moment from "moment";
import { utils } from "../utils/utils";

const MAX_PAGE_SIZE = 100;
const INIT_PAGE_SIZE = 10;
const INIT_PAGE = 1;

const initialState = {
  downtimes: {},
  pagination: {},
  sizePerPage: INIT_PAGE_SIZE,
  page: INIT_PAGE,
  machines: {},
  selectedMachine: "",
  startDate: "",
  endDate: "",
};

const ACTIONS = {
  REFRESH: "REFRESH",
  PAGE_CHANGE: "PAGE_CHANGE",
  PAGE_SIZE_CHANGE: "PAGE_SIZE_CHANGE",
  GET_DOWNTIMES_SUCCESS: "GET_DOWNTIMES_SUCCESS",
  GET_MACHINES_SUCCESS: "GET_MACHINES_SUCCESS",
  MACHINE_FILTER_CHANGE: "MACHINE_FILTER_CHANGE",
  DATE_FILTER_CHANGE: "DATE_FILTER_CHANGE",
};

const formatData = (timeEntries) => {
  let data = [];
  timeEntries.forEach((timeEntry) => {
    const json = {
      id: timeEntry.id,
      date: moment(timeEntry.shiftEntry.date).format("MM/DD/YY"),
      line: timeEntry.shiftEntry.machine.name,
      shift: timeEntry.shiftEntry.shiftSchedule
        ? timeEntry.shiftEntry.shiftSchedule.name
        : "Not Set",
      description:
        timeEntry.notes && timeEntry.notes.length ? timeEntry.notes : "-",
      majorReason: timeEntry.majorReason ? timeEntry.majorReason.name : "-",
      minorReason: timeEntry.minorReason ? timeEntry.minorReason.name : "-",
      downtime: (timeEntry.duration / 60).toFixed(2),
      mm_yr: moment(timeEntry.shiftEntry.date).format("MM/YYYY"),
      week_of: utils.formatDate(
        moment(timeEntry.shiftEntry.date).startOf("isoWeek")
      ),
      dow: moment(timeEntry.shiftEntry.date).format("dd"),
    };
    data.push(json);
  });
  return data.sort((x, y) =>
    moment(x.date).isBefore(moment(y.date)) ? -1 : 1
  );
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_DOWNTIMES_SUCCESS: {
      const { payload } = data;
      const { timeEntries, pagination } = payload;

      return {
        ...state,
        downtimes: formatData(timeEntries),
        pagination,
      };
    }

    case ACTIONS.GET_MACHINES_SUCCESS: {
      const { payload } = data;
      const { machines } = payload;

      return {
        ...state,
        machines,
      };
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case ACTIONS.PAGE_CHANGE: {
      const { payload } = data;
      const { page } = payload;
      return {
        ...state,
        page,
      };
    }

    case ACTIONS.PAGE_SIZE_CHANGE: {
      const { payload } = data;
      const { sizePerPage } = payload;
      return {
        ...state,
        sizePerPage,
        page: INIT_PAGE,
      };
    }

    case ACTIONS.MACHINE_FILTER_CHANGE: {
      const { payload } = data;
      const { selectedMachine } = payload;
      return {
        ...state,
        selectedMachine,
      };
    }

    case ACTIONS.DATE_FILTER_CHANGE: {
      const { payload } = data;
      const { startDate, endDate } = payload;
      return {
        ...state,
        startDate: startDate
          ? moment(startDate).format("YYYY-MM-DD")
          : state.startDate,
        endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : state.endDate,
      };
    }

    default:
      throw new Error();
  }
};

const DowntimeReportContext = createContext(initialState);

const DowntimeReportProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <DowntimeReportContext.Provider value={stateAndDispatch}>
      {children}
    </DowntimeReportContext.Provider>
  );
};

export const useDowntimeReport = () => useContext(DowntimeReportContext);

export {
  DowntimeReportContext,
  DowntimeReportProvider,
  ACTIONS,
  MAX_PAGE_SIZE,
};
