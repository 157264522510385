import { useCallback } from "react";
import { useApiMutation } from "./Api.hooks";
import { HttpMethod } from "./Api";

const endpoint = `/api/shiftJobSteps`;

export const useUpdateShiftJobStep = (shiftJobStepId) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PUT,
    `${endpoint}/${shiftJobStepId}`
  );

  const update = useCallback(
    async (shiftJobStep) => {
      await mutate(shiftJobStep);
    },
    [mutate]
  );

  return { ...rest, update };
};
