import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import useGetReasons from "../../../api/Reasons.hooks";

import InformationModal from "../../InformationModal";

import Loader from "../../Loader";
import { useCreateExtendedDowntime } from "../../../api/ExtendedDowntime.hooks";
import { useAuth } from "../../../providers/authProvider";
import CustomCheckbox from "../../CustomCheckbox";

const SHIFT_OPTIONS = [
  { name: "First Shift", value: 1 },
  { name: "Second Shift", value: 2 },
  { name: "Third Shift", value: 3 },
];

const ExtendedDowntimeModal = ({ machine, onClose, onSubmit }) => {
  const [extendedDowntime, setExtendedDowntime] = useState({
    machine_id: machine.id,
  });

  const {
    data: reasonsData,
    error: reasonsDataErr,
    isLoading: isLoadingReasonsData,
    get: getReasons,
  } = useGetReasons();

  const [majorReasons, setMajorReasons] = useState();
  const [minorReasons, setMinorReasons] = useState();
  const [categories, setCategories] = useState();
  const [date, setDate] = useState();
  const [requiredNotes, setRequiredNotes] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [authContext] = useAuth();

  const {
    data: createExtendedDowntimeData,
    error: createExtendedDowntimeErr,
    isLoading: isLoadingCreateExtendedDowntime,
    mutate: createExtendedDowntime,
  } = useCreateExtendedDowntime();

  useEffect(() => {
    if (createExtendedDowntimeErr) {
      setInformationModal({
        isOpen: true,
        title: "Create Extended Downtime",
        body:
          createExtendedDowntimeErr?.response?.data[0].msg ||
          "There was an error with your request.",
      });
    }
  }, [createExtendedDowntimeErr]);

  useEffect(() => {
    if (createExtendedDowntimeData) {
      onSubmit();
    }
  }, [createExtendedDowntimeData, onSubmit]);

  useEffect(() => {
    getReasons();
  }, [getReasons]);

  useEffect(() => {
    if (authContext.currentUser.id) {
      setExtendedDowntime((extendedDowntime) => ({
        ...extendedDowntime,
        user_id: authContext.currentUser.id,
      }));
    }
  }, [authContext.currentUser]);

  useEffect(() => {
    if (reasonsData) {
      const reasons = reasonsData
        .filter((r) => r.line_id === machine.line_id && r.minorReasons)
        .sort((x, y) => (x.name > y.name ? 1 : -1));
      setMajorReasons(reasons);
    }
  }, [machine.line_id, reasonsData]);

  useEffect(() => {
    if (extendedDowntime.major_reason_id > 0) {
      const minorReasons = majorReasons
        .find((r) => r.id === extendedDowntime.major_reason_id)
        .minorReasons.sort((x, y) => (x.name > y.name ? 1 : -1));
      setMinorReasons(minorReasons);
    } else {
      setMinorReasons();
    }
  }, [extendedDowntime.major_reason_id, majorReasons]);

  useEffect(() => {
    if (extendedDowntime.minor_reason_id > 0) {
      const categories = minorReasons
        .find((mr) => mr.id === extendedDowntime.minor_reason_id)
        .categoryNotes.filter((c) => c.category_id > 0)
        .sort((x, y) => (x.name > y.name ? 1 : -1));
      setCategories(categories);
    } else {
      setCategories();
    }
  }, [extendedDowntime.minor_reason_id, minorReasons]);

  useEffect(() => {
    if (extendedDowntime.category_id > 0) {
      const category = categories.find(
        (c) => c.category_id === extendedDowntime.category_id
      );
      setRequiredNotes(category?.notes_mandatory);
    } else {
      setRequiredNotes(false);
    }
  }, [categories, extendedDowntime.category_id]);

  useEffect(() => {
    if (reasonsDataErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [reasonsDataErr]);

  const doSubmit = async (e) => {
    e.preventDefault();
    if (!extendedDowntime.shifts?.length) {
      return setInformationModal({
        isOpen: true,
        title: "Add Extended Downtime",
        body: "Please select at least one shift.",
      });
    }
    createExtendedDowntime({
      ...extendedDowntime,
    });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          Add Extended Downtime
        </ModalHeader>
        <ModalBody>
          <Row>
            {isLoadingReasonsData || isLoadingCreateExtendedDowntime ? (
              <Loader size="sm" />
            ) : (
              <Col className="col-12">
                <FormGroup row>
                  <Label for="major-reason" sm={4}>
                    <span>Major reason:</span>
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <Col sm={8}>
                    <Input
                      type="select"
                      name="majorReasonSelect"
                      id="majorReasonSelect"
                      onChange={(e) =>
                        setExtendedDowntime({
                          ...extendedDowntime,
                          major_reason_id: parseInt(e.target.value),
                          minor_reason_id: "",
                          category_id: "",
                        })
                      }
                      value={extendedDowntime.major_reason_id}
                      required
                    >
                      <option value={""}>Choose...</option>
                      {majorReasons &&
                        majorReasons.length &&
                        majorReasons.map((majorReason) => (
                          <option key={majorReason.id} value={majorReason.id}>
                            {majorReason.name}
                          </option>
                        ))}
                    </Input>
                  </Col>
                </FormGroup>
                {minorReasons && minorReasons.length ? (
                  <FormGroup row>
                    <Label for="minor-reason" sm={4}>
                      <span>Minor reason:</span>
                      <span className="text-danger ml-1">*</span>
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="select"
                        name="minorReasonSelect"
                        id="minorReasonSelect"
                        onChange={(e) =>
                          setExtendedDowntime({
                            ...extendedDowntime,
                            minor_reason_id: parseInt(e.target.value),
                            category_id: "",
                          })
                        }
                        value={extendedDowntime.minor_reason_id}
                        required
                      >
                        <option value={""}>Choose...</option>
                        {minorReasons.map((minorReasons) => (
                          <option key={minorReasons.id} value={minorReasons.id}>
                            {minorReasons.name}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </FormGroup>
                ) : (
                  ""
                )}
                {categories && categories.length ? (
                  <FormGroup row>
                    <Label for="categoris" sm={4}>
                      <span>Categories:</span>
                      <span className="text-danger ml-1">*</span>
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="select"
                        name="categoriesSelect"
                        id="categoriesSelect"
                        onChange={(e) =>
                          setExtendedDowntime({
                            ...extendedDowntime,
                            category_id: parseInt(e.target.value),
                          })
                        }
                        value={extendedDowntime.category_id}
                        required
                      >
                        <option value={""}>Choose...</option>
                        {categories.map((category) => (
                          <option
                            key={category.category_id}
                            value={category.category_id}
                          >
                            {category.category_name}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </FormGroup>
                ) : (
                  ""
                )}
                <FormGroup>
                  <Label for="caused-downtime">
                    <span>What Caused This Downtime?</span>
                    <span hidden={!requiredNotes} className="text-danger ml-1">
                      *
                    </span>
                  </Label>
                  <Input
                    type="textarea"
                    name="causedDowntime"
                    id="causedDowntime"
                    placeholder="Enter notes here..."
                    onChange={(e) =>
                      setExtendedDowntime({
                        ...extendedDowntime,
                        notes: e.target.value,
                      })
                    }
                    value={extendedDowntime.notes}
                    required={requiredNotes}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="restart-date">
                    <span>Expected Restart Date</span>
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <Input
                    type="date"
                    name="restartDatePicker"
                    id="restartDatePicker"
                    onChange={(e) => {
                      const date = moment(e.target.value);
                      setDate(e.target.value);
                      setExtendedDowntime({
                        ...extendedDowntime,
                        restart_date: {
                          day: date.date(),
                          month: date.month(),
                          year: date.year(),
                        },
                      });
                    }}
                    value={date || ""}
                    required
                  />
                </FormGroup>
                <ListGroup>
                  <ListGroupItem className="d-flex justify-content-start align-items-center bg-lighter">
                    <span>Shifts</span>
                    <span className="text-danger ml-2">*</span>
                  </ListGroupItem>
                  {SHIFT_OPTIONS.map((shift, i) => (
                    <ListGroupItem
                      key={i}
                      className="d-flex justify-content-between align-items-center cursor-pointer"
                      onClick={() => {
                        const shifts = [...(extendedDowntime.shifts || [])];
                        const index = shifts.findIndex(
                          (s) => s === shift.value
                        );
                        if (index < 0) {
                          shifts.push(shift.value);
                        } else {
                          shifts.splice(index, 1);
                        }
                        setExtendedDowntime({
                          ...extendedDowntime,
                          shifts,
                        });
                      }}
                    >
                      <div className="flex-shrink-0">{shift.name}</div>
                      <CustomCheckbox
                        checked={
                          extendedDowntime.shifts?.indexOf(shift.value) > -1
                        }
                      />
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-center">
              <Button color="danger" type="submit" className="col-12">
                Set Machine Down
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ExtendedDowntimeModal;
