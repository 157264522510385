import { useCallback } from "react";
import { HttpMethod } from "./Api";
import { useApiGet, useApiMutation } from "./Api.hooks";

const endpoint = `/api/shift-schedules`;

export const useGetShifts = () => {
  return useApiGet(endpoint);
};

export const useUpdateShifts = (shifts) => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, `${endpoint}`);

  const update = useCallback(
    async (shifts) => {
      await mutate(shifts);
    },
    [mutate]
  );

  return { ...rest, update };
};
