import React, { useReducer, createContext, useContext } from "react";

const LINES_ID_LATH = 1;

const initialState = {
  machines: [],
  lines: [],
  homeMachines: [],
  selectedLine: LINES_ID_LATH,
};

const ACTIONS = {
  REFRESH: "REFRESH",
  GET_MACHINES_SUCCESS: "GET_MACHINES_SUCCESS",
  GET_LINES_SUCCESS: "GET_LINES_SUCCESS",
  CHANGE_LINE: "CHANGE_LINE",
  PRODUCTION_UPDATE: "PRODUCTION_UPDATE",
};

const updateProductionStatus = (machines, productionStatus = []) => {
  return machines.map((machine) => {
    const status = productionStatus.find((status) => status.id === machine.id);
    if (!status) {
      return {
        ...machine,
      };
    }
    const result = {
      ...machine,
      start_quantity: status.start_quantity,
      quantity_completed: status.quantity_completed,
      production_schedule: status.production_schedule,
      description: status.description,
      on_hold: status.on_hold,
      in_downtime: status.in_downtime,
      downtime_start: status.downtime_start,
    };
    return result;
  });
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_MACHINES_SUCCESS: {
      const { payload } = data;
      const { machines } = payload;

      return {
        ...state,
        machines,
        homeMachines: machines
          .filter((machine) => machine.line_id === state.selectedLine)
          .sort((m1, m2) => m1.order - m2.order),
      };
    }

    case ACTIONS.REFRESH: {
      return {
        ...state,
        refresh: !state.refresh,
      };
    }

    case ACTIONS.GET_LINES_SUCCESS: {
      const { payload } = data;
      const { lines } = payload;

      return {
        ...state,
        lines,
      };
    }

    case ACTIONS.CHANGE_LINE: {
      const { payload } = data;
      const { selectedLine } = payload;

      return {
        ...state,
        selectedLine,
        homeMachines: state.machines
          .filter((machine) => machine.line_id === selectedLine)
          .sort((m1, m2) => m1.order - m2.order),
      };
    }

    case ACTIONS.PRODUCTION_UPDATE: {
      const { payload } = data;
      const { productionStatus } = payload;

      return {
        ...state,
        machines: updateProductionStatus(state.machines, productionStatus),
        homeMachines: updateProductionStatus(
          state.homeMachines,
          productionStatus
        ),
      };
    }

    default:
      throw new Error();
  }
};

const HomeContext = createContext(initialState);

const HomeProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <HomeContext.Provider value={stateAndDispatch}>
      {children}
    </HomeContext.Provider>
  );
};

export const useHome = () => useContext(HomeContext);

export { HomeContext, HomeProvider, ACTIONS };
