import React, { useReducer, createContext, useContext } from "react";
import moment from "moment";

const initialState = {
  date: moment(new Date()).format("YYYY-MM-DD"),
  startDate: moment(new Date()).startOf('isoWeek').format("YYYY-MM-DD"),
  endDate: moment(new Date()).endOf('isoWeek').format("YYYY-MM-DD"),
  monday: moment(new Date()).startOf('isoWeek').format("YYYY-MM-DD"),
  weekCalc: {},
  downtimes: {},
  production: {},
};

const ACTIONS = {
  REFRESH: "REFRESH",
  DATE_CHANGE: "DATE_CHANGE",
  MONDAY_CHANGE: "MONDAY_CHANGE",
  DATE_RANGE_CHANGE: "DATE_RANGE_CHANGE",
  GET_WEEK_CALC_SUCCESS: "GET_WEEK_CALC_SUCCESS",
  GET_DOWNTIME_SUCCESS: "GET_DOWNTIME_SUCCESS",
  GET_PRODUCTION_SUCCESS: "GET_PRODUCTION_SUCCESS",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_WEEK_CALC_SUCCESS: {
      const { payload } = data;
      const { weekCalc } = payload;

      return {
        ...state,
        weekCalc,
      };
    }

    case ACTIONS.GET_DOWNTIME_SUCCESS: {
      const { payload } = data;
      const { downtimes } = payload;

      return {
        ...state,
        downtimes,
      };
    }

    case ACTIONS.GET_PRODUCTION_SUCCESS: {
      const { payload } = data;
      const { production } = payload;

      return {
        ...state,
        production,
      };
    }

    case ACTIONS.DATE_CHANGE: {
      const { payload } = data;
      const { date } = payload;

      return {
        ...state,
        date,
      };
    }

    case ACTIONS.MONDAY_CHANGE: {
      const { payload } = data;
      const { monday } = payload;

      return {
        ...state,
        monday,
      };
    }

    case ACTIONS.DATE_RANGE_CHANGE: {
      const { payload } = data;
      const { startDate, endDate } = payload;

      return {
        ...state,
        startDate,
        endDate,
      };
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    default:
      throw new Error();
  }
};

const OeeReportContext = createContext(initialState);

const OeeReportProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <OeeReportContext.Provider value={stateAndDispatch}>
      {children}
    </OeeReportContext.Provider>
  );
};

export const useOeeReport = () => useContext(OeeReportContext);

export { OeeReportContext, OeeReportProvider, ACTIONS };
