import React, { useEffect, useState } from "react";

import { Card, CardBody, Input, Container } from "reactstrap";

import { useHome, ACTIONS } from "../../providers/homeProvider";

import InformationModal from "../../components/InformationModal";
import useGetMachines from "../../api/Machines.hooks";
import useGetLines from "../../api/Lines.hooks";
import { useSocket } from "../../providers/socketProvider";
import Loader from "../../components/Loader";
import Machine from "../../components/admin/home/Machine";
import { useHistory, useParams } from "react-router-dom";

const ALL_MACHINES_ID = 0;
const LINES_ID_LATH = 1;
const LINES_ID_PRESS = 2;

const LATH_LINE = "lath";
const PRESS_LINE = "press";
const LINES_ID = {
  [LATH_LINE]: LINES_ID_LATH,
  [PRESS_LINE]: LINES_ID_PRESS,
};

const Home = ({ mode }) => {
  const params = useParams();
  const history = useHistory();

  const [homeContext, setHomeContext] = useHome();

  const socket = useSocket();

  const {
    data: machines,
    error: machinesErr,
    isLoading: isLoadingMachines,
    get: getMachines,
  } = useGetMachines();

  const {
    data: lines,
    error: linesErr,
    isLoading: isLoadingLines,
    get: getLines,
  } = useGetLines();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    socket.on("productionUpdate", (productionStatus) => {
      setHomeContext({
        action: ACTIONS.PRODUCTION_UPDATE,
        payload: {
          productionStatus,
        },
      });
    });
    socket.emit("productionUpdate", ALL_MACHINES_ID);
  }, [socket, setHomeContext]);

  useEffect(() => {
    getMachines();
  }, [getMachines, homeContext.refresh]);

  useEffect(() => {
    if (machines) {
      setHomeContext({
        action: ACTIONS.GET_MACHINES_SUCCESS,
        payload: { machines: machines.machines },
      });
    }
  }, [machines, setHomeContext]);

  useEffect(() => {
    if (machinesErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [machinesErr]);

  useEffect(() => {
    getLines();
  }, [getLines]);

  useEffect(() => {
    if (params.line) {
      if (params.line !== LATH_LINE && params.line !== PRESS_LINE) {
        history.push("/auth/sign-in");
      } else {
        setHomeContext({
          action: ACTIONS.CHANGE_LINE,
          payload: { selectedLine: LINES_ID[params.line] },
        });
      }
    } else {
      if (lines) {
        setHomeContext({
          action: ACTIONS.GET_LINES_SUCCESS,
          payload: { lines },
        });
      }
    }
  }, [lines, setHomeContext, params.line, history]);

  useEffect(() => {
    if (linesErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [linesErr]);

  return (
    <Container className="mx-0" fluid>
      {params.line ? (
        <h3 className="text-center">
          {params.line === LATH_LINE ? "Lath" : "Press"}
        </h3>
      ) : (
        <div className="col-12 px-0 pb-3">
          <Input
            type="select"
            className="col-6 col-md-3 col-lg-2"
            name="lineSelect"
            id="lineSelect"
            onChange={(e) =>
              setHomeContext({
                action: ACTIONS.CHANGE_LINE,
                payload: { selectedLine: parseInt(e.target.value) },
              })
            }
            value={homeContext.selectedLine}
          >
            {homeContext.lines?.length &&
              homeContext.lines.map((line) => (
                <option key={line.id} value={line.id}>
                  {line.name}
                </option>
              ))}
          </Input>
        </div>
      )}
      <Card className="col-12 px-0">
        <CardBody className="d-flex flex-row flex-wrap">
          {isLoadingMachines || isLoadingLines ? (
            <Loader />
          ) : homeContext.homeMachines?.length ? (
            homeContext.homeMachines.map((machine, index) => (
              <Machine key={index} machine={machine} mode={mode} />
            ))
          ) : (
            <div className="text-center">No results</div>
          )}
        </CardBody>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default Home;
