import { useApiGet, useApiMutation } from "./Api.hooks";
import { HttpMethod } from "./Api";
import { useCallback } from "react";

const endpoint = `/api/jobs`;

export const useGetJobs = ({ id }) => {
  return useApiGet(id ? `/api/jobs/${id}` : `/api/jobs`);
};

export const useArchiveJob = (data) => {
  return useApiMutation(HttpMethod.PUT, `/api/jobs/${data?.id}`, data);
};

export const useUpdateJob = (jobId) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PUT,
    `${endpoint}/${jobId}`
  );

  const update = useCallback(
    async (job) => {
      await mutate(job);
    },
    [mutate]
  );

  return { ...rest, update };
};
