import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import CustomCheckbox from "../../CustomCheckbox";

import InformationModal from "../../InformationModal";
import Loader from "../../Loader";

import Moment from "moment";
import { extendMoment } from "moment-range";
import { useGetShifts, useUpdateShifts } from "../../../api/Shifts.hooks";
const moment = extendMoment(Moment);

const ShiftSchedulesModal = ({ onClose }) => {
  const [shiftSchedules, setShiftSchedules] = useState([]);
  const [refresh, setRefresh] = useState();

  const {
    data: shifts,
    error: shiftsErr,
    isLoading: isLoadingShifts,
    get: getShifts,
  } = useGetShifts();

  const {
    error: updateShiftsErr,
    isLoading: isLoadingUpdateShifts,
    update: updateShifts,
    data: updateShiftsData,
  } = useUpdateShifts(shiftSchedules);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const onSubmit = async (evt) => {
    evt.preventDefault();
    //check no intersection
    const ranges = [...shiftSchedules].map((shift) =>
      moment.range(
        moment(shift.start_time, "HH:mm"),
        moment(shift.end_time, "HH:mm")
      )
    );
    const noConflict = ranges.reduce((p, c) => {
      const overlaps = ranges.filter((range) =>
        c.overlaps(range, { adjacent: true })
      );
      return p && overlaps.length === 1; //only self
    }, true);
    if (!noConflict) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "Shifts can't overlap.",
      });
    }
    await updateShifts({ shiftSchedules });
  };

  useEffect(() => {
    getShifts({});
  }, [getShifts, refresh]);

  useEffect(() => {
    if (shifts) {
      setShiftSchedules(shifts);
    }
  }, [shifts]);

  useEffect(() => {
    if (updateShiftsData) {
      setInformationModal({
        isOpen: true,
        title: "Shift Schedules",
        body: "Shift schedules updated successfully.",
      });
      setRefresh((prev) => !prev);
    }
  }, [updateShiftsData]);

  useEffect(() => {
    if (shiftsErr || updateShiftsErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [shiftsErr, updateShiftsErr]);

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={onSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          Shift Schedules
        </ModalHeader>
        <ModalBody>
          {isLoadingShifts || isLoadingUpdateShifts ? (
            <Loader size="sm" />
          ) : (
            shiftSchedules.map((shiftSchedule, i) => (
              <ListGroup key={i} className="mb-3">
                <ListGroupItem className="d-flex justify-content-center align-items-center bg-lighter">
                  <span>{shiftSchedule.name} Shift</span>
                </ListGroupItem>
                <ListGroupItem
                  className="d-flex justify-content-between align-items-center cursor-pointer"
                  onClick={() => {
                    const updatedShiftSchedule = {
                      ...shiftSchedule,
                      active: !shiftSchedule.active,
                    };
                    const updatedShiftSchedules = [...shiftSchedules];
                    updatedShiftSchedules.splice(i, 1, updatedShiftSchedule);
                    setShiftSchedules(updatedShiftSchedules);
                  }}
                >
                  <div className="flex-shrink-0">Active</div>
                  <CustomCheckbox checked={shiftSchedule.active} />
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-start align-items-center">
                  <Col md={6}>
                    <FormGroup className="mb-1">
                      <Label for="start-time">
                        <span>Start time:</span>
                        <span className="text-danger ml-1">*</span>
                      </Label>
                      <Input
                        type="time"
                        onChange={(e) => {
                          const updatedShiftSchedule = {
                            ...shiftSchedule,
                            start_time: e.currentTarget.value,
                          };
                          const updatedShiftSchedules = [...shiftSchedules];
                          updatedShiftSchedules.splice(
                            i,
                            1,
                            updatedShiftSchedule
                          );
                          setShiftSchedules(updatedShiftSchedules);
                        }}
                        value={shiftSchedule.start_time || ""}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-1">
                      <Label for="end-time">
                        <span>End time:</span>
                        <span className="text-danger ml-1">*</span>
                      </Label>
                      <Input
                        type="time"
                        onChange={(e) => {
                          const updatedShiftSchedule = {
                            ...shiftSchedule,
                            end_time: e.currentTarget.value,
                          };
                          const updatedShiftSchedules = [...shiftSchedules];
                          updatedShiftSchedules.splice(
                            i,
                            1,
                            updatedShiftSchedule
                          );
                          setShiftSchedules(updatedShiftSchedules);
                        }}
                        value={shiftSchedule.end_time || ""}
                        required
                      />
                    </FormGroup>
                  </Col>
                </ListGroupItem>
              </ListGroup>
            ))
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between mt-n3">
          <Button
            className="d-flex align-items-center ml-2"
            color="secondary"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            className="d-flex align-items-center"
            color="primary"
            type="submit"
          >
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ShiftSchedulesModal;
