import { useCallback } from "react";
import { useApiGet, useApiMutation } from "./Api.hooks";
import { HttpMethod } from "./Api";

const endpoint = `/api/timeEntries`;

export const useGetTimeEntries = () => {
  return useApiGet(endpoint);
};

export const useCreateTimeEntry = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useUpdateTimeEntry = (timeEntryId) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PUT,
    `${endpoint}/${timeEntryId}`
  );

  const update = useCallback(
    async (timeEntry) => {
      await mutate(timeEntry);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteTimeEntry = (timeEntryId) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.DELETE,
    `${endpoint}/${timeEntryId}`
  );

  const deleteTimeEntry = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, deleteTimeEntry };
};
