import React, { useState, useEffect } from "react";

import { Card, CardBody } from "reactstrap";

import { useChartsReport } from "../../../providers/chartsReportProvider";
import { chartsReportUtils } from "../../../utils/chartsReportUtils";
import { Chart as ChartJS, defaults } from "chart.js";

import "chartjs-plugin-datalabels";
import "chartjs-plugin-colorschemes";

defaults.global.defaultFontFamily = "Rubik";

const ProductionChart = () => {
  const [chartsReportContext] = useChartsReport();

  const [, setChart] = useState();

  useEffect(() => {
    const chartOptions = chartsReportUtils.getProductionGraph(
      chartsReportContext.charts
    );
    const container = document.getElementById("production-chart-canvas");
    const ctx = container.getContext("2d");
    const newChart = new ChartJS(ctx, chartOptions);
    setChart((previousValue) => {
      previousValue && previousValue.destroy();
      return newChart;
    });
  }, [
    chartsReportContext.extCharts,
    chartsReportContext.charts,
    chartsReportContext.startDate,
  ]);

  return (
    <Card className="border-radius-0 box-shadow-none border-left border-bottom border-right">
      <CardBody className="d-flex flex-column">
        <div className="mb-6">
          <canvas id="production-chart-canvas"></canvas>
        </div>
      </CardBody>
    </Card>
  );
};

export default ProductionChart;
