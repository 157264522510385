import { faSearch, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import moment from "moment";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  InputGroupText,
  Input,
  InputGroup,
} from "reactstrap";

import InformationModal from "../../components/InformationModal";

import {
  ACTIONS,
  MAX_PAGE_SIZE,
  useMachines,
} from "../../providers/machinesProvider";

import useGetMachines from "../../api/Machines.hooks";
import Machine from "../../components/admin/machines/Machine";
import Loader from "../../components/Loader";
import CustomCheckboxWithLabel from "../../components/CustomCheckboxWithLabel";

const Machines = () => {
  const [machinesContext, setMachinesContext] = useMachines();
  const [showHistorical, setShowHistorical] = useState();

  const {
    data: machines,
    error: machinesErr,
    isLoading: isLoadingMachines,
    get: getMachines,
  } = useGetMachines({});

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    getMachines({
      search: machinesContext.search,
      showHistorical,
      page_size: MAX_PAGE_SIZE,
      selectedDate: moment(machinesContext.selectedDate).isValid()
        ? machinesContext.selectedDate
        : undefined,
    });
  }, [
    getMachines,
    machinesContext.search,
    machinesContext.refresh,
    showHistorical,
    machinesContext.selectedDate,
  ]);

  useEffect(() => {
    if (machines) {
      setMachinesContext({
        action: ACTIONS.GET_MACHINES_SUCCESS,
        payload: machines,
      });
    }
  }, [machines, setMachinesContext]);

  useEffect(() => {
    if (machinesErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [machinesErr]);

  return (
    <Container className="mx-0" fluid>
      <Card className="col-12 px-0">
        <CardHeader className="d-flex align-items-center justify-content-between">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className="mb-0 ">Schedule</h3>
            <small className="text-muted ml-2 pt-1">
              ({machinesContext.pagination?.rowCount || 0})
            </small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <InputGroup size="m" className="mr-3" style={{ width: 200 }}>
              <Input
                className="form-control"
                type="date"
                name="datePicker"
                id="datePicker"
                onChange={(evt) =>
                  setMachinesContext({
                    action: ACTIONS.SELECTED_DATE,
                    payload: {
                      selectedDate: moment(evt.target.value).format(
                        "YYYY-MM-DD"
                      ),
                    },
                  })
                }
                value={machinesContext.selectedDate || ""}
              />
            </InputGroup>
            <InputGroup
              size="m"
              className="mr-3 border rounded px-2 py-1"
              style={{ width: 350 }}
            >
              <CustomCheckboxWithLabel
                label="Show Job History"
                checked={showHistorical}
                onClick={() => setShowHistorical(!showHistorical)}
              />
            </InputGroup>
            <InputGroup size="m" className="mr-3 flex-grow-1">
              <Input
                maxLength="50"
                placeholder="Search by.."
                value={machinesContext.search}
                onChange={(evt) =>
                  setMachinesContext({
                    action: ACTIONS.SEARCH,
                    payload: { search: evt.target.value },
                  })
                }
              />
              <InputGroupText className="search-machines input-group-text bg-primary text-white border-left-0 border-primary">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroupText>
            </InputGroup>
            <Button
              size="sm"
              className="rounded-circle square-32"
              color="primary"
              onClick={() =>
                setMachinesContext({
                  action: ACTIONS.REFRESH,
                })
              }
            >
              <FontAwesomeIcon icon={faSync} />
            </Button>
          </div>
        </CardHeader>
        <CardBody className="d-flex flex-column">
          {isLoadingMachines ? (
            <Loader />
          ) : machinesContext.machines?.length ? (
            <div className="border rounded">
              <div className="d-flex font-weight-bold small text-muted p-3 border-bottom">
                <div className="px-0 col-6 text-left">
                  <span>Machine</span>
                </div>
                <div className="px-0 col-6 text-right">
                  <span>Actions</span>
                </div>
              </div>
              {machines?.machines.map((machine, index) => (
                <Machine
                  machine={machine}
                  key={index}
                  showHistorical={showHistorical}
                />
              ))}
            </div>
          ) : (
            <div className="text-center">No results</div>
          )}
        </CardBody>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default Machines;
