import React, { useCallback, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  InputGroup,
  Input,
} from "reactstrap";
import InformationModal from "../../components/InformationModal";
import Loader from "../../components/Loader";
import {
  ACTIONS,
  MAX_PAGE_SIZE,
  useDowntimeReport,
} from "../../providers/downtimeReportProvider";
import useGetDowntimeReport from "../../api/DowntimeReport.hooks";
import useGetMachines from "../../api/Machines.hooks";
import exportFromJSON from "export-from-json";
import { faDownload, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TABLE_COLUMNS = [
  {
    dataField: "date",
    text: "Date",
    classes: "text-truncate text-center",
    formatter: (cell) => cell || "-",
  },
  {
    dataField: "line",
    text: "Line",
    classes: "text-truncate text-center",
    formatter: (cell) => cell || "-",
  },
  {
    dataField: "shift",
    text: "Shift",
    classes: "text-truncate text-center",
    formatter: (cell) => cell || "-",
  },
  {
    dataField: "majorReason",
    text: "Major",
    classes: "text-truncate text-center",
    formatter: (cell) => cell || "-",
  },
  {
    dataField: "minorReason",
    text: "Minor",
    classes: "text-truncate text-center",
    formatter: (cell) => cell || "-",
  },
  {
    dataField: "downtime",
    text: "Downtime",
    classes: "text-truncate text-center",
    formatter: (cell) => cell || "-",
  },
  {
    dataField: "mm_yr",
    text: "MM-YR",
    classes: "text-truncate text-center",
    formatter: (cell) => cell || "-",
  },
  {
    dataField: "week_of",
    text: "Week of",
    classes: "text-truncate text-center",
    formatter: (cell) => cell || "-",
  },
  {
    dataField: "dow",
    text: "DOW",
    classes: "text-truncate text-center",
    formatter: (cell) => cell || "-",
  },
];

const DowntimeReport = () => {
  const [downtimeReportContext, setDowntimeReportContext] = useDowntimeReport();

  const [startDate, setStartDate] = useState(downtimeReportContext.startDate);
  const [endDate, setEndDate] = useState(downtimeReportContext.endDate);
  const {
    data: downtimeReport,
    error: downtimeReportErr,
    isLoading: isLoadingDowntimeReport,
    get: getDowntimeReport,
  } = useGetDowntimeReport();
  const {
    data: machines,
    error: machinesErr,
    isLoading: isLoadingMachines,
    get: getMachines,
  } = useGetMachines();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const getSizePerPage = (size) => (size === "All" ? MAX_PAGE_SIZE : size);

  const setDowntimeReportContextCb = useCallback(
    (data) => setDowntimeReportContext(data),
    [setDowntimeReportContext]
  );

  const formatDataForExcel = (timeEntries) => {
    let data = [];
    timeEntries.forEach((timeEntry) => {
      const json = {
        Date: timeEntry.date,
        Line: timeEntry.line,
        Shift: timeEntry.shift,
        Description: timeEntry.description,
        Major: timeEntry.majorReason,
        Minor: timeEntry.minorReason,
        Downtime: timeEntry.downtime,
        "MM-YR": timeEntry.mm_yr,
        "Week of": timeEntry.week_of,
        DOW: timeEntry.dow,
      };
      data.push(json);
    });
    return data;
  };

  const downloadExcel = () => {
    const data = formatDataForExcel(downtimeReportContext.downtimes);
    const fileName = `export_downtime_report_${new Date().getTime()}`;
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({ data, fileName, exportType });
  };

  useEffect(() => {
    getDowntimeReport({
      page: downtimeReportContext.page,
      page_size: getSizePerPage(downtimeReportContext.sizePerPage),
      id: downtimeReportContext.selectedMachine,
      start_date: downtimeReportContext.startDate,
      end_date: downtimeReportContext.endDate,
    });
  }, [
    downtimeReportContext.endDate,
    downtimeReportContext.page,
    downtimeReportContext.selectedMachine,
    downtimeReportContext.sizePerPage,
    downtimeReportContext.startDate,
    downtimeReportContext.refresh,
    getDowntimeReport,
  ]);

  useEffect(() => {
    if (downtimeReport) {
      setDowntimeReportContextCb({
        action: ACTIONS.GET_DOWNTIMES_SUCCESS,
        payload: downtimeReport,
      });
    }
  }, [downtimeReport, setDowntimeReportContextCb]);

  useEffect(() => {
    getMachines();
  }, [getMachines]);

  useEffect(() => {
    if (machines) {
      setDowntimeReportContextCb({
        action: ACTIONS.GET_MACHINES_SUCCESS,
        payload: { machines: machines.machines },
      });
    }
  }, [machines, setDowntimeReportContextCb]);

  useEffect(() => {
    if (downtimeReportErr || machinesErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [downtimeReportErr, machinesErr]);

  useEffect(() => {
    if (startDate && endDate) {
      setDowntimeReportContextCb({
        action: ACTIONS.DATE_FILTER_CHANGE,
        payload: { startDate, endDate },
      });
    }
  }, [setDowntimeReportContextCb, startDate, endDate]);

  return (
    <Container className="mx-0" fluid>
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className="mb-0 ">Downtime Report</h3>
            <small className="text-muted ml-2 pt-1">
              ({downtimeReportContext.pagination.rowCount || 0})
            </small>
          </div>
          <div className="d-flex align-items-center justify-content-end flex-shrink-0">
            <InputGroup size="m" className="mr-3">
              <Input
                type="select"
                name="machineSelect"
                id="machineSelect"
                onChange={(evt) =>
                  setDowntimeReportContextCb({
                    action: ACTIONS.MACHINE_FILTER_CHANGE,
                    payload: {
                      selectedMachine: evt.target.value,
                    },
                  })
                }
                value={downtimeReportContext.selectedMachine}
              >
                <option key="" value="">
                  {"All machines"}
                </option>
                {downtimeReportContext.machines?.length &&
                  downtimeReportContext.machines.map((machine) => (
                    <option key={machine.id} value={machine.id}>
                      {machine.name}
                    </option>
                  ))}
              </Input>
            </InputGroup>
            <InputGroup
              size="m"
              className="d-flex align-items-center mr-3 flex-nowrap"
            >
              <Input
                className="min-width-150"
                type="date"
                name="startDatePicker"
                id="startDatePicker"
                value={startDate}
                onChange={(evt) => setStartDate(evt.target.value)}
              />
              <span className="mr-2 ml-2">to</span>
              <Input
                className="min-width-150"
                type="date"
                name="endDatePicker"
                id="endDatePicker"
                value={endDate}
                onChange={(evt) => setEndDate(evt.target.value)}
              />
            </InputGroup>
            <Button
              size="sm"
              className="rounded-circle mr-2 square-32"
              color="primary"
              onClick={downloadExcel}
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
            <Button
              size="sm"
              className="rounded-circle square-32"
              color="primary"
              onClick={() =>
                setDowntimeReportContext({
                  action: ACTIONS.REFRESH,
                })
              }
            >
              <FontAwesomeIcon icon={faSync} />
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          {isLoadingDowntimeReport || isLoadingMachines ? (
            <Loader />
          ) : downtimeReportContext.downtimes?.length ? (
            <BootstrapTable
              bootstrap4
              remote
              striped
              bordered={false}
              keyField="id"
              classes="border"
              headerClasses="small text-muted text-center"
              data={downtimeReportContext.downtimes}
              columns={TABLE_COLUMNS}
              onTableChange={() => {}}
              pagination={paginationFactory({
                page: downtimeReportContext.page,
                totalSize: downtimeReportContext.pagination.rowCount,
                sizePerPage: getSizePerPage(downtimeReportContext.sizePerPage),
                sizePerPageList: [
                  5,
                  10,
                  25,
                  50,
                  {
                    text: "All",
                    value: downtimeReportContext.pagination.rowCount,
                  },
                ],
                onPageChange: (page) =>
                  setDowntimeReportContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  }),
                onSizePerPageChange: (sizePerPage) =>
                  setDowntimeReportContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  }),
              })}
            />
          ) : (
            <div className="text-center">No results</div>
          )}
        </CardBody>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
    </Container>
  );
};

export default DowntimeReport;
