import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const CustomCheckboxWithLabel = ({ label, onClick, checked }) => {
  return (
    <div
      className="d-flex cursor-pointer text-muted w-100 justify-content-between"
      onClick={() => (onClick ? onClick() : null)}
    >
      {label ? <div className="mr-2">{label}</div> : null}
      <div className="bg-primary px-1 rounded">
        {checked ? (
          <FontAwesomeIcon icon={faCheck} className="text-white" size="sm" />
        ) : (
          <FontAwesomeIcon icon={faCheck} className="text-primary" size="sm" />
        )}
      </div>
    </div>
  );
};

export default CustomCheckboxWithLabel;
