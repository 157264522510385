import React, { useReducer, createContext, useContext } from "react";

const initialState = {
  shifts: {},
};

const ACTIONS = {
  REFRESH: "REFRESH",
  GET_SHIFTS_SUCCESS: "GET_SHIFTS_SUCCESS",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_SHIFTS_SUCCESS: {
      const { payload } = data;
      const { shifts } = payload;

      return {
        ...state,
        shifts,
      };
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    default:
      throw new Error();
  }
};

const ExpiredShiftsContext = createContext(initialState);

const ExpiredShiftsProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <ExpiredShiftsContext.Provider value={stateAndDispatch}>
      {children}
    </ExpiredShiftsContext.Provider>
  );
};

export const useExpiredShifts = () => useContext(ExpiredShiftsContext);

export { ExpiredShiftsContext, ExpiredShiftsProvider, ACTIONS };
