import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { useEnviarFile } from "../../../api/Settings.hooks";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";

const UploadJobsCSVModal = ({ onClose }) => {
  const [file, setFile] = useState(null);
  const [isChecked, setIsCheck] = useState(true);

  const {
    data: sendFileData,
    error: sendFileErr,
    isLoading: isLoadingSendFile,
    mutate: sendFile,
  } = useEnviarFile(file);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const handleCheck = () => {
    setIsCheck(!isChecked);
  };

  useEffect(() => {
    if (sendFileErr) {
      setInformationModal({
        isOpen: true,
        title: "Upload Jobs CSV",
        body:
          sendFileErr?.response?.data[0].msg ||
          "There was an error with your request.",
      });
    }
  }, [sendFileErr]);

  useEffect(() => {
    if (sendFileData) {
      setInformationModal({
        isOpen: true,
        title: "Upload Jobs CSV",
        body: "Jobs CSV Uploaded Successfully",
        onClose,
      });
    }
  }, [sendFileData, onClose]);

  const upFiles = (e) => {
    setFile(e);
  };

  const doSetSubmit = async (evt) => {
    evt.preventDefault();
    const formdata = new FormData();
    formdata.append("file", file);
    formdata.append("autoRefreshForJobs", isChecked);
    await sendFile(formdata);
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSetSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          Upload Jobs CSV
        </ModalHeader>
        <ModalBody>
          <Row>
            {isLoadingSendFile ? (
              <Loader size="sm" />
            ) : (
              <Col className="col-12">
                <FormGroup>
                  <Row>
                    <Col className="col-12">
                      <Label for="name">
                        <span>Upload Jobs CSV File</span>
                      </Label>
                      <Input
                        type="file"
                        name="files"
                        accept=".csv,"
                        multiple
                        required
                        onChange={(e) => {
                          upFiles(e.target.files[0]);
                        }}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="pl-4 mb-0">
                  <Input
                    type="checkbox"
                    name="enable"
                    checked={isChecked}
                    onChange={handleCheck}
                  />
                  <Label for="shift" className="mb-0">
                    <span>Enable auto refresh for Jobs</span>
                  </Label>
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Close
              </Button>
              <Button color={"primary"} type="submit">
                Send
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default UploadJobsCSVModal;
