import { useCallback } from "react";
import { useApiGet, useApiMutation } from "./Api.hooks";
import { HttpMethod } from "./Api";

const endpoint = `/api/users`;

export const useGetUsers = () => {
  return useApiGet(endpoint);
};

export const useCreateUser = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useUpdateUser = (userId) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PUT,
    `${endpoint}/${userId}`
  );

  const update = useCallback(
    async (user) => {
      await mutate(user);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteUser = (userId) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.DELETE,
    `${endpoint}/${userId}`
  );

  const deleteUser = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, deleteUser };
};

export const useArchiveUser = (userId) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PATCH,
    `${endpoint}/${userId}/archive`
  );

  const archiveUser = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, archiveUser };
};
