import { useApiGet } from "./Api.hooks";

export const useGetOeeWeekCalcReport = () => {
  return useApiGet("/api/machines/oee-report-week-calc");
};

export const useGetOeeDowntimeReport = () => {
  return useApiGet("/api/machines/oee-report-downtimes");
};

export const useGetOeeProductionReport = () => {
  return useApiGet("/api/machines/oee-report-production");
};
