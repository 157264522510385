import React, { useEffect, useState } from "react";

import Select from "react-select";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import InformationModal from "../../InformationModal";
import makeAnimated from "react-select/animated";
import { useGetJobs } from "../../../api/Jobs.hooks";
import { useCreateJobStep } from "../../../api/JobSteps.hooks";
import Loader from "../../Loader";

const MAX_PAGE_SIZE = 30;

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};

const animatedComponents = makeAnimated();

const ScheduleJobModal = ({ machine, onClose, onSubmit }) => {
  const [selectedJob, setSelectedJob] = useState();
  const [search, setSearch] = useState();
  const [jobs, setJobs] = useState([]);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const {
    data: jobsData,
    error: jobsErr,
    isLoading: isLoadingJobs,
    get: getJobs,
  } = useGetJobs({});

  const {
    error: createJobStepErr,
    isLoading: isLoadingCreateJobStep,
    mutate: createJobStep,
    data: createJobStepData,
  } = useCreateJobStep();

  useEffect(() => {
    getJobs({
      search,
      page_size: MAX_PAGE_SIZE,
    });
  }, [getJobs, search]);

  useEffect(() => {
    if (jobsData) {
      setJobs(jobsData.jobs);
    }
  }, [jobsData]);

  useEffect(() => {
    if (jobsErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [jobsErr]);

  useEffect(() => {
    if (createJobStepErr) {
      setInformationModal({
        isOpen: true,
        title: "Create Job Step",
        body:
          createJobStepErr?.response?.data[0].msg ||
          "There was an error with your request.",
      });
    }
  }, [createJobStepErr]);

  useEffect(() => {
    if (createJobStepData) {
      onSubmit();
    }
  }, [createJobStepData, onSubmit]);

  const onChange = (selected) => {
    const id = selected?.value;
    const selectedJob = jobs.find((j) => j.id === id);
    if (selectedJob) {
      setSelectedJob({
        value: selectedJob.id,
        label: selectedJob.id,
      });
    } else {
      setSelectedJob();
    }
  };

  const doSubmit = (evt) => {
    evt.preventDefault();
    if (!selectedJob) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "Please select a job",
      });
    }
    const existingJobStep = machine.jobSteps.find(
      (js) => js.job_id === selectedJob.value && !js.completed
    );
    if (existingJobStep) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "Job has another step already assigned to the machine.",
      });
    }
    createJobStep({
      job_id: selectedJob.value,
      machine_id: machine.id,
    });
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose} size="sm">
      {isLoadingCreateJobStep ? (
        <div className="my-3">
          <Loader size="sm" />
        </div>
      ) : (
        <Form onSubmit={doSubmit}>
          <ModalHeader close={closeBtn}>
            Assign Job to {machine.name}
          </ModalHeader>
          <ModalBody>
            <FormGroup className="mb-0">
              <Select
                placeholder={
                  <span className="text-muted">Search for a job..</span>
                }
                noOptionsMessage={() => "No jobs found"}
                styles={style}
                className="flex-grow-1 border rounded my-2"
                options={jobs
                  .filter((job) => selectedJob?.value !== job.id)
                  .map((job) => ({
                    value: job.id,
                    label: job.id,
                  }))}
                closeMenuOnSelect={true}
                components={animatedComponents}
                isSearchable
                isClearable
                inputValue={search}
                onInputChange={setSearch}
                onChange={onChange}
                isLoading={isLoadingJobs}
                value={selectedJob}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter className="justify-content-between align-items-center">
            <Button color={"primary"} onClick={onClose}>
              Discard
            </Button>
            <Button type="submit" color={"success"}>
              Save
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Modal>
  );
};

export default ScheduleJobModal;
