import React from "react";

import { Badge, Collapse } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon,
    isOpen,
    children,
    onClick,
    location,
    to,
  }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };
    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <div
          data-toggle="collapse"
          className={
            "sidebar-link d-flex align-items-center " +
            (!isOpen ? "collapsed" : "")
          }
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          {icon.type === "img" ? (
            icon
          ) : (
            <FontAwesomeIcon
              icon={icon}
              fixedWidth
              className="align-middle mx-2 sidebar-icon"
            />
          )}{" "}
          <span className="align-middle text-truncate pr-4 ml-1">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </div>
        <Collapse isOpen={Boolean(isOpen)}>
          <ul className={"sidebar-dropdown list-unstyled"}>{children}</ul>
        </Collapse>
      </li>
    );
  }
);

export default SidebarCategory;
