import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import { useUpdateTimeEntry } from "../../../api/TimeEntries.hooks";
import DowntimeForm from "./DowntimeForm";

const ResolveRunningTimersModal = ({ timeEntry, onClose, onSubmit }) => {
  const [downtime, setDowntime] = useState({
    ...timeEntry,
  });

  const [timeValidation, setTimeValidation] = useState({
    startTime: true,
    endTime: true,
  });

  const {
    error: updateTimeEntryErr,
    data: updateTimeEntryData,
    isLoading: isLoadingUpdateTimeEntry,
    update: updateTimeEntry,
  } = useUpdateTimeEntry(downtime.id);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const doSubmit = async (e) => {
    e.preventDefault();
    if (!timeValidation.startTime) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "Invalid start time. Check the data consistency before saving.",
      });
    } else if (!timeValidation.endTime) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "Invalid end time. Check the data consistency before saving.",
      });
    }
    updateTimeEntry(downtime);
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  useEffect(() => {
    if (updateTimeEntryErr) {
      setInformationModal({
        isOpen: true,
        title: "Fix Open Timer",
        body:
          updateTimeEntryErr?.response?.data[0].msg ||
          "There was an error with your request.",
      });
    }
  }, [updateTimeEntryErr]);

  useEffect(() => {
    if (updateTimeEntryData) {
      onSubmit();
    }
  }, [updateTimeEntryData, onSubmit]);

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true}>
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex bg-danger justify-content-between"
          close={closeBtn}
        >
          <span className="text-white">Fix Open Timer</span>
        </ModalHeader>
        <ModalBody>
          {isLoadingUpdateTimeEntry ? (
            <Loader size="sm" />
          ) : (
            <DowntimeForm
              shift={timeEntry.shiftEntry}
              downtime={downtime}
              setDowntime={setDowntime}
              timeValidation={timeValidation}
              setTimeValidation={setTimeValidation}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-center">
              <Button color={"danger"} type="submit">
                Fix Timer
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ResolveRunningTimersModal;
