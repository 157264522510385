import React from "react";
import BootstrapTable from "react-bootstrap-table-next";

const OeeWeekCalcReport = ({data, machines}) => {
  const TABLE_COLUMNS = [
    {
      dataField: "date",
      text: "Date",
      classes: "text-truncate text-center",
      headerClasses: "border-top-0",
      formatter: (cell) => cell || "-",
    },
    ...machines.map(m => ({
      dataField: m,
      text: m,
      classes: "text-truncate text-center",
      headerClasses: "border-top-0",
      formatter: (cell) => cell || "-",
    })),
  ];
  
  return (
    <BootstrapTable
      bootstrap4
      remote
      striped
      bordered={false}
      keyField="date"
      classes="border-radius-0 box-shadow-none border-left border-bottom border-right"
      headerClasses="small text-muted text-center"
      data={data}
      columns={TABLE_COLUMNS}
      onTableChange={() => { }}
    />
  );
};

export default OeeWeekCalcReport;
