import React from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import AdminLayout from "../layouts/Admin";
import AuthLayout from "../layouts/Auth";
import DefaultLayout from "../layouts/Default";

import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

import ScrollToTop from "../components/ScrollToTop";

import { authRoutes, adminRoutes } from ".";
import { useAuth } from "../providers/authProvider";
import Page401 from "../pages/auth/Page401";
import Home from "../pages/admin/Home";

const MODE_KIOSK = "MODE_KIOSK";

const Routes = () => {
  const [authContext] = useAuth();

  return (
    <Router>
      <ScrollToTop>
        <Switch>
          {authRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact
              render={(props) => (
                <AuthLayout>
                  <route.component {...props} />
                </AuthLayout>
              )}
            />
          ))}
          {adminRoutes.flatMap((route, index) =>
            route.entries
              ? route.entries.map((entry, index) => (
                  <Route
                    key={index}
                    path={entry.path}
                    exact
                    render={(props) => {
                      if (
                        authContext.currentUser &&
                        entry.roles?.length &&
                        entry.roles.indexOf(authContext.currentUser.roleId) < 0
                      ) {
                        return <Redirect to="/error/401" />;
                      }
                      return (
                        <AdminLayout>
                          <entry.component {...props} />
                        </AdminLayout>
                      );
                    }}
                  />
                ))
              : [
                  <Route
                    key={index}
                    path={route.path}
                    exact
                    render={(props) => {
                      if (
                        authContext.currentUser &&
                        route.roles &&
                        route.roles.indexOf(authContext.currentUser.roleId) < 0
                      ) {
                        return <Redirect to="/error/401" />;
                      }
                      return (
                        <AdminLayout>
                          <route.component {...props} />
                        </AdminLayout>
                      );
                    }}
                  />,
                ]
          )}
          <Route path={"/"} exact render={() => <Redirect to="/home" />} />
          <Route
            path={"/kiosk/:line"}
            exact
            render={() => (
              <DefaultLayout>
                <Home mode={MODE_KIOSK} />
              </DefaultLayout>
            )}
          />
          <Route
            path={"/error/500"}
            exact
            render={() => (
              <DefaultLayout>
                <Page500 />
              </DefaultLayout>
            )}
          />
          <Route
            path={"/error/404"}
            exact
            render={() => (
              <DefaultLayout>
                <Page404 />
              </DefaultLayout>
            )}
          />
          <Route
            path={"/error/401"}
            exact
            render={() => (
              <DefaultLayout>
                <Page401 />
              </DefaultLayout>
            )}
          />
          <Route
            render={() => (
              <DefaultLayout>
                <Page404 />
              </DefaultLayout>
            )}
          />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default Routes;
