import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  InputGroup,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import InformationModal from "../../../components/InformationModal";
import Loader from "../../../components/Loader";
import {
  ACTIONS,
  useOeeReport,
} from "../../../providers/oeeReportProvider";
import { useGetOeeDowntimeReport, useGetOeeProductionReport, useGetOeeWeekCalcReport } from "../../../api/OeeReport.hooks";
import exportFromJSON from "export-from-json";
import { faChevronLeft, faChevronRight, faDownload, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import OeeDowntimeReport from "./OeeDowntimeReport";
import OeeProductionReport from "./OeeProductionReport";
import OeeWeekCalcReport from "./OeeWeekCalcReport";
import TooltipItem from "../../../components/TooltipItem";
import moment from "moment";

const PRODUCTION_TAB = "PRODUCTION_TAB";
const DOWNTIME_TAB = "DOWNTIME_TAB";
const OEE_CALC_TAB = "OEE_CALC_TAB";

const EooReport = () => {
  const [oeeReportContext, setOeeReportContext] = useOeeReport();

  const [activeTab, setActiveTab] = useState(OEE_CALC_TAB);
  const [date, setDate] = useState(oeeReportContext.date);
  const [startDate, setStartDate] = useState(oeeReportContext.startDate);
  const [monday, setMonday] = useState(oeeReportContext.monday);
  const [endDate, setEndDate] = useState(oeeReportContext.endDate);

  const {
    data: oeeWeekCalcReport,
    error: oeeWeekCalcReportErr,
    isLoading: isLoadingWeekCalcReport,
    get: getOeeWeekCalcReport,
  } = useGetOeeWeekCalcReport();

  const {
    data: oeeDowntimeReport,
    error: oeeDowntimeReportErr,
    isLoading: isLoadingDowntimeReport,
    get: getEooDowntimeReport,
  } = useGetOeeDowntimeReport();

  const {
    data: oeeProductionReport,
    error: oeeProductionReportErr,
    isLoading: isLoadingProductionReport,
    get: getEooProductionReport,
  } = useGetOeeProductionReport();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const setEooReportContextCb = useCallback(
    (data) => setOeeReportContext(data),
    [setOeeReportContext]
  );

  const formatWeekCalcDataForExcel = (entries) => {
    let data = [];
    entries.forEach((entry) => {
      const json = {
        Date: entry.date,
        ...entry,
      };
      delete json.date;
      data.push(json);
    });
    return data;
  };

  const formatProductionDataForExcel = (entries) => {
    let data = [];
    entries.forEach((entry) => {
      const json = {
        Date: entry.date,
        ...entry,
      };
      delete json.date;
      data.push(json);
    });
    return data;
  };

  const formatDowntimeDataForExcel = (entries) => {
    let data = [];
    entries.forEach((entry) => {
      const json = {
        Reason: entry.reason,
        ...entry,
      };
      delete json.reason;
      data.push(json);
    });
    return data;
  };

  const downloadExcel = () => {
    let data;
    let fileName;
    if (activeTab === OEE_CALC_TAB) {
      data = formatWeekCalcDataForExcel(oeeWeekCalcReport.data);
      fileName = `export_oee_week_calc_report_${new Date().getTime()}`;
    } else if (activeTab === PRODUCTION_TAB) {
      data = formatProductionDataForExcel(oeeProductionReport.data);
      fileName = `export_oee_production_report_${new Date().getTime()}`;
    } else {
      data = formatDowntimeDataForExcel(oeeDowntimeReport.data);
      fileName = `export_oee_downtime_report_${new Date().getTime()}`;
    }
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({ data, fileName, exportType });
  };

  useEffect(() => {
    getOeeWeekCalcReport({
      start_date: oeeReportContext.monday,
      end_date: moment(oeeReportContext.monday).endOf('isoWeek').format("YYYY-MM-DD"),
    });
  }, [
    oeeReportContext.monday,
    oeeReportContext.refresh,
    getOeeWeekCalcReport,
  ]);

  useEffect(() => {
    if (oeeWeekCalcReport) {
      setEooReportContextCb({
        action: ACTIONS.GET_WEEK_CALC_SUCCESS,
        payload: oeeWeekCalcReport,
      });
    }
  }, [oeeWeekCalcReport, setEooReportContextCb]);

  useEffect(() => {
    getEooDowntimeReport({
      date: oeeReportContext.date,
    });
  }, [oeeReportContext.date, oeeReportContext.refresh, getEooDowntimeReport]);

  useEffect(() => {
    if (oeeDowntimeReport) {
      setEooReportContextCb({
        action: ACTIONS.GET_DOWNTIME_SUCCESS,
        payload: oeeDowntimeReport,
      });
    }
  }, [oeeDowntimeReport, setEooReportContextCb]);

  useEffect(() => {
    getEooProductionReport({
      start_date: oeeReportContext.startDate,
      end_date: oeeReportContext.endDate,
    });
  }, [
    oeeReportContext.startDate,
    oeeReportContext.endDate,
    oeeReportContext.refresh,
    getEooProductionReport,
  ]);

  useEffect(() => {
    if (oeeProductionReport) {
      setEooReportContextCb({
        action: ACTIONS.GET_PRODUCTION_SUCCESS,
        payload: oeeProductionReport,
      });
    }
  }, [oeeProductionReport, setEooReportContextCb]);

  useEffect(() => {
    if (oeeDowntimeReportErr || oeeProductionReportErr || oeeWeekCalcReportErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [oeeDowntimeReportErr, oeeProductionReportErr, oeeWeekCalcReportErr]);

  useEffect(() => {
    if (date) {
      setEooReportContextCb({
        action: ACTIONS.DATE_CHANGE,
        payload: { date },
      });
    }
  }, [setEooReportContextCb, date]);

  useEffect(() => {
    if (startDate && endDate) {
      setEooReportContextCb({
        action: ACTIONS.DATE_RANGE_CHANGE,
        payload: { startDate, endDate },
      });
    }
  }, [setEooReportContextCb, startDate, endDate]);

  useEffect(() => {
    if (monday) {
      setEooReportContextCb({
        action: ACTIONS.MONDAY_CHANGE,
        payload: { monday },
      });
    }
  }, [setEooReportContextCb, monday]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <Container className="mx-0" fluid>
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className="mb-0 ">OEE Report</h3>
          </div>
          <div className="d-flex align-items-center justify-content-end flex-shrink-0">
            {activeTab === OEE_CALC_TAB ? (
              <div className="d-flex justify-content-between align-items-center flex-shrink-0 mr-3 col-10">
                <TooltipItem id="tooltip-previous-date" title="Previous Week">
                  <Button
                    color="primary"
                    onClick={() => setMonday(moment(monday).subtract(1, 'week').format("YYYY-MM-DD"))}
                    className="rounded-circle square-32 d-flex align-items-center justify-content-center"
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </Button>
                </TooltipItem>
                <div className="form-control mx-1 d-flex align-items-center justify-content-center">
                  <span>
                    {monday}
                  </span>
                </div>
                to
                <div className="form-control mx-1 d-flex align-items-center justify-content-center">
                  <span>
                    {moment(monday).endOf('isoWeek').format("YYYY-MM-DD")}
                  </span>
                </div>
                <TooltipItem id="tooltip-next-date" title="Next Week">
                  <Button
                    color="primary"
                    onClick={() => setMonday(moment(monday).add(1, 'week').format("YYYY-MM-DD"))}
                    className="rounded-circle square-32 d-flex align-items-center justify-content-center"
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                </TooltipItem>
              </div>
            ) : null}
            {activeTab === PRODUCTION_TAB ? (
              <InputGroup
                size="m"
                className="d-flex align-items-center mr-3 flex-nowrap"
              >
                <Input
                  className="min-width-150"
                  type="date"
                  name="startDatePicker"
                  id="startDatePicker"
                  value={startDate}
                  onChange={(evt) => setStartDate(evt.target.value)}
                />
                <span className="mr-2 ml-2">to</span>
                <Input
                  className="min-width-150"
                  type="date"
                  name="endDatePicker"
                  id="endDatePicker"
                  value={endDate}
                  onChange={(evt) => setEndDate(evt.target.value)}
                />
              </InputGroup>
              ) : null}
              {activeTab === DOWNTIME_TAB ? (
              <InputGroup
                size="m"
                className="d-flex align-items-center mr-3 flex-nowrap"
              >
                <Input
                  className="min-width-150"
                  type="date"
                  name="startDatePicker"
                  id="startDatePicker"
                  value={date}
                  onChange={(evt) => setDate(evt.target.value)}
                />
              </InputGroup>
            ) : null}
            <Button
              size="sm"
              className="rounded-circle mr-2 square-32"
              color="primary"
              onClick={downloadExcel}
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
            <Button
              size="sm"
              className="rounded-circle square-32"
              color="primary"
              onClick={() =>
                setOeeReportContext({
                  action: ACTIONS.REFRESH,
                })
              }
            >
              <FontAwesomeIcon icon={faSync} />
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <Nav
            tabs
            className="d-flex align-items-center work-order-details-nav cursor-pointer mt-3"
          >
            <div className="d-flex flex-grow-1">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === OEE_CALC_TAB })}
                  onClick={() => toggleTab(OEE_CALC_TAB)}
                >
                  OEE
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === PRODUCTION_TAB })}
                  onClick={() => toggleTab(PRODUCTION_TAB)}
                >
                  Production OEE
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === DOWNTIME_TAB })}
                  onClick={() => toggleTab(DOWNTIME_TAB)}
                >
                  Downtime OEE
                </NavLink>
              </NavItem>
            </div>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={OEE_CALC_TAB}>
              {activeTab === OEE_CALC_TAB ? (
                  isLoadingWeekCalcReport ? (
                    <Loader />
                  ) : oeeWeekCalcReport?.data?.length ? (
                    <OeeWeekCalcReport data={oeeWeekCalcReport.data} machines={oeeWeekCalcReport.machines}/>
                  ) : (
                    <div className="text-center">No results</div>
                  )
                ) : null}
            </TabPane>
            <TabPane tabId={PRODUCTION_TAB}>
              {activeTab === PRODUCTION_TAB ? (
                  isLoadingProductionReport ? (
                    <Loader />
                  ) : oeeProductionReport?.data?.length ? (
                    <OeeProductionReport data={oeeProductionReport.data} machines={oeeProductionReport.machines}/>
                  ) : (
                    <div className="text-center">No results</div>
                  )
                ) : null}
            </TabPane>
            <TabPane tabId={DOWNTIME_TAB}>
              {activeTab === DOWNTIME_TAB ? (
                isLoadingDowntimeReport ? (
                  <Loader />
                ) : oeeDowntimeReport?.data?.length ? (
                  <OeeDowntimeReport data={oeeDowntimeReport.data} machines={oeeDowntimeReport.machines}/>
                ) : (
                  <div className="text-center">No results</div>
                )
              ) : null}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
    </Container>
  );
};

export default EooReport;
