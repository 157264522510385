import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const CustomCheckbox = ({ onClick, checked }) => {
  return (
    <div
      className="bg-primary border border-primary rounded cursor-pointer px-1"
      onClick={() => (onClick ? onClick() : null)}
    >
      {checked ? (
        <FontAwesomeIcon icon={faCheck} className="text-white" size="sm" />
      ) : (
        <FontAwesomeIcon icon={faCheck} className="text-primary" size="sm" />
      )}
    </div>
  );
};

export default CustomCheckbox;
