import React, { useState, useEffect } from "react";
import moment from "moment";
import { Col, FormGroup, Input, Label, FormFeedback, Row } from "reactstrap";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import useGetReasons from "../../../api/Reasons.hooks";
import { SHIFT_STATUS } from "../../../providers/shiftsTableProvider";

const DATE_TIME_FORMAT = "YYYY/MM/DD HH:mm";

const DowntimeForm = ({
  shift,
  downtime,
  setDowntime,
  timeValidation,
  setTimeValidation,
}) => {
  const {
    data: reasonsData,
    error: reasonsDataErr,
    isLoading: isLoadingReasonsData,
    get: getReasons,
  } = useGetReasons();

  const [majorReasons, setMajorReasons] = useState([]);
  const [minorReasons, setMinorReasons] = useState([]);
  const [categories, setCategories] = useState([]);
  const [requiredNotes, setRequiredNotes] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    getReasons();
  }, [getReasons]);

  useEffect(() => {
    if (reasonsData) {
      const reasons = reasonsData
        .filter((r) => r.line_id === shift.machine.line_id && r.minorReasons)
        .sort((x, y) => (x.name > y.name ? 1 : -1));
      setMajorReasons(reasons);
    }
  }, [shift.machine.line_id, reasonsData]);

  useEffect(() => {
    if (downtime.major_reason_id > 0) {
      const majorReason = majorReasons.find(
        (r) => r.id === downtime.major_reason_id
      );
      const minorReasons = (majorReason?.minorReasons || []).sort((x, y) =>
        x.name > y.name ? 1 : -1
      );
      setMinorReasons(minorReasons);
    } else {
      setMinorReasons([]);
    }
  }, [downtime.major_reason_id, majorReasons]);

  useEffect(() => {
    if (downtime.minor_reason_id > 0) {
      const minorReason = minorReasons.find(
        (mr) => mr.id === downtime.minor_reason_id
      );
      const categories = (minorReason?.categoryNotes || [])
        .filter((c) => c.category_id > 0)
        .sort((x, y) => (x.name > y.name ? 1 : -1));
      setCategories(categories);
    } else {
      setCategories([]);
    }
  }, [downtime.minor_reason_id, minorReasons]);

  useEffect(() => {
    if (downtime.category_id > 0) {
      const category = categories.find(
        (c) => c.category_id === downtime.category_id
      );
      setRequiredNotes(category?.notes_mandatory);
    } else {
      setRequiredNotes(false);
    }
  }, [categories, downtime.category_id]);

  useEffect(() => {
    if (reasonsDataErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [reasonsDataErr]);

  const invalidStartTimeFormat = () => {
    const shiftStartTime = moment(shift.started_at);
    const shiftEndTime = moment(shift.ended_on);

    const endTime =
      parseInt(shift.status) !== SHIFT_STATUS.SHIFT_STATUS_IN_PROGRESS
        ? shiftEndTime.format(DATE_TIME_FORMAT)
        : false;

    return `Start time must be ${
      endTime
        ? `between ${shiftStartTime.format(DATE_TIME_FORMAT)} and ${endTime}`
        : `after ${shiftStartTime.format(DATE_TIME_FORMAT)}`
    }`;
  };

  const invalidEndTimeFormat = () => {
    const downtimeStartTime = moment(downtime.start_time);
    const shiftStartTime = moment(shift.started_at);

    const startTime = moment
      .max([shiftStartTime, downtimeStartTime])
      .format(DATE_TIME_FORMAT);

    const shiftEndTime = moment(shift.ended_on);

    const endTime =
      parseInt(shift.status) !== SHIFT_STATUS.SHIFT_STATUS_IN_PROGRESS
        ? shiftEndTime.format(DATE_TIME_FORMAT)
        : false;

    return `End time must be ${
      endTime ? `between ${startTime} and ${endTime}` : `after ${startTime}`
    }`;
  };

  useEffect(() => {
    if (downtime.start_time) {
      const downtimeStartTime = moment(downtime.start_time);
      const shiftStartTime = moment(shift.started_at);
      const shiftEndTime = moment(shift.ended_on);

      const correctStartTime =
        shiftStartTime.utc().isSameOrBefore(downtimeStartTime, "minute") &&
        (parseInt(shift.status) === SHIFT_STATUS.SHIFT_STATUS_IN_PROGRESS
          ? true
          : shiftEndTime.utc().isSameOrAfter(downtimeStartTime, "minute"));

      setTimeValidation((prevValues) => ({
        ...prevValues,
        startTime: correctStartTime,
      }));
    }
  }, [setTimeValidation, downtime.end_time, downtime.start_time, shift]);

  useEffect(() => {
    if (downtime.start_time && downtime.end_time) {
      const downtimeStartTime = moment(downtime.start_time);
      const downtimeEndTime = moment(downtime.end_time);
      const shiftStartTime = moment(shift.started_at);
      const shiftEndTime = moment(shift.ended_on);

      const correctShiftEndTime =
        parseInt(shift.status) === SHIFT_STATUS.SHIFT_STATUS_IN_PROGRESS
          ? true
          : shiftStartTime.utc().isSameOrBefore(downtimeEndTime, "minute") &&
            shiftEndTime.utc().isSameOrAfter(downtimeEndTime, "minute");

      const correctEndTime =
        correctShiftEndTime &&
        downtimeStartTime.utc().isSameOrBefore(downtimeEndTime, "minute");

      setTimeValidation((prevValues) => ({
        ...prevValues,
        endTime: correctEndTime,
      }));
    }
  }, [setTimeValidation, downtime.end_time, downtime.start_time, shift]);

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Row>
      {isLoadingReasonsData ? (
        <Loader size="sm" />
      ) : (
        <Col className="col-12">
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="start-time">
                  <span>Start time:</span>
                  <span className="text-danger ml-1">*</span>
                </Label>
                <Input
                  type="datetime-local"
                  name="start-time"
                  id="start-time"
                  onChange={(e) => {
                    setDowntime({
                      ...downtime,
                      end_time: "",
                      start_time: moment(e.target.value).toISOString(),
                    });
                  }}
                  value={
                    downtime.start_time
                      ? moment(downtime.start_time).format("YYYY-MM-DDTHH:mm")
                      : ""
                  }
                  required
                  invalid={!timeValidation.startTime}
                />
                <FormFeedback invalid>{invalidStartTimeFormat()}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="end-time">
                  <span>End time:</span>
                  <span className="text-danger ml-1">*</span>
                </Label>
                <Input
                  type="datetime-local"
                  name="end-time"
                  id="end-time"
                  onChange={(e) => {
                    setDowntime({
                      ...downtime,
                      end_time: moment(e.target.value).toISOString(),
                    });
                  }}
                  value={
                    downtime.end_time
                      ? moment(downtime.end_time).format("YYYY-MM-DDTHH:mm")
                      : moment(downtime.start_time)
                          .add(1, "minute")
                          .format("YYYY-MM-DDTHH:mm")
                  }
                  required
                  invalid={!timeValidation.endTime}
                />
                <FormFeedback>{invalidEndTimeFormat()}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup row>
            <Label for="major-reason" sm={4}>
              <span>Major reason:</span>
              <span className="text-danger ml-1">*</span>
            </Label>
            <Col sm={8}>
              <Input
                type="select"
                name="majorReasonSelect"
                id="majorReasonSelect"
                onChange={(e) =>
                  setDowntime({
                    ...downtime,
                    major_reason_id: parseInt(e.target.value),
                    minor_reason_id: "",
                    category_id: "",
                  })
                }
                value={downtime.major_reason_id}
                required
              >
                <option value={""}>Choose...</option>
                {majorReasons &&
                  majorReasons.length &&
                  majorReasons.map((majorReason) => (
                    <option key={majorReason.id} value={majorReason.id}>
                      {majorReason.name}
                    </option>
                  ))}
              </Input>
            </Col>
          </FormGroup>
          {minorReasons && minorReasons.length ? (
            <FormGroup row>
              <Label for="minor-reason" sm={4}>
                <span>Minor reason:</span>
                <span className="text-danger ml-1">*</span>
              </Label>
              <Col sm={8}>
                <Input
                  type="select"
                  name="minorReasonSelect"
                  id="minorReasonSelect"
                  onChange={(e) =>
                    setDowntime({
                      ...downtime,
                      minor_reason_id: parseInt(e.target.value),
                      category_id: "",
                    })
                  }
                  value={downtime.minor_reason_id}
                  required
                >
                  <option value={""}>Choose...</option>
                  {minorReasons.map((minorReasons) => (
                    <option key={minorReasons.id} value={minorReasons.id}>
                      {minorReasons.name}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
          ) : (
            ""
          )}
          {categories && categories.length ? (
            <FormGroup row>
              <Label for="categoris" sm={4}>
                <span>Categories:</span>
                <span className="text-danger ml-1">*</span>
              </Label>
              <Col sm={8}>
                <Input
                  type="select"
                  name="categoriesSelect"
                  id="categoriesSelect"
                  onChange={(e) =>
                    setDowntime({
                      ...downtime,
                      category_id: parseInt(e.target.value),
                    })
                  }
                  value={downtime.category_id}
                  required
                >
                  <option value={""}>Choose...</option>
                  {categories.map((category) => (
                    <option
                      key={category.category_id}
                      value={category.category_id}
                    >
                      {category.category_name}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
          ) : (
            ""
          )}
          <FormGroup>
            <Label for="notes">
              <span>Notes</span>
              <span hidden={!requiredNotes} className="text-danger ml-1">
                *
              </span>
            </Label>
            <Input
              type="textarea"
              name="notes"
              id="notes"
              placeholder="Enter notes here..."
              onChange={(e) =>
                setDowntime({
                  ...downtime,
                  notes: e.target.value,
                })
              }
              value={downtime.notes}
              required={requiredNotes}
            />
          </FormGroup>
          <Row>
            <Col md={6}>
              <FormGroup className="mb-0">
                <Label for="shift-start-time">
                  <span>Shift Start time:</span>
                </Label>
                <Input
                  type="datetime-local"
                  name="shift-start-time"
                  id="shift-start-time"
                  disabled={true}
                  value={
                    shift.started_at
                      ? moment(shift.started_at).format("YYYY-MM-DDTHH:mm")
                      : ""
                  }
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="mb-0">
                <Label for="shift-end-time">
                  <span>Shift End time:</span>
                </Label>
                <Input
                  type="datetime-local"
                  name="shift-end-time"
                  id="shift-end-time"
                  disabled={true}
                  value={
                    shift.ended_on
                      ? moment(shift.ended_on).format("YYYY-MM-DDTHH:mm")
                      : ""
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default DowntimeForm;
