import { utils } from "./utils";
import moment from "moment";
import { Chart, defaults } from "chart.js";

import "chartjs-plugin-datalabels";
import "chartjs-plugin-colorschemes";

defaults.global.defaultFontFamily = "Rubik";

const colors = {
  blue: "rgb(54, 162, 235)",
  green: "rgb(75, 192, 192)",
  grey: "rgb(201, 203, 207)",
  orange: "rgb(255, 159, 64)",
  purple: "rgb(153, 102, 255)",
  red: "rgb(255, 99, 132)",
  yellow: "rgb(255, 205, 86)",
};

const shiftEntryReasons = (se) =>
  se.timeEntries.reduce((p, c) => {
    if (!c.majorReason) {
      return p;
    }
    if (p[c.majorReason.name]) {
      p[c.majorReason.name] += 1;
      return p;
    }
    p[c.majorReason.name] = 1;
    return p;
  }, {});

const machineReasons = (m) =>
  m.shiftEntries.reduce((p, c) => {
    const reasons = shiftEntryReasons(c);
    Object.keys(reasons).forEach((key) => {
      if (p[key]) {
        p[key] += reasons[key];
      } else {
        p[key] = reasons[key];
      }
    });
    return p;
  }, {});

const shiftEntryWeeklyDowntimes = (se) =>
  se.timeEntries.reduce((p, c) => p + c.duration, 0);

const machineWeeklyDowntimes = (m) =>
  m.shiftEntries.reduce((p, c) => {
    const seWeeklyDT = shiftEntryWeeklyDowntimes(c);
    const week = moment(c.date).isoWeek();
    if (p[week]) {
      p[week] += seWeeklyDT;
    } else {
      p[week] = seWeeklyDT;
    }
    return p;
  }, {});

const getWeeksGraphData = (extendedMachines) => {
  const color = Chart.helpers.color;
  const data = extendedMachines.reduce((p, c) => {
    const weeklyTimes = machineWeeklyDowntimes(c);
    Object.keys(weeklyTimes).forEach((key) => {
      if (p[key]) {
        p[key] += weeklyTimes[key];
      } else {
        p[key] = weeklyTimes[key];
      }
    });
    return p;
  }, {});
  return {
    labels: Object.keys(data).map(
      (d) => `${moment().set("week", d).startOf("week").format("MM/DD/YYYY")}`
    ),
    datasets: [
      {
        label: "Downtime",
        backgroundColor: color(colors.green).alpha(0.5).rgbString(),
        borderColor: colors.green,
        borderWidth: 1,
        data: Object.values(data),
      },
    ],
  };
};

const getReasonsGraphData = (machines) => {
  const data = machines.reduce((p, c) => {
    const mReasons = machineReasons(c);
    Object.keys(mReasons).forEach((key) => {
      if (p[key]) {
        p[key] += mReasons[key];
      } else {
        p[key] = mReasons[key];
      }
    });
    return p;
  }, {});
  return {
    datasets: [
      {
        data: Object.values(data).length ? Object.values(data) : ["1"],
        label: "Reasons",
      },
    ],
    labels: Object.keys(data).length ? Object.keys(data) : ["No Downtimes"],
  };
};

const getProductionGraphData = (machines) => {
  const color = Chart.helpers.color;
  return {
    labels: machines.map((m) => m.name),
    datasets: [
      {
        label: "Expected",
        backgroundColor: color(colors.blue).alpha(0.5).rgbString(),
        borderColor: colors.blue,
        borderWidth: 1,
        data: machines.map((m) =>
          (
            m.shiftEntries.reduce(
              (p, c) =>
                p +
                c.shiftJobSteps.reduce(
                  (p, c) => p + c.effective_worked_minutes,
                  0
                ),
              0
            ) * m.expected_per_min
          ).toFixed(0)
        ),
      },
      {
        label: "Actual",
        backgroundColor: color(colors.green).alpha(0.5).rgbString(),
        borderColor: colors.green,
        borderWidth: 1,
        data: machines.map((m) =>
          m.shiftEntries
            .reduce(
              (p, c) =>
                p + c.shiftJobSteps.reduce((p, c) => p + c.production, 0),
              0
            )
            .toFixed(0)
        ),
      },
    ],
  };
};

const getDowntimeGraphData = (startDate, machines) => {
  const dates = [0, 1, 2, 3, 4, 5, 6].map((d) =>
    moment(startDate).add(d, "days").format("MM/DD/YYYY")
  );
  const datasets = machines.map((m, i) => {
    return {
      label: m.name,
      backgroundColor:
        colors[Object.keys(colors)[i % Object.keys(colors).length]],
      borderColor: colors[Object.keys(colors)[i % Object.keys(colors).length]],
      data: dates.map((date) =>
        m.shiftEntries
          .filter((se) => se.date === date) //TODO compare dates using moment with date granularity
          .reduce(
            (p, c) => p + c.timeEntries.reduce((p, c) => p + c.duration, 0),
            0
          )
      ),
      fill: false,
    };
  });
  return {
    labels: dates.map((d) => moment(d).format("MM/DD/YYYY")),
    datasets,
  };
};

const calculateAveragePerDay = (startDate, endDate, machines) => {
  const dates = [];
  const currentDate = moment(startDate);
  while (currentDate.isSameOrBefore(moment(endDate), "date")) {
    dates.push(currentDate.format("YYYY-MM-DD"));
    currentDate.add(1, "day");
  }
  const datasets = machines.map((m, i) => {
    const data = dates.map((date) =>
      m.shiftEntries
        .filter((se) => moment(se.date).isSame(moment(date), "date"))
        .reduce(
          (p, c) => p + c.timeEntries.reduce((p, c) => p + c.duration, 0),
          0
        )
    );
    return {
      label: m.name,
      backgroundColor:
        colors[Object.keys(colors)[i % Object.keys(colors).length]],
      borderColor: colors[Object.keys(colors)[i % Object.keys(colors).length]],
      data,
      fill: false,
    };
  });
  return utils.formatTime(
    datasets.reduce((p, c) => p + c.data.reduce((p, c) => p + c), 0) /
      dates.length
  );
};

const calculateAveragePerWeek = (extendedMachines, weeksForAverage) => {
  return utils.formatTime(
    extendedMachines.reduce(
      (p, c) =>
        p +
        c.shiftEntries.reduce(
          (p, c) => p + c.timeEntries.reduce((p, c) => p + c.duration, 0),
          0
        ),
      0
    ) / weeksForAverage
  );
};

export const chartsReportUtils = {
  getDowntimePerWeek: (extendedMachines) => ({
    type: "bar",
    data: getWeeksGraphData(extendedMachines),
    showTooltips: true,
    options: {
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            const dataset = data["datasets"][0];
            const value = dataset["data"][tooltipItem["index"]];
            return utils.formatTime(value);
          },
        },
      },
      responsive: true,
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Total Downtime By Weeks",
      },
      scales: {
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Downtime",
            },
            ticks: {
              callback: function (label) {
                const ret = utils.formatTime(label);
                return ret;
              },
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              autoSkip: false,
              maxRotation: 45,
              minRotation: 45,
            },
          },
        ],
      },
      plugins: {
        colorschemes: {
          scheme: "tableau.RedBlueBrown12",
        },
        datalabels: {
          formatter: (data) => {
            if (!data || data < 0) {
              return "";
            }
            const ret = utils.formatTime(data);
            return ret;
          },
          font: {
            weight: "bold",
            familiy: "Rubik",
          },
        },
        labels: {
          render: function (data) {
            return utils.formatTime(data.value);
          },
        },
      },
    },
  }),
  getDowntimePerDay: (machines) => ({
    type: "doughnut",
    data: getReasonsGraphData(machines),
    options: {
      tooltips: {
        callbacks: {
          title: function () {},
          label: function (tooltipItem, data) {
            const dataset = data["datasets"][0];
            const total = Object.values(dataset["_meta"])[0]["total"];
            const value = dataset["data"][tooltipItem["index"]];
            const percent = Math.round((value / total) * 100);
            return `${percent}%`;
          },
          afterLabel: function () {},
        },
      },
      responsive: true,
      legend: {
        position: "left",
      },
      pieceLabel: {
        render: "label",
        fontColor: "#000",
        position: "outside",
        segment: true,
      },
      title: {
        display: true,
        text: "Downtime Reasons",
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
      plugins: {
        colorschemes: {
          scheme: "tableau.RedBlueBrown12",
        },
        labels: {
          render: "label",
          fontColor: "#fff",
          fontStyle: "bold",
          precision: 2,
          fontSize: 24,
          fontFamily: "inherit",
        },
      },
    },
  }),
  getProductionGraph: (machines) => ({
    type: "bar",
    data: getProductionGraphData(machines),
    showTooltips: true,
    options: {
      responsive: true,
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Production",
      },
      scales: {
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Produced Items",
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              autoSkip: false,
              maxRotation: 45,
              minRotation: 45,
            },
          },
        ],
      },
      plugins: {
        colorschemes: {
          scheme: "tableau.RedBlueBrown12",
        },
        labels: {
          render: "value",
        },
      },
    },
  }),
  getDowntimeGraph: (startDate, machines) => ({
    type: "line",
    data: getDowntimeGraphData(startDate, machines),
    options: {
      responsive: true,
      title: {
        display: true,
        align: "left",
        text: "Downtime by Machine",
      },
      tooltips: {
        mode: "point",
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      plugins: {
        datalabels: {
          formatter: (data) => (data ? utils.formatTime(data) : ""),
          font: {
            weight: "bold",
            familiy: "Rubik",
          },
        },
      },
      scales: {
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Downtime",
            },
            ticks: {
              callback: function (label, index, labels) {
                return utils.formatTime(label);
              },
            },
          },
        ],
      },
    },
  }),
  colors,
  calculateAveragePerDay,
  calculateAveragePerWeek,
};
