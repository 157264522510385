import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useUpdateTimeEntry } from "../../../api/TimeEntries.hooks";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";

const EditDowntimeModal = ({ downtime, onClose, onSubmit }) => {
  const [editDowntime, setEditDowntime] = useState({
    ...downtime,
  });

  const {
    data: updateTimeEntryData,
    error: updateTimeEntryErr,
    isLoading: isLoadingUpdateTimeEntry,
    update: updateShiftEntry,
  } = useUpdateTimeEntry(downtime.id);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    if (updateTimeEntryErr) {
      setInformationModal({
        isOpen: true,
        title: "Create Downtime Error",
        body:
          updateTimeEntryErr?.response?.data[0].msg ||
          "There was an error with your request.",
      });
    }
  }, [updateTimeEntryErr]);

  useEffect(() => {
    if (updateTimeEntryData) {
      onSubmit();
    }
  }, [updateTimeEntryData, onSubmit]);

  const doSubmit = async (e) => {
    e.preventDefault();
    updateShiftEntry({
      ...editDowntime,
    });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="md">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          Edit Downtime Entry
        </ModalHeader>
        <ModalBody>
          <Row>
            {isLoadingUpdateTimeEntry ? (
              <Loader size="sm" />
            ) : (
              <Col className="col-12">
                <FormGroup>
                  <Label for="notes">
                    <span>Notes</span>
                  </Label>
                  <Input
                    type="textarea"
                    name="notes"
                    id="notes"
                    placeholder="Enter notes here..."
                    onChange={(e) =>
                      setEditDowntime({
                        ...editDowntime,
                        notes: e.target.value,
                      })
                    }
                    value={editDowntime.notes}
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditDowntimeModal;
