import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { utils } from "../../../utils/utils";
import TooltipItem from "../../TooltipItem";

const now = moment().utc();

const MachineStatus = ({ machine }) => {
  const [duration, setDuration] = useState(-1);

  useEffect(() => {
    if (machine.downtime_start && duration < 0) {
      const duration = now.diff(
        moment(machine.downtime_start).utc(),
        "seconds"
      );
      setDuration(Math.max(duration, 0));
    }
  }, [duration, machine.downtime_start]);

  useEffect(() => {
    if (machine.downtime_start && duration >= 0) {
      setTimeout(() => setDuration(Math.max(duration + 1, 0)), 1000);
    }
  }, [machine.downtime_start, duration]);

  const hasActiveShift = () => machine.activeShiftEntry.length > 0;
  const hasActiveExtendedDowntime = () =>
    machine.activeExtendedDowntime?.length;

  const restartDate = () =>
    moment(machine.activeExtendedDowntime[0].restart_date)
      .subtract(1, "day")
      .format("MM/DD/YYYY");

  return (
    <div className="d-flex flex-row w-100">
      {machine.in_downtime ? (
        <span className="flex-grow-1 text-white status bg-primary py-1">
          Down: {utils.timeFromSeconds(duration, true)}
          {hasActiveExtendedDowntime() ? (
            <TooltipItem
              id="extended-tooltip"
              className="float-right mr-2"
              position="bottom"
              title={`Extended downtime until ${restartDate()}`}
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="ml-2 cursor-pointer"
              />
            </TooltipItem>
          ) : null}
        </span>
      ) : hasActiveShift() && machine.start_quantity ? (
        <>
          <span className="flex-grow-1 text-white status bg-secondary py-1">
            {machine.production_schedule >= 1
              ? "On Schedule"
              : "Behind Schedule"}
          </span>
          <span className="flex-grow-1 text-black py-1">
            {machine.quantity_completed}/{machine.start_quantity}
          </span>
        </>
      ) : (
        <span className="flex-grow-1 text-dark status bg-light py-1">
          On Hold
        </span>
      )}
    </div>
  );
};

export default MachineStatus;
