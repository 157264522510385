import React, { useCallback, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";

import UserModal from "../../components/admin/users/UserModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import InformationModal from "../../components/InformationModal";
import Loader from "../../components/Loader";
import { useAuth } from "../../providers/authProvider";

import {
  ACTIONS,
  useUsers,
  MAX_PAGE_SIZE,
} from "../../providers/usersProvider";

import {
  useGetUsers,
  useDeleteUser,
  useArchiveUser,
} from "../../api/Users.hooks";
import {
  faArrowDown,
  faArrowUp,
  faPen,
  faPlus,
  faSearch,
  faSync,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ROLE_EXECUTIVE = "1";

//TODO use constants here
const getUserRoleClass = (role) => {
  const roleName = role.toLowerCase().replace(" ", "");
  switch (roleName) {
    case "executive":
      return "primary";
    case "maintenancetech":
      return "secondary";
    case "operator":
      return "success";
    case "supervisor":
      return "danger";
    default:
      return "info";
  }
};

const Users = () => {
  const [usersContext, setUsersContext] = useUsers();
  const [authContext] = useAuth();

  const {
    data: users,
    error: usersErr,
    isLoading: isLoadingUsers,
    get: getUsers,
  } = useGetUsers();

  const [deleteUserId, setUserDeleteId] = useState();
  const [archiveUserId, setUserArchiveId] = useState();

  const {
    data: deleteUserData,
    error: deleteUserErr,
    isLoading: isLoadingDeleteUsers,
    deleteUser,
  } = useDeleteUser(deleteUserId);

  const {
    data: archiveUserData,
    error: archiveUserErr,
    isLoading: isLoadingArchiveUsers,
    archiveUser,
  } = useArchiveUser(archiveUserId);

  const [userToEdit, setUserToEdit] = useState();

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [createUserModal, setCreateUserModal] = useState(false);

  const [editUserModal, setEditUserModal] = useState(false);

  const getSizePerPage = (size) => (size === "All" ? MAX_PAGE_SIZE : size);

  const onSort = (sortBy, direction) => {
    setUsersContext({
      action: ACTIONS.SORT,
      payload: { sortBy, direction },
    });
  };

  const TABLE_COLUMNS = (onSort) => [
    {
      dataField: "name",
      text: "Name",
      classes: "text-truncate",
      sort: true,
      onSort,
    },
    {
      dataField: "email",
      text: "Email",
      classes: "text-truncate",
    },
    {
      dataField: "shiftName",
      text: "Shift",
      classes: "text-truncate",
      sort: true,
      onSort,
    },
    {
      dataField: "roleName",
      text: "Role",
      classes: "text-truncate",
      formatter: (cell) => <Badge color={getUserRoleClass(cell)}>{cell}</Badge>,
    },
  ];

  useEffect(() => {
    if (deleteUserId) {
      deleteUser();
    }
  }, [deleteUser, deleteUserId]);

  useEffect(() => {
    if (archiveUserId) {
      archiveUser();
    }
  }, [archiveUser, archiveUserId]);

  useEffect(() => {
    if (archiveUserData) {
      setUserArchiveId();
      setInformationModal({
        isOpen: true,
        title: "Archive User",
        body: "User archived successfully.",
      });
      setUsersContext({
        action: ACTIONS.REFRESH,
      });
    }
  }, [archiveUserData, setUsersContext]);

  useEffect(() => {
    if (deleteUserData) {
      setUserDeleteId();
      setInformationModal({
        isOpen: true,
        title: "Delete User",
        body: "User deleted successfully.",
      });
      setUsersContext({
        action: ACTIONS.REFRESH,
      });
    }
  }, [deleteUserData, setUsersContext]);

  useEffect(() => {
    if (deleteUserErr) {
      setUserDeleteId();
      setInformationModal({
        isOpen: true,
        title: "Delete User",
        body: "There was an error with your request.",
      });
    }
  }, [deleteUserErr]);

  useEffect(() => {
    if (archiveUserErr) {
      setUserArchiveId();
      setInformationModal({
        isOpen: true,
        title: "Delete User",
        body: "There was an error with your request.",
      });
    }
  }, [archiveUserErr]);

  const actions = (user) => (
    <div className="d-flex justify-content-between align-items-center">
      <Button
        size="sm"
        className="rounded"
        color="warning"
        onClick={() => onEdit(user)}
      >
        <FontAwesomeIcon icon={faPen} className="mr-1" />
        <span>Edit</span>
      </Button>
      {user.user_type !== ROLE_EXECUTIVE ? (
        <Button
          size="sm"
          className="rounded ml-2"
          color={!user.archived ? "dark" : "success"}
          onClick={() => onArchive(user)}
        >
          <FontAwesomeIcon
            icon={!user.archived ? faArrowDown : faArrowUp}
            className="mr-1"
          />
          <span>{!user.archived ? "Archive" : "Unarchive"}</span>
        </Button>
      ) : null}
      <Button
        size="sm"
        className="rounded ml-2"
        color="danger"
        onClick={() => onDelete(user)}
      >
        <FontAwesomeIcon icon={faTrash} className="mr-1" />
        <span>Delete</span>
      </Button>
    </div>
  );

  const onEdit = (user) => {
    setUserToEdit(user);
    setEditUserModal(true);
  };

  const onArchive = (user) => {
    if (user.id === authContext.currentUser.id) {
      return setInformationModal({
        isOpen: true,
        title: "Archive User",
        body: "Can't archive yourself.",
      });
    }
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        setUserArchiveId(user.id);
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
        setUsersContext({
          action: ACTIONS.REFRESH,
        });
      },
      title: `${!user.archived ? "Archive" : "Unarchive"} User`,
      body: `Are you sure you want to ${
        !user.archived ? "archive" : "unarchive"
      } ${user.name}? ${
        !user.archived ? "The user's data wont be removed." : ""
      }`,
      confirmColor: "danger",
    });
  };

  const onDelete = (user) => {
    if (user.id === authContext.currentUser.id) {
      return setInformationModal({
        isOpen: true,
        title: "Delete User",
        body: "Can't delete yourself.",
      });
    }
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        setUserDeleteId(user.id);
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
        setUsersContext({
          action: ACTIONS.REFRESH,
        });
      },
      title: "Delete User",
      body: `Are you sure you want to delete ${user.name} and all the data associated? Note that you may prefer to archive so no data is lost.`,
      confirmColor: "danger",
    });
  };

  const setUsersContextCb = useCallback(
    (data) => setUsersContext(data),
    [setUsersContext]
  );

  useEffect(() => {
    getUsers({
      search: usersContext.search,
      page: usersContext.page,
      page_size: getSizePerPage(usersContext.sizePerPage),
      sortBy: usersContext.sortBy,
      direction: usersContext.direction,
    });
  }, [
    getUsers,
    usersContext.direction,
    usersContext.sortBy,
    usersContext.page,
    usersContext.sizePerPage,
    usersContext.refresh,
    usersContext.search,
  ]);

  useEffect(() => {
    if (users) {
      setUsersContextCb({
        action: ACTIONS.GET_USERS_SUCCESS,
        payload: users,
      });
    }
  }, [users, setUsersContextCb]);

  useEffect(() => {
    if (usersErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [usersErr]);

  return (
    <Container className="mx-0" fluid>
      <Row>
        <Col>
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">Users</h3>
                <small className="text-muted ml-2 pt-1">
                  ({usersContext.pagination?.rowCount || 0})
                </small>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup size="m" className="mr-3">
                  <Input
                    maxLength="50"
                    placeholder="Search by.."
                    value={usersContext.search}
                    onChange={(evt) =>
                      setUsersContext({
                        action: ACTIONS.SEARCH,
                        payload: { search: evt.target.value },
                      })
                    }
                  />
                  <InputGroupText className="search-jobs input-group-text bg-primary text-white border-left-0 border-primary">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroupText>
                </InputGroup>
                <Button
                  size="sm"
                  className="mr-3 rounded-circle square-32"
                  color="primary"
                  onClick={() => setCreateUserModal(true)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
                <Button
                  size="sm"
                  className="rounded-circle square-32"
                  color="primary"
                  onClick={() =>
                    setUsersContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              {isLoadingUsers ||
              isLoadingDeleteUsers ||
              isLoadingArchiveUsers ? (
                <Loader />
              ) : usersContext.users?.length ? (
                <BootstrapTable
                  bootstrap4
                  remote
                  striped
                  bordered={false}
                  keyField="id"
                  classes="border"
                  headerClasses="small text-muted"
                  data={usersContext.users}
                  columns={[
                    ...TABLE_COLUMNS(onSort),
                    {
                      dataField: "",
                      text: "",
                      sort: false,
                      classes: "text-right",
                      formatter: (cell, user) => actions(user),
                    },
                  ]}
                  defaultSorted={[
                    {
                      dataField: usersContext.sortBy,
                      order: usersContext.direction,
                    },
                  ]}
                  onTableChange={() => {}}
                  pagination={paginationFactory({
                    page: usersContext.page,
                    totalSize: usersContext.pagination.rowCount,
                    sizePerPage: getSizePerPage(usersContext.sizePerPage),
                    sizePerPageList: [
                      5,
                      10,
                      25,
                      50,
                      {
                        text: "All",
                        value: usersContext.pagination.rowCount,
                      },
                    ],
                    onPageChange: (page) =>
                      setUsersContext({
                        action: ACTIONS.PAGE_CHANGE,
                        payload: { page },
                      }),
                    onSizePerPageChange: (sizePerPage) =>
                      setUsersContext({
                        action: ACTIONS.PAGE_SIZE_CHANGE,
                        payload: { sizePerPage },
                      }),
                  })}
                />
              ) : (
                <div className="text-center">No results</div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {createUserModal ? (
        <UserModal
          onClose={() => {
            setCreateUserModal(false);
          }}
          onSubmit={() => {
            setCreateUserModal(false);
            setInformationModal({
              isOpen: true,
              title: "Create User",
              body: "User created successfully.",
              onClose: () => {
                setUsersContext({
                  action: ACTIONS.REFRESH,
                });
                setInformationModal({ isOpen: false });
              },
            });
          }}
        />
      ) : editUserModal ? (
        <UserModal
          user={userToEdit}
          onClose={() => {
            setUserToEdit(null);
            setEditUserModal(false);
          }}
          onSubmit={() => {
            setUserToEdit(null);
            setEditUserModal(false);
            setInformationModal({
              isOpen: true,
              title: "Update User",
              body: "User Updated Successfully.",
            });
            setUsersContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false })
          }
        />
      ) : null}
    </Container>
  );
};

export default withRouter(Users);
