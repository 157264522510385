import React, { useReducer, createContext, useContext } from "react";

const ROLE_EXECUTIVE = "1";
const ROLE_SUPERVISOR = "2";
const ROLE_OPERATOR = "3";
const ROLE_MAINTENANCE_TECH = "4";

const USER_ROLES = [
  { name: "Executive", value: ROLE_EXECUTIVE },
  { name: "Supervisor", value: ROLE_SUPERVISOR },
  { name: "Operator", value: ROLE_OPERATOR },
  { name: "Maintenance Tech", value: ROLE_MAINTENANCE_TECH },
];

const SHIFT_SCHEDULES = [
  { name: "First Shift", value: 1 },
  { name: "Second Shift", value: 2 },
  { name: "Third Shift", value: 3 },
];

const getRole = (roleId) => {
  const obj = USER_ROLES.find((r) => r.value === roleId);
  return obj ? obj.name : "-";
};

const getShift = (shiftId) => {
  const obj = SHIFT_SCHEDULES.find((s) => s.value === shiftId);
  return obj ? obj.name : "Not Set";
};

const MAX_PAGE_SIZE = 100;
const INIT_PAGE_SIZE = 10;
const INIT_PAGE = 1;

const SORT_ORDER = {
  ASC: "asc",
  DESC: "desc",
};

const DEFAULT_SORT_BY = "name";

const initialState = {
  users: {},
  pagination: {},
  sizePerPage: INIT_PAGE_SIZE,
  page: INIT_PAGE,
  search: "",
  sortBy: DEFAULT_SORT_BY,
  direction: SORT_ORDER.ASC,
};

const ACTIONS = {
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  SEARCH: "SEARCH",
  PAGE_CHANGE: "PAGE_CHANGE",
  PAGE_SIZE_CHANGE: "PAGE_SIZE_CHANGE",
  REFRESH: "REFRESH",
  SORT: "SORT",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_USERS_SUCCESS: {
      const { payload } = data;
      const { users, pagination } = payload;

      return {
        ...state,
        users: users?.map((user) => ({
          ...user,
          roleName: getRole(user.user_type),
          shiftName: getShift(user.shift_schedule_id),
        })),
        pagination,
      };
    }

    case ACTIONS.PAGE_CHANGE: {
      const { payload } = data;
      const { page } = payload;
      return {
        ...state,
        page,
      };
    }

    case ACTIONS.PAGE_SIZE_CHANGE: {
      const { payload } = data;
      const { sizePerPage } = payload;
      return {
        ...state,
        sizePerPage,
        page: INIT_PAGE,
      };
    }

    case ACTIONS.SEARCH: {
      const { payload } = data;
      const { search } = payload;

      return {
        ...state,
        search,
        page: 1,
      };
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case ACTIONS.SORT:
      const { payload } = data;
      const { sortBy, direction } = payload;
      return {
        ...state,
        sortBy,
        direction,
      };

    default:
      throw new Error();
  }
};

const UsersContext = createContext(initialState);

const UsersProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <UsersContext.Provider value={stateAndDispatch}>
      {children}
    </UsersContext.Provider>
  );
};

export const useUsers = () => useContext(UsersContext);

export {
  UsersContext,
  UsersProvider,
  ACTIONS,
  USER_ROLES,
  MAX_PAGE_SIZE,
  SHIFT_SCHEDULES,
  getRole,
  getShift,
};
