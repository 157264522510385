import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import { utils } from "../../../utils/utils";

import { useShifts, ACTIONS } from "../../../providers/shiftsTableProvider";

import { useDeleteTimeEntry } from "../../../api/TimeEntries.hooks";

import TooltipItem from "../../TooltipItem";
import EditDowntimeModal from "./EditDowntimeModal";
import InformationModal from "../../InformationModal";
import ConfirmationModal from "../../ConfirmationModal";
import moment from "moment";

const now = moment().utc();

const TimeEntryDetails = ({ isExtendedDowntime, timeEntry }) => {
  const [, setShiftsContext] = useShifts();
  const [deleteTimeEntryId, setDeleteTimeEntryId] = useState();
  const [duration, setDuration] = useState(-1);

  useEffect(() => {
    if (!timeEntry.duration && duration < 0) {
      const duration = now.diff(moment(timeEntry.start_time).utc(), "seconds");
      setDuration(Math.max(duration, 0));
    }
  }, [duration, timeEntry.start_time, timeEntry.duration]);

  useEffect(() => {
    if (!timeEntry.duration && duration >= 0) {
      setTimeout(() => setDuration(Math.max(duration + 1, 0)), 1000);
    }
  }, [duration, timeEntry.duration]);

  const {
    error: deleteTimeEntryErr,
    data: deleteTimeEntryData,
    deleteTimeEntry,
  } = useDeleteTimeEntry(deleteTimeEntryId);

  const [editDowntimeModal, setEditDowntimeModal] = useState(false);
  const [editDowntimeEntry, setEditDowntimeEntry] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const onDeleteDowntime = (timeEntry) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setDeleteTimeEntryId(timeEntry.id);
        setConfirmationModal(initConfirmationModal);
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Remove Downtime",
      body: `Are you sure you want to delete this downtime?`,
      confirmColor: "danger",
    });
  };

  useEffect(() => {
    if (deleteTimeEntryId) {
      deleteTimeEntry();
    }
  }, [deleteTimeEntry, deleteTimeEntryId]);

  useEffect(() => {
    if (deleteTimeEntryData) {
      setShiftsContext({
        action: ACTIONS.REFRESH,
      });
    }
  }, [deleteTimeEntryData, setShiftsContext]);

  useEffect(() => {
    if (deleteTimeEntryErr) {
      setInformationModal({
        isOpen: true,
        title: "Delete Time Entry",
        body: "There was an error with your request.",
      });
    }
  }, [deleteTimeEntryErr]);

  useEffect(() => {
    if (editDowntimeEntry) {
      setEditDowntimeModal(true);
    }
  }, [editDowntimeEntry]);

  useEffect(() => {
    if (!editDowntimeModal) {
      setEditDowntimeEntry();
    }
  }, [editDowntimeModal]);

  return (
    <div className="col-12 d-flex py-3 justify-content-evenly">
      <div className="col-2 font-weight-bold">
        {timeEntry.duration
          ? utils.timeFromSeconds(timeEntry.duration, true)
          : utils.timeFromSeconds(duration, true)}
      </div>
      <div className="col-2">
        {timeEntry.majorReason && timeEntry.majorReason.name
          ? timeEntry.majorReason.name
          : "N/A"}
        {timeEntry.minorReason && timeEntry.minorReason.name
          ? "- " + timeEntry.minorReason.name
          : ""}
      </div>
      <div className="col-2">
        <span>
          {timeEntry.notes && timeEntry.notes.length ? timeEntry.notes : "N/A"}
        </span>
      </div>
      <div className="col-2">{timeEntry.active ? "Active" : "On Hold"}</div>
      <div className="col-2">{timeEntry.is_quick_time ? "Yes" : "No"}</div>
      {isExtendedDowntime ? (
        <div className="text-right col-2">
          <TooltipItem id="tooltip-edit-downtime" title={"Edit Downtime"}>
            <Button
              size="sm"
              className="rounded mr-2"
              color="warning"
              onClick={() => setEditDowntimeEntry(timeEntry)}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
          </TooltipItem>
          <TooltipItem id="tooltip-remove-downtime" title={"Remove Downtime"}>
            <Button
              size="sm"
              className="rounded"
              color="danger"
              onClick={() => onDeleteDowntime(timeEntry)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </TooltipItem>
        </div>
      ) : null}
      {editDowntimeModal && (
        <EditDowntimeModal
          downtime={editDowntimeEntry}
          onClose={() => {
            setEditDowntimeModal(false);
          }}
          onSubmit={() => {
            setEditDowntimeModal(false);
            setShiftsContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      )}
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </div>
  );
};

export default TimeEntryDetails;
