import { faQuestionCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  CustomInput,
  Table,
} from "reactstrap";

import { useGetJobs, useUpdateJob } from "../../api/Jobs.hooks";
import { useDeleteJobStep } from "../../api/JobSteps.hooks";
import JobProduction from "../../components/admin/jobs/JobProduction";
import ConfirmationModal from "../../components/ConfirmationModal";
import InformationModal from "../../components/InformationModal";
import Loader from "../../components/Loader";
import TooltipItem from "../../components/TooltipItem";
import { utils } from "../../utils/utils";
import { jobStepsApi } from "../../api/jobStepServices";

const DATE_TIME_FORMAT = "YYYY/MM/DD HH:mm";

const JOB_STATUS_IN_PROGRESS = "1";
const JOB_STATUS_COMPLETED = "2";
const JOB_STATUS_NOT_STARTED_ORACLE = "3";
const JOB_STATUS_CLOSED = "4";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const getStepNumber = (job, activeJobStep) =>
  job.jobSteps.findIndex((js) => js.id === activeJobStep.id) + 1;

const getProductionRate = (activeJobStep) => {
  const activeShiftJobStep = activeJobStep.activeShiftJobStep[0];
  const schedule =
    activeShiftJobStep.effective_worked_minutes > 0
      ? (
          activeShiftJobStep.production /
          activeShiftJobStep.effective_worked_minutes /
          activeJobStep.machine.expected_per_min
        ).toFixed(2)
      : 0;
  return schedule >= 1
    ? { schedule, text: "On Schedule" }
    : { schedule, text: "Behind Schedule" };
};

const getClarificationText = (activeJobStep) => {
  const activeShiftJobStep = activeJobStep.activeShiftJobStep[0];
  const productionRate = getProductionRate(activeJobStep);
  return `The Operator ${
    activeShiftJobStep.shiftEntry.user.name
  } is ${productionRate.text.toLowerCase()} because is expected to produce ${
    activeJobStep.machine.expected_per_min
  } pieces per minute, and during the ${activeShiftJobStep.effective_worked_minutes.toFixed(
    2
  )} minutes that has worked in his current shift, has produced ${
    activeShiftJobStep.production
  }. ${productionRate.schedule >= 1 ? "More" : "Fewer"} than the ${(
    activeShiftJobStep.effective_worked_minutes *
    activeJobStep.machine.expected_per_min
  ).toFixed(2)} pieces expected.`;
};

const JobDetailsTable = ({ job }) => {
  const {
    error: updateJobErr,
    isLoading: isLoadingUpdateJob,
    update: updateJob,
    data: updateJobData,
  } = useUpdateJob(job.id);

  const [status, setStatus] = useState();

  useEffect(() => {
    setStatus(utils.jobStatus(job));
  }, [job]);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    if (updateJobErr) {
      setInformationModal({
        isOpen: true,
        title: "Update Job Status",
        body:
          updateJobErr?.response?.data[0].msg ||
          "There was an error with your request.",
      });
    }
  }, [updateJobErr]);

  useEffect(() => {
    if (updateJobData) {
      setInformationModal({
        isOpen: true,
        title: "Update Job Status",
        body: "Status updated successfully.",
      });
    }
  }, [updateJobData]);

  const onJobStatusChange = async (value) => {
    setStatus(value);
    if (value === JOB_STATUS_IN_PROGRESS) {
      updateJob({ ...job, started: true, ended: false });
    } else if (value === JOB_STATUS_COMPLETED) {
      updateJob({ ...job, started: true, ended: true });
    } else if (value === JOB_STATUS_NOT_STARTED_ORACLE) {
      updateJob({ ...job, started: false, ended: false });
    } else if (value === JOB_STATUS_CLOSED) {
      updateJob({ ...job, status: "closed" });
    }
  };

  return (
    <Table className="mb-0 border">
      <tbody>
        <tr>
          <td>Job#:</td>
          <td className="text-right">{job.id}</td>
        </tr>
        <tr>
          <td>Status:</td>
          <td className="text-right">
            {isLoadingUpdateJob ? (
              <Loader size="sm" />
            ) : (
              <CustomInput
                id="statusSelect"
                type="select"
                className="text-right"
                name="statusSelect"
                required
                value={status}
                onChange={(e) => onJobStatusChange(e.currentTarget.value)}
              >
                <option value={""}>Select a status..</option>
                {utils.JOB_STATUSES.map((status) => (
                  <option key={status.id} value={status.id}>
                    {status.name}
                  </option>
                ))}
              </CustomInput>
            )}
          </td>
        </tr>
        <tr>
          <td>Item:</td>
          <td className="text-right">{job.item || "-"}</td>
        </tr>
        <tr>
          <td>Description:</td>
          <td className="text-right">{unescape(job.description) || "-"}</td>
        </tr>
        <tr>
          <td>Class Code:</td>
          <td className="text-right">{job.class_code || "-"}</td>
        </tr>
      </tbody>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
    </Table>
  );
};

const JobsStepsTable = ({ jobSteps }) => {
  const [deleteJobStepId, setDeleteJobStepId] = useState();

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const {
    error: deleteJobStepErr,
    data: deleteJobStepData,
    deleteJobStep,
  } = useDeleteJobStep(deleteJobStepId);

  const onDeleteJobStep = (step) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setDeleteJobStepId(step.id);
        setConfirmationModal(initConfirmationModal);
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Remove Shift",
      body: `Are you sure you want to delete this step?`,
      confirmColor: "danger",
    });
  };

  useEffect(() => {
    if (deleteJobStepId) {
      deleteJobStep();
    }
  }, [deleteJobStep, deleteJobStepId]);

  useEffect(() => {
    if (deleteJobStepData) {
      window.location.reload(false);
    }
  }, [deleteJobStepData]);

  useEffect(() => {
    if (deleteJobStepErr) {
      setInformationModal({
        isOpen: true,
        title: "Delete Job Step",
        body: deleteJobStepErr?.response?.data?.length
          ? deleteJobStepErr.response.data[0].msg
          : "There was an error, please try again.",
      });
    }
  }, [deleteJobStepErr]);

  return (
    <>
      {jobSteps?.length ? (
        jobSteps
          .sort(
            (x, y) =>
              (y.order || Number.MAX_SAFE_INTEGER) -
              (x.order || Number.MAX_SAFE_INTEGER)
          )
          .map((jobStep, index) => (
            <Table key={index} className="border mb-0">
              <thead>
                <tr className="bg-lighter">
                  <th
                    className="text-center font-weight-normal border-bottom"
                    colSpan="2"
                  >
                    <div className="d-flex">
                      <div className="col-4"></div>
                      <div className="col-4">{jobStep.machine.name} Step</div>
                      <div className="col-4 text-right">
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="text-danger cursor-pointer"
                          onClick={() => onDeleteJobStep(jobStep)}
                        />
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Order:</td>
                  <td className="text-right">{jobStep.order || "N/A"}</td>
                </tr>
                <tr>
                  <td>Status:</td>
                  <td className="text-right">
                    <Badge
                      color={
                        jobStep.completed || jobStep.active
                          ? "success"
                          : "warning"
                      }
                    >
                      {jobStep.completed
                        ? "Completed"
                        : jobStep.active
                        ? "In Progress"
                        : "Next Up"}
                    </Badge>
                  </td>
                </tr>
                <tr>
                  <td>Machine:</td>
                  <td className="text-right">{jobStep.machine.name}</td>
                </tr>
                <tr>
                  <td>Expected per minute:</td>
                  <td className="text-right">
                    {jobStep.machine.expected_per_min || 0} pieces
                  </td>
                </tr>
                <tr>
                  <td>
                    Start Quantity:
                    <TooltipItem
                      id="start-quantity-tooltip"
                      title={
                        "This is the required number of pieces to complete the Job Step"
                      }
                    >
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        className="ml-2 cursor-pointer"
                      />
                    </TooltipItem>
                  </td>
                  <td className="text-right">
                    {jobStep.start_quantity || 0} pieces
                  </td>
                </tr>
                <tr>
                  <td>Quantity Remaining:</td>
                  <td className="text-right">
                    {jobStep.quantity_remaining || 0} pieces
                  </td>
                </tr>
                <tr>
                  <td>Quantity Completed:</td>
                  <td className="text-right">
                    {jobStep.quantity_completed || 0} pieces
                  </td>
                </tr>
                <tr>
                  <td>Order:</td>
                  <td className="text-right">{jobStep.order || "Not Set"}</td>
                </tr>
              </tbody>
            </Table>
          ))
      ) : (
        <div className="text-center small">There are no job steps</div>
      )}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
    </>
  );
};

const ActiveJobsStepsTable = ({ job, activeJobSteps }) =>
  activeJobSteps && activeJobSteps.length ? (
    <Table className="border">
      {activeJobSteps.map((activeJobStep, index) => (
        <tbody key={index}>
          <tr className="bg-lighter">
            <td className="text-center font-weight-normal" colSpan="2">
              Step {getStepNumber(job, activeJobStep)}
            </td>
          </tr>
          <tr>
            <td>Operator:</td>
            <td className="text-right">
              {activeJobStep.activeShiftJobStep[0].shiftEntry.user.name}
            </td>
          </tr>
          <tr>
            <td>Production:</td>
            <td className="text-right">
              {activeJobStep.activeShiftJobStep[0].production} pieces
            </td>
          </tr>
          <tr>
            <td>Machine:</td>
            <td className="text-right">{activeJobStep.machine.name}</td>
          </tr>
          <tr>
            <td>Operator Schedule:</td>
            <td className="text-right">
              {getProductionRate(activeJobStep).text}
            </td>
          </tr>
          <tr>
            <td>Initial Quantity:</td>
            <td className="text-right">
              {activeJobStep.activeShiftJobStep[0].initial_quantity} pieces
            </td>
          </tr>
          <tr>
            <td>Started On:</td>
            <td className="text-right">
              {utils.formatDate(
                activeJobStep.activeShiftJobStep[0].start_time,
                DATE_TIME_FORMAT
              )}
            </td>
          </tr>
          <tr>
            <td>Downtime:</td>
            <td className="text-right">
              {activeJobStep.activeShiftJobStep[0].downtime} minutes
            </td>
          </tr>
          <tr>
            <td>Working Time:</td>
            <td className="text-right">
              {activeJobStep.activeShiftJobStep[0].effective_worked_minutes.toFixed(
                2
              )}
              minutes
            </td>
          </tr>
          <tr>
            <td colSpan="2">{getClarificationText(activeJobStep)}</td>
          </tr>
        </tbody>
      ))}
    </Table>
  ) : (
    <div className="text-center small">There are no shifts in progress</div>
  );

const JobDetails = () => {
  const { jobId } = useParams();
  const [job, setJob] = useState({});
  const [activeJobStep, setActiveJobStep] = useState();

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [isLoadingCompletingJob, setIsLoadingCompletingJob] = useState();
  const [refresh, setRefresh] = useState(false);

  const onComplete = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        setIsLoadingCompletingJob(true);
        await jobStepsApi.completeJobStep({
          machine_id: activeJobStep.machine_id,
          job_id: activeJobStep.job_id,
        });
        setIsLoadingCompletingJob(false);
        setRefresh(!refresh);
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Complete Job Step",
      body: `
          <div class="text-center">
              Do you confirm you want to set this job step as completed?
          </div>
    `,
      confirmColor: "warning",
    });
  };

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const {
    data: jobData,
    error: jobErr,
    isLoading: isLoadingJob,
    get: getJob,
  } = useGetJobs({ id: jobId });

  useEffect(() => {
    getJob();
  }, [getJob, refresh]);

  useEffect(() => {
    if (jobData) {
      setJob(jobData);
      const activeJobStepFound = jobData.jobSteps.find((js) => js.active);
      if (activeJobStepFound) {
        setActiveJobStep(activeJobStepFound);
      } else {
        setActiveJobStep(undefined);
      }
    }
  }, [jobData]);

  useEffect(() => {
    if (jobErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [jobErr]);

  return (
    <Container className="mx-0" fluid>
      {isLoadingJob ? (
        <Loader />
      ) : (
        <div className="d-flex flex-column">
          <div className="d-flex">
            <div className="col-4 d-flex">
              <Card className="flex-grow-1">
                <CardHeader className="h3 d-flex align-items-center justify-content-center font-weight-bold">
                  Job Information
                </CardHeader>
                <CardBody>
                  <JobDetailsTable job={job} />
                  {activeJobStep ? (
                    isLoadingCompletingJob ? (
                      <div className="mt-4">
                        <Loader size="sm" align="start" />
                      </div>
                    ) : (
                      <Button
                        className="rounded mt-4"
                        size="sm"
                        color="success"
                        onClick={() => onComplete()}
                      >
                        Complete Job
                      </Button>
                    )
                  ) : null}
                </CardBody>
              </Card>
            </div>
            <div className="col-4 d-flex">
              <Card className="flex-grow-1">
                <CardHeader className="h3 d-flex align-items-center justify-content-center font-weight-bold">
                  Job Steps
                </CardHeader>
                <CardBody>
                  <JobsStepsTable jobSteps={job.jobSteps} />
                </CardBody>
              </Card>
            </div>
            <div className="col-4 d-flex">
              <Card className="flex-grow-1">
                <CardHeader className="h3 d-flex align-items-center justify-content-center font-weight-bold">
                  In Progress Shifts
                </CardHeader>
                <CardBody>
                  <ActiveJobsStepsTable
                    job={job}
                    activeJobSteps={job.activeJobSteps}
                  />
                </CardBody>
              </Card>
            </div>
          </div>
          <JobProduction jobId={jobId} />
        </div>
      )}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Container>
  );
};

export default JobDetails;
