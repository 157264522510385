import React, { useState, useEffect } from "react";

import { Card, CardBody } from "reactstrap";

import { useChartsReport } from "../../../providers/chartsReportProvider";
import { chartsReportUtils } from "../../../utils/chartsReportUtils";
import { Chart as ChartJS, defaults } from "chart.js";

import "chartjs-plugin-datalabels";
import "chartjs-plugin-colorschemes";

defaults.global.defaultFontFamily = "Rubik";

const AverageDowntimePerDay = () => {
  const [chartsReportContext] = useChartsReport();

  const [, setChart] = useState();
  const [averagePerDay, setAveragePerDay] = useState("");

  useEffect(() => {
    const average = chartsReportUtils.calculateAveragePerDay(
      chartsReportContext.startDate,
      chartsReportContext.endDate,
      chartsReportContext.charts
    );
    setAveragePerDay(average);
  }, [
    chartsReportContext.charts,
    chartsReportContext.endDate,
    chartsReportContext.startDate,
  ]);

  useEffect(() => {
    const chartOptions = chartsReportUtils.getDowntimePerDay(
      chartsReportContext.charts
    );
    const container = document.getElementById("days-chart-canvas");
    const ctx = container.getContext("2d");
    const newChart = new ChartJS(ctx, chartOptions);
    setChart((previousValue) => {
      previousValue && previousValue.destroy();
      return newChart;
    });
  }, [
    chartsReportContext.extCharts,
    chartsReportContext.charts,
    chartsReportContext.startDate,
  ]);

  return (
    <Card className="border-radius-0 box-shadow-none border-left border-bottom border-right">
      <CardBody className="d-flex flex-column">
        <div className="text-center d-flex justify-content-end">
          <div className="d-flex flex-column">
            <span>Average downtime per day for the selected period</span>
            <span className="font-weight-bold">{averagePerDay}</span>
          </div>
        </div>
        <canvas id="days-chart-canvas" className="mt-n5"></canvas>
      </CardBody>
    </Card>
  );
};

export default AverageDowntimePerDay;
