import React from "react";
import { Badge } from "reactstrap";

import { SHIFT_STATUS } from "../../../providers/shiftsTableProvider";

import ShiftDetails from "./ShiftDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {
  ACTIONS,
  useDowntimeTracker,
} from "../../../providers/downtimeTrackerProvider";

const DATE_TIME_FORMAT = "MM/DD/YYYY HH:mm";

const getShiftStatusName = (status) => {
  switch (parseInt(status)) {
    case SHIFT_STATUS.SHIFT_STATUS_IN_PROGRESS:
      return "In Progress";
    case SHIFT_STATUS.SHIFT_STATUS_COMPLETED:
      return "Completed";
    case SHIFT_STATUS.SHIFT_STATUS_APPROVED:
      return "Approved";
    case SHIFT_STATUS.SHIFT_STATUS_EXTENDED_DOWNTIME:
      return "Extended Downtime";
    default:
      return "";
  }
};

const getShiftStatusClass = (status) => {
  switch (parseInt(status)) {
    case SHIFT_STATUS.SHIFT_STATUS_IN_PROGRESS:
      return "primary";
    case SHIFT_STATUS.SHIFT_STATUS_COMPLETED:
      return "secondary";
    case SHIFT_STATUS.SHIFT_STATUS_APPROVED:
      return "success";
    case SHIFT_STATUS.SHIFT_STATUS_EXTENDED_DOWNTIME:
      return "danger";
    default:
      return "info";
  }
};

const ShiftsTableEntry = ({ machine, shiftEntry }) => {
  const [downtimeTrackerContext, setDowntimeTrackerContext] =
    useDowntimeTracker();

  const handleShiftClick = (selectedShift) => {
    setDowntimeTrackerContext({
      action: ACTIONS.TOGGLE_OPEN_SHIFT,
      payload: { selectedShift },
    });
  };

  return (
    <tbody
      className={`border-1 ${
        shiftEntry && downtimeTrackerContext.openShifts[shiftEntry?.id]
          ? "border"
          : ""
      }`}
    >
      <tr
        className="cursor-pointer"
        onClick={() => (shiftEntry ? handleShiftClick(shiftEntry.id) : null)}
      >
        <td className="font-weight-bold align-middle p-3">
          <span className="float-left">{machine ? machine.name : ""}</span>
        </td>
        <td className="text-center align-middle p-3">
          {shiftEntry?.user ? shiftEntry.user.name : ""}
        </td>
        <td className="text-center align-middle p-3">
          {shiftEntry?.shiftSchedule ? shiftEntry.shiftSchedule.name : ""}
        </td>
        <td className="text-center align-middle p-3">
          {shiftEntry
            ? moment(shiftEntry.started_at).format(DATE_TIME_FORMAT)
            : ""}
        </td>
        <td className="text-center align-middle p-3">
          {shiftEntry?.ended_on && moment(shiftEntry.ended_on).isValid()
            ? moment(shiftEntry.ended_on).format(DATE_TIME_FORMAT)
            : ""}
        </td>
        <td className="text-center align-middle p-3">
          <>
            <Badge color={getShiftStatusClass(shiftEntry?.status)}>
              {getShiftStatusName(shiftEntry?.status)}
            </Badge>
          </>
        </td>
        <td className="text-right align-middle p-3">
          {shiftEntry?.id ? (
            <FontAwesomeIcon
              className="mr-2"
              icon={
                downtimeTrackerContext.openShifts[shiftEntry?.id]
                  ? faChevronDown
                  : faChevronUp
              }
            />
          ) : null}
        </td>
      </tr>
      {downtimeTrackerContext.openShifts[shiftEntry?.id] ? (
        <tr>
          <td className="align-middle p-0" colSpan="8">
            <ShiftDetails shift={shiftEntry} />
          </td>
        </tr>
      ) : null}
    </tbody>
  );
};

export default ShiftsTableEntry;
