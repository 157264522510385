import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import { utils } from "../../../utils/utils";
import { useUpdateShiftEntry } from "../../../api/ShiftEntries.hooks";
import moment from "moment";

const SHIFT_STATUS_COMPLETED = 1;

const ResolveOpenShiftModal = ({ shift, onClose, onSubmit }) => {
  const {
    error: updateShiftEntryErr,
    isLoading: isLoadingUpdateShiftEntry,
    update: updateShiftEntry,
  } = useUpdateShiftEntry(shift.id);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const doSubmit = async (e) => {
    e.preventDefault();
    if (shift.id) {
      await updateShiftEntry({
        ...shift,
        status: SHIFT_STATUS_COMPLETED,
        ended_on: moment(shift.started_at).add(8, "hours").utc().toDate(),
      });
      onSubmit();
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  useEffect(() => {
    if (updateShiftEntryErr) {
      setInformationModal({
        isOpen: true,
        title: "Update Shift",
        body:
          updateShiftEntryErr?.response?.data[0].msg ||
          "There was an error with your request.",
      });
    }
  }, [updateShiftEntryErr]);

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex bg-secondary justify-content-between"
          close={closeBtn}
        >
          <span className="text-white">Resolve Open Shift</span>
        </ModalHeader>
        <ModalBody>
          <Row>
            {isLoadingUpdateShiftEntry ? (
              <Loader size="sm" />
            ) : (
              <div className="col-12 d-flex flex-column">
                <div className="pb-2 border-bottom">
                  <span className="font-weight-bold">Machine: </span>
                  <span>{shift.machine ? shift.machine.name : ""}</span>
                </div>
                <div className="mt-2 pb-2 border-bottom">
                  <span className="font-weight-bold">Operator: </span>
                  <span>{shift.user ? shift.user.name : ""}</span>
                </div>
                <div className="rounded bg-light p-2 mt-3 border">
                  <h5 className="border-bottom text-center pt-1 pb-2 mb-0">
                    Downtime Report
                  </h5>
                  <ul className="list-group">
                    {shift.timeEntries && shift.timeEntries.length ? (
                      shift.timeEntries.map((timeEntry) => (
                        <li
                          key={timeEntry.id}
                          className="mt-1 list-group-item border-bottom d-flex justify-content-between bg-light py-1 border-top-0 border-left-0 border-right-0 border-bottom"
                        >
                          <span>{timeEntry.majorReason.name}</span>
                          <span>
                            {utils.timeFromSeconds(timeEntry.duration, false)}
                          </span>
                        </li>
                      ))
                    ) : (
                      <li className="mt-1 text-center list-group-item border-0 bg-light py-1">
                        No downtimes to show
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-center">
              <Button color={"primary"} type="submit">
                Close Shift
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ResolveOpenShiftModal;
