import { useApiMutation } from "./Api.hooks";
import { HttpMethod } from "./Api";
import { useCallback } from "react";

const endpoint = `/api/extended-downtime`;

export const useCreateExtendedDowntime = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useCancelExtendedDowntime = (extendedDowntimeId) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.POST,
    `${endpoint}/${extendedDowntimeId}/cancel`
  );

  const cancel = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, cancel };
};
