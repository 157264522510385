import React, { useReducer, createContext, useContext } from "react";

const MAX_PAGE_SIZE = 100;
const INIT_PAGE_SIZE = 10;
const INIT_PAGE = 1;

const initialState = {
  jobs: {},
  pagination: {},
  sizePerPage: INIT_PAGE_SIZE,
  page: INIT_PAGE,
  search: "",
  sortBy: "status",
  direction: "desc",
};

const ACTIONS = {
  SORT: "SORT",
  SEARCH: "SEARCH",
  REFRESH: "REFRESH",
  PAGE_CHANGE: "PAGE_CHANGE",
  PAGE_SIZE_CHANGE: "PAGE_SIZE_CHANGE",
  GET_JOBS_SUCCESS: "GET_JOBS_SUCCESS",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_JOBS_SUCCESS: {
      const { payload } = data;
      const { jobs, pagination } = payload;
      return {
        ...state,
        jobs,
        pagination,
      };
    }

    case ACTIONS.SORT: {
      const { payload } = data;
      const { sortBy, direction } = payload;

      return {
        ...state,
        sortBy,
        direction: direction,
        page: 1,
      };
    }

    case ACTIONS.PAGE_CHANGE: {
      const { payload } = data;
      const { page } = payload;
      return {
        ...state,
        page,
      };
    }

    case ACTIONS.PAGE_SIZE_CHANGE: {
      const { payload } = data;
      const { sizePerPage } = payload;
      return {
        ...state,
        sizePerPage,
        page: INIT_PAGE,
      };
    }

    case ACTIONS.SEARCH: {
      const { payload } = data;
      const { search } = payload;

      return {
        ...state,
        search,
        page: 1,
      };
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    default:
      throw new Error();
  }
};

const JobsContext = createContext(initialState);

const JobsProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <JobsContext.Provider value={stateAndDispatch}>
      {children}
    </JobsContext.Provider>
  );
};

export const useJobs = () => useContext(JobsContext);

export { JobsContext, JobsProvider, ACTIONS, MAX_PAGE_SIZE };
