import React, { useEffect, useState } from "react";

import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  InputGroup,
  Input,
  Container,
} from "reactstrap";

import InformationModal from "../../InformationModal";
import Loader from "../../Loader";

import {
  ACTIONS,
  MAX_PAGE_SIZE,
  useJobProduction,
} from "../../../providers/jobProductionProvider";

import {
  useGetJobProduction,
  useGetJobProductionFilters,
} from "../../../api/JobProduction.hooks";
import "react-day-picker/lib/style.css";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TABLE_COLUMNS = (onSort) => [
  {
    dataField: "date",
    text: "Created Date",
    classes: "text-truncate",
    sort: true,
    formatter: (cell, _) => moment(cell).format("MM/DD/YYYY h:mm:ss") || "-",
    onSort,
  },
  {
    dataField: "shiftEntry.user_id",
    text: "User",
    classes: "text-truncate",
    sort: false,
    formatter: (_, jobProduction) =>
      jobProduction.shiftEntry?.user?.name || "-",
    onSort,
  },
  {
    dataField: "machine_id",
    text: "Machine",
    classes: "text-truncate",
    sort: true,
    formatter: (_, jobProduction) => jobProduction.machine?.name || "-",
    onSort,
  },
  {
    dataField: "shiftEntry.shift_schedule_id",
    text: "Shift Schedule",
    classes: "text-truncate",
    sort: false,
    formatter: (_, jobProduction) =>
      jobProduction.shiftEntry?.shiftSchedule?.name || "-",
    onSort,
  },
  {
    dataField: "step_code",
    text: "Step",
    classes: "text-truncate",
    sort: true,
    formatter: (cell, _) => cell || "-",
    onSort,
  },
  {
    dataField: "quantity_completed",
    text: "Items Count",
    classes: "text-truncate",
    sort: true,
    formatter: (cell, _) => cell || "-",
    onSort,
  },
];

const JobProduction = ({ jobId }) => {
  const [jobProductionContext, setJobProductionContext] = useJobProduction();

  const {
    data: jobProduction,
    error: jobProductionErr,
    isLoading: isLoadingJobProduction,
    get: getJobProduction,
  } = useGetJobProduction();

  const {
    data: jobProductionFilters,
    error: jobProductionFiltersErr,
    isLoading: isLoadingJobProductionFilters,
    get: getJobProductionFilters,
  } = useGetJobProductionFilters();

  const [startDate, setStartDate] = useState(jobProductionContext.startDate);
  const [endDate, setEndDate] = useState(jobProductionContext.endDate);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onSort = (sortBy, direction) => {
    if (
      jobProductionContext.sortBy === sortBy &&
      jobProductionContext.direction === direction
    ) {
      return;
    }
    setJobProductionContext({
      action: ACTIONS.SORT,
      payload: { sortBy, direction },
    });
  };

  const getSizePerPage = (size) => (size === "All" ? MAX_PAGE_SIZE : size);

  useEffect(() => {
    getJobProduction({
      job_id: jobId,
      page: jobProductionContext.page,
      page_size: getSizePerPage(jobProductionContext.sizePerPage),
      orderBy: jobProductionContext.sortBy,
      direction: jobProductionContext.direction,
      dateFrom: jobProductionContext.startDate,
      dateTo: jobProductionContext.endDate,
      machine_id: jobProductionContext.selectedMachine,
    });
  }, [
    jobId,
    getJobProduction,
    jobProductionContext.direction,
    jobProductionContext.endDate,
    jobProductionContext.page,
    jobProductionContext.selectedMachine,
    jobProductionContext.sizePerPage,
    jobProductionContext.sortBy,
    jobProductionContext.startDate,
    jobProductionContext.refresh,
  ]);

  useEffect(() => {
    if (jobProduction) {
      setJobProductionContext({
        action: ACTIONS.GET_JOB_PRODUCTION_SUCCESS,
        payload: jobProduction,
      });
    }
  }, [jobProduction, setJobProductionContext]);

  useEffect(() => {
    getJobProductionFilters();
  }, [getJobProductionFilters]);

  useEffect(() => {
    if (jobProductionFilters) {
      setJobProductionContext({
        action: ACTIONS.GET_MACHINES_SUCCESS,
        payload: jobProductionFilters,
      });
    }
  }, [jobProductionFilters, setJobProductionContext]);

  useEffect(() => {
    if (jobProductionErr || jobProductionFiltersErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [jobProductionErr, jobProductionFiltersErr]);

  useEffect(() => {
    setJobProductionContext({
      action: ACTIONS.DATE_FILTER_CHANGE,
      payload: { startDate, endDate },
    });
  }, [setJobProductionContext, startDate, endDate]);
  return (
    <Container className="mx-0" fluid>
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className="mb-0 ">Transactions</h3>
            <small className="text-muted ml-2 pt-1">
              ({jobProductionContext.pagination?.rowCount || 0} entries)
            </small>
          </div>
          <div className="d-flex align-items-center justify-content-end flex-shrink-0">
            <InputGroup size="m" className="mr-3">
              <Input
                type="select"
                name="machineSelect"
                id="machineSelect"
                onChange={(evt) =>
                  setJobProductionContext({
                    action: ACTIONS.MACHINE_FILTER_CHANGE,
                    payload: {
                      selectedMachine: evt.target.value,
                    },
                  })
                }
                value={jobProductionContext.selectedMachine}
              >
                <option key="" value="">
                  {"All machines"}
                </option>
                {jobProductionContext.machines?.length &&
                  jobProductionContext.machines.map((machine) => (
                    <option key={machine.id} value={machine.id}>
                      {machine.name}
                    </option>
                  ))}
              </Input>
            </InputGroup>
            <InputGroup
              size="m"
              className="d-flex align-items-center mr-3 flex-nowrap"
            >
              <Input
                className="min-width-150"
                type="date"
                name="startDatePicker"
                id="startDatePicker"
                value={startDate}
                onChange={(evt) => setStartDate(evt.target.value)}
              />
              <span className="mr-2 ml-2">to</span>
              <Input
                className="min-width-150"
                type="date"
                name="endDatePicker"
                id="endDatePicker"
                value={endDate}
                onChange={(evt) => setEndDate(evt.target.value)}
              />
            </InputGroup>
            <Button
              size="sm"
              className="rounded-circle square-32"
              color="primary"
              onClick={() =>
                setJobProductionContext({
                  action: ACTIONS.REFRESH,
                })
              }
            >
              <FontAwesomeIcon icon={faSync} />
            </Button>
          </div>
        </CardHeader>
        <CardBody className="d-flex flex-column">
          {isLoadingJobProduction || isLoadingJobProductionFilters ? (
            <Loader />
          ) : jobProductionContext.jobProduction?.length ? (
            <BootstrapTable
              bootstrap4
              remote
              striped
              bordered={false}
              keyField="id"
              classes="border"
              headerClasses="small text-muted"
              data={jobProductionContext.jobProduction}
              columns={[...TABLE_COLUMNS(onSort)]}
              defaultSorted={[
                {
                  dataField: jobProductionContext.sortBy,
                  order: jobProductionContext.direction,
                },
              ]}
              onTableChange={() => {}}
              pagination={paginationFactory({
                page: jobProductionContext.page,
                totalSize: jobProductionContext.pagination.rowCount,
                sizePerPage: getSizePerPage(jobProductionContext.sizePerPage),
                sizePerPageList: [
                  5,
                  10,
                  25,
                  50,
                  {
                    text: "All",
                    value: jobProductionContext.pagination.rowCount,
                  },
                ],
                onPageChange: (page) =>
                  setJobProductionContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  }),
                onSizePerPageChange: (sizePerPage) =>
                  setJobProductionContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  }),
              })}
            />
          ) : (
            <div className="text-center">No results</div>
          )}
        </CardBody>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default JobProduction;
