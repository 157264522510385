import React, { useState } from "react";

import {
  faSignOutAlt,
  faCog,
  faUpload,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../providers/authProvider";
import SideBarUser from "./SidebarUser";
import SidebarItem from "./SidebarItem";
import brand from "../../../assets/img/amico.png";

import { authApi } from "../../../api/authServices";
import { useHistory } from "react-router-dom";
import SidebarMenu from "./SidebarMenu";
import { adminRoutes } from "../../../routes";
import { useSidebar } from "../../../providers/sidebarProvider";
import UploadJobsCSVModal from "../settings/UploadJobsCSVModal";
import ShiftSchedulesModal from "../settings/ShiftSchedulesModal";

const Sidebar = () => {
  const history = useHistory();
  const [authContext, setAuthContext] = useAuth();
  const [sidebarContext] = useSidebar();
  const [uploadJobsCSVModal, setUploadJobsCSVModal] = useState(false);
  const [shiftSchedulesModal, setShiftSchedulesModal] = useState(false);

  const logout = () => {
    authApi.logout();
    setAuthContext({ currentUser: null });
    history.push("/auth/sign-in");
  };

  return (
    <nav className={`sidebar ${!sidebarContext.isOpen ? "toggled" : ""}`}>
      <div className="sidebar-content py-2">
        <div className="sidebar-brand d-flex align-items-center">
          <img src={brand} className="rounded p-0 col-12" alt={"Amico"} />
        </div>
        <SideBarUser />
        <ul className="sidebar-nav">
          {adminRoutes
            .filter(
              (route) =>
                route.sidebar &&
                (!route.roles ||
                  route.roles?.indexOf(authContext.currentUser.roleId) > -1)
            )
            .map((route, index) =>
              route.entries ? (
                <SidebarMenu
                  key={index}
                  name={route.name}
                  icon={route.icon}
                  to={route.path}
                  entries={route.entries}
                />
              ) : (
                <SidebarItem
                  key={index}
                  name={route.name}
                  icon={route.icon}
                  to={route.path}
                />
              )
            )}
          <SidebarMenu
            name="Settings"
            icon={faCog}
            entries={[
              {
                icon: faUpload,
                name: "Upload Jobs CSV",
                action: () => setUploadJobsCSVModal(true),
                sidebar: true,
              },
              {
                icon: faClock,
                name: "Edit Shift Schedules",
                action: () => setShiftSchedulesModal(true),
                sidebar: true,
              },
            ]}
          />
          <SidebarItem name="Logout" icon={faSignOutAlt} action={logout} />
        </ul>
      </div>
      {uploadJobsCSVModal && (
        <UploadJobsCSVModal onClose={() => setUploadJobsCSVModal(false)} />
      )}
      {shiftSchedulesModal && (
        <ShiftSchedulesModal onClose={() => setShiftSchedulesModal(false)} />
      )}
    </nav>
  );
};

export default Sidebar;
