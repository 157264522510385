import React, { useState, useEffect, useCallback } from "react";
import { Table } from "reactstrap";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import { useGetShiftEntries } from "../../../api/ShiftEntries.hooks";
import useGetMachines from "../../../api/Machines.hooks";
import { useShifts, ACTIONS } from "../../../providers/shiftsTableProvider";
import { useDowntimeTracker } from "../../../providers/downtimeTrackerProvider";
import ShiftsTableEntry from "./ShiftsTableEntry";

const ShiftsTable = () => {
  const [downtimeTrackerContext] = useDowntimeTracker();

  const {
    data: shifts,
    error: shiftsErr,
    isLoading: isLoadingShifts,
    get: getShiftEntries,
  } = useGetShiftEntries();

  const {
    data: machines,
    error: machinesErr,
    isLoading: isLoadingMachines,
    get: getMachines,
  } = useGetMachines();

  const [shiftsContext, setShiftsContext] = useShifts();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const setShiftsContextCb = useCallback(
    (data) => setShiftsContext(data),
    [setShiftsContext]
  );

  useEffect(() => {
    const GET_SHIFT_PARAMS = {
      date: downtimeTrackerContext.selectedDate,
      line_id: downtimeTrackerContext.selectedLine,
      shift_schedule_id: downtimeTrackerContext.selectedShift,
    };
    getShiftEntries(GET_SHIFT_PARAMS);
  }, [
    getShiftEntries,
    shiftsContext.refresh,
    downtimeTrackerContext.selectedDate,
    downtimeTrackerContext.selectedShift,
    downtimeTrackerContext.selectedLine,
  ]);

  useEffect(() => {
    const GET_MACHINES_PARAMS = {
      line_id: downtimeTrackerContext.selectedLine,
    };
    getMachines(GET_MACHINES_PARAMS);
  }, [getMachines, downtimeTrackerContext.selectedLine]);

  useEffect(() => {
    if (machines) {
      setShiftsContextCb({
        action: ACTIONS.GET_MACHINES_SUCCESS,
        payload: { machines: machines.machines },
      });
    }
  }, [setShiftsContextCb, machines]);

  useEffect(() => {
    if (shifts) {
      setShiftsContextCb({
        action: ACTIONS.GET_SHIFTS_SUCCESS,
        payload: { shifts },
      });
    }
  }, [setShiftsContextCb, shifts]);

  useEffect(() => {
    if (shiftsErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [shiftsErr]);

  useEffect(() => {
    if (machinesErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [machinesErr]);

  const getShiftsTableEntries = () => {
    const shiftsTableEntries = [
      ...shiftsContext.shifts.map((shiftEntry) => {
        return {
          shiftEntry,
          machine: shiftEntry.machine,
        };
      }),
      ...machines.machines
        .filter(
          (machine) =>
            !shiftsContext.shifts.find(
              (shiftEntry) => shiftEntry.machine_id === machine.id
            )
        )
        .map((machine) => {
          return {
            machine,
          };
        }),
    ];
    return shiftsTableEntries.sort((x, y) => x.machine.order - y.machine.order);
  };

  return isLoadingShifts || isLoadingMachines ? (
    <Loader />
  ) : (
    <>
      <Table striped className="border">
        <thead>
          <tr className="small text-muted">
            <th className="border-0 border-bottom-0 p-3">
              <span>Machine</span>
            </th>
            <th className="text-center border-0 border-bottom-0 p-3">
              <span>Operator</span>
            </th>
            <th className="text-center border-0 border-bottom-0 p-3">
              <span>Shift</span>
            </th>
            <th className="text-center border-0 border-bottom-0 p-3">
              <span>Start Time</span>
            </th>
            <th className="text-center border-0 border-bottom-0 p-3">
              <span>End Time</span>
            </th>
            <th className="text-center border-0 border-bottom-0 p-3">
              <span>Status</span>
            </th>
            <th className="text-right border-0 border-bottom-0 p-3">
              <span>Actions</span>
            </th>
          </tr>
        </thead>
        {getShiftsTableEntries().map((shiftsTableEntry, index) => {
          return (
            <ShiftsTableEntry
              key={index}
              shiftEntry={shiftsTableEntry.shiftEntry}
              machine={shiftsTableEntry.machine}
            />
          );
        })}
      </Table>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </>
  );
};

export default ShiftsTable;
